import React, { useCallback, useState } from 'react'
import { isIterableArray } from '../../../utils/utils'
import DoughnutGraph from './DoughnutGraph'
import { useTranslation } from 'react-i18next'

const DoughnutGraphController = ({ currentData }: { currentData: any, }) => {

    const { t } = useTranslation()

    const KEYVAL1 = 'price'
    const KEYVAL2 = 'total_for_act'

    // const unique = Object.keys(currentData).map(i => ({
    //     key: i,
    //     show: true
    // }))
    // const [legend, setLegend] = useState(unique);
    const formatted = useCallback(
        () => {
            if (currentData) {
                // console.log('WPK useCallback')

                return isIterableArray(Object.keys(currentData)) ?
                    Object.keys(currentData).reduce((acc: any, key: any) => {

                        // const isshow = legend.find(i => i.key === key) 
                        // if (!isshow?.show) return acc

                        const item = currentData[key]
                        if (item?.[KEYVAL1]) {
                            acc.values1.push(item?.[KEYVAL1])
                            acc.labels1.push(key)
                        }
                        if (item?.[KEYVAL2]) {
                            acc.values2.push(item?.[KEYVAL2])
                            acc.labels2.push(key)
                        }
                        return acc
                    }, {
                        values1: [],
                        labels1: [],
                        values2: [],
                        labels2: [],
                    })
                    :
                    false
            }
        },
        [currentData]
    )

    return (
        <div>
            {
                currentData.month &&
                <DoughnutGraph
                    currentData={formatted()}
                    month={currentData.month}
                    year={currentData.year}
                />
            }
            {/* <div className='flex gap-[4px] flex-wrap justify-center'>
                {
                    isIterableArray(unique) &&
                    legend.map((item, index) => (
                        <div key={index} className='flex gap-[2px] items-center' onClick={() => {
                            setLegend(prev => prev.map(i => ({
                                key: i.key,
                                show: item.key === i.key ? !i.show : i.show
                            })))
                        }}>
                            <span className={`block rounded w-[10px] h-[10px]  `} style={{ backgroundColor: item.show ? dougColors[index] : '#eee' }}></span>
                            <Typography variant='lead' className={`${item.show ? 'text-gray-700' : 'text-gray-400'}  font-medium text-[14px]`}>
                                {t(`analytics.pie_${item.key}`)}
                            </Typography>
                        </div>
                    ))
                }
            </div> */}
        </div >
    )
}

export default DoughnutGraphController