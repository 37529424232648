import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ContractState {
    service_id: number,
    contract_id: number | null,
    total?: {
        balance_1?: number | undefined,
        balance_2?: number | undefined,
        balance_3?: number | undefined,
        balance_4?: number | undefined,
        balance_5?: number | undefined,
        balance_6?: number | undefined,
    },
    or_number: string
}

const initialState: ContractState = {
    service_id: 0, // электро
    contract_id: null,
    total: {},
    or_number: ''
}

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setContractId: (state, action: PayloadAction<number>) => {
            state.contract_id = action.payload
        },
        setServiceId: (state, action: PayloadAction<number>) => {
            state.service_id = action.payload
        },
        setContractTotal: (state, action: PayloadAction<{}>) => {
            state.total = action.payload
        },
        setContractOrNumber: (state, action: PayloadAction<string>) => {
            state.or_number = action.payload
        },
    },
})

export const { setContractId, setServiceId, setContractTotal, setContractOrNumber } = contractSlice.actions

export default contractSlice.reducer