import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import localeUa from 'air-datepicker/locale/uk';

import '../../../style/airpicker.scss'

import { useEffect, useRef } from "react";
import moment from "moment";

function AirDatepickerMonth(props: any) {

    const { onChange, selectedDates } = props
    let $input = useRef<any>();
    let dp = useRef<any>();
    useEffect(() => {
        if (!dp.current && $input.current) dp.current = new AirDatepicker($input.current, {
            inline: true,
            range: false,
            dynamicRange: false,
            locale: localeUa,
            selectedDates: selectedDates ? [moment(selectedDates, 'DD-MM-YYYY').toISOString()] : [],
            // dateFormat: 'DD-MM-YYYY',
            onSelect: ({ formattedDate }: { formattedDate: any }) => {
                onChange(formattedDate)
            }
        });

    }, []);

    useEffect(() => {
        if (selectedDates?.length === 0) dp.current.update({ selectedDates: [] });
    }, [selectedDates]);

    return <input ref={$input} type='hidden' />;
}

export default AirDatepickerMonth;