import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import { PayPlanType } from '../ContractPage.types'

const PaymentPlanContract = ({ data }: { data: PayPlanType | undefined }) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const textService = service_id === 1 ? 'text-orange-600' : service_id === 2 ? 'text-blue-700' : 'text-gray-600'

    const RowItem = ({ children }: { children: any }) => (
        <div className='grid grid-cols-[20%_52%_28%] items-center tab:flex tab:justify-between  py-[10px] border-b border-gray-100 last:border-0'>
            {children}
        </div>
    )
    const RenderByBalance = ({ balance, balance_id }: any) => (
        <div className='flex flex-col bg-gray-100 border-2 border-gray-200 p-[8px] rounded-[16px] lap:rounded-[24px]'>
            <p className='text-gray-800  p-[16px_10px] mob:p-[16px] pb-[12px] lap:p-[24px]  text-[14px] tab:text-[18px] lap:text-[24px]'>
                {t(`contract_page.pay_plan_balance_${balance_id}`)}
            </p>
            <div className='bg-white border-2 border-gray-200 p-[16px_10px] mob:p-[16px] lap:p-[24px] rounded-[16px] grow'>
                {
                    balance ?
                        <>
                            {
                                balance?.type === 1 &&
                                isIterableArray(balance?.parts) &&
                                balance.parts.map((item: any, key: any) => (
                                    <RowItem key={key}>
                                        <p className={` tab:w-[24%] font-bold text-[14px] lap:text-[18px] ${textService}`}>
                                            {item.part}%
                                        </p>
                                        <p className=' tab:w-[38%] font-medium text-[12px] mob:text-[14px] lap:text-[16px] text-gray-800'>
                                            {t(`contract_page.type_day_${item.type_day}`)}
                                        </p>
                                        <p className=' tab:w-[38%] font-medium text-[14px] lap:text-[16px] text-gray-800'>
                                            {item?.day_of_month && <> {t('to')}{item.day_of_month} </>}
                                            {item.month}
                                        </p>
                                    </RowItem>
                                ))
                            }
                            {
                                balance?.type === 1 &&
                                isIterableArray(balance?.part_fact) &&
                                balance?.part_fact.map((item: any, key: any) => (
                                    <RowItem key={key}>
                                        <p className={` tab:w-[24%] font-bold text-[14px] lap:text-[18px] ${textService}`}>
                                            {t('contract_page.askoe')}
                                        </p>
                                        <p className=' tab:w-[38%] font-medium text-[12px] mob:text-[14px] lap:text-[16px] text-gray-800'>
                                            {t(`contract_page.type_day_${item.type_day}`)}
                                        </p>
                                        <p className=' tab:w-[38%] font-medium text-[14px] lap:text-[16px] text-gray-800'>
                                            {item.month}
                                            {
                                                item.day_of_month &&
                                                <>{t('to')} {item.day_of_month}{t('contract_page.day_of_month_postfix')}</>
                                            }
                                        </p>
                                    </RowItem>
                                ))
                            }
                            {
                                balance?.type === 2 &&
                                isIterableArray(balance?.parts) &&
                                <RowItem>
                                    <p className={` tab:w-[24%] font-bold text-[14px] lap:text-[18px] ${textService}`}>
                                        {100}%
                                    </p>
                                    <p className=' tab:w-[38%] font-medium text-[12px] mob:text-[14px] lap:text-[16px] text-gray-800'>
                                        {t(`contract_page.type_day_${balance?.parts[0].type_day}`)}
                                    </p>
                                    <p className=' tab:w-[38%] font-medium text-[14px] lap:text-[16px] text-gray-800'>
                                        {balance?.parts[0]?.day_of_month && <> {t('to')}{balance?.parts[0].day_of_month} </>}
                                        {balance?.parts[0].month}
                                    </p>
                                </RowItem>
                            }
                            {
                                balance?.type === 3 &&
                                <RowItem>
                                    <p className={` tab:w-[24%] font-bold text-[14px] lap:text-[18px] ${textService}`}>
                                        {t('by_fact')}
                                    </p>
                                </RowItem>
                            }
                        </>
                        :
                        <div className='py-[10px]'>
                            <p className={`text-center text-[14px] lap:text-[18px] text-gray-600`}>
                                {t('contract_page.pay_plan_no_data')}
                            </p>
                        </div>
                }
            </div>
        </div>
    )
    return (
        <div className='mb-[96px] lap:mb-[128px]'>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.payment_title')}
            </Typography>
            <div className='grid tab:grid-cols-2 gap-[24px]'>
                {data?.balance_1 && <RenderByBalance balance={data.balance_1} balance_id={1} />}
                {data?.balance_2 && <RenderByBalance balance={data.balance_2} balance_id={2} />}
                {data?.balance_3 && <RenderByBalance balance={data.balance_3} balance_id={3} />}
                {data?.balance_4 && <RenderByBalance balance={data.balance_4} balance_id={4} />}
            </div>
        </div>
    )
}

export default PaymentPlanContract