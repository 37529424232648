import { Button, Typography } from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom"
import PageLayout from "../../../layouts/pagesLayouts/PageLayout"
import { useViewDetailingAllQuery } from "../../../store/auth/authService"
import { setContractId, setServiceId } from "../../../store/service/contractSlice"
import { AppDispatch, RootState } from "../../../store/store"
import { isIterableArray } from "../../../utils/utils"
import TkoInfoCard from "../TkoInfoCard"
import MatrixDays from "../matrix/MatrixDays"
import { useForm } from "react-hook-form"
import MatrixMonth from "../matrix/MatrixMonth"
import { Helmet } from "react-helmet"
import { DownloadExcelButton } from "../views/DownloadExcelButton"
import { useSelector } from "react-redux"
import axios from "axios"
import { baseApi } from "../../../ConfigApi"
import { errorToast } from "../../../components/toasts/toasts"
import fileDownload from "js-file-download"

const ViewDetailingAll = () => {

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch<AppDispatch>()

    let [searchParams, setSearchParams] = useSearchParams();

    const tkos_param = searchParams.get('tkos')

    const tkosParamArr = tkos_param ? tkos_param.split(',').map(i => parseInt(i)) : []

    const { or_number } = useSelector((state: RootState) => state.contract)

    const { data, isLoading, isFetching, error } = useViewDetailingAllQuery({
        contract_id: Number(param_contract_id),
        arr_id: isIterableArray(tkosParamArr) ? tkosParamArr : [],
    })

    const [isLoadingSave, setIsLoadingSave] = useState(false);

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);



    const {
        control,
    } = useForm<any>()

    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    const handleDownloadConsExcel = () => {
        if (isIterableArray(tkosParamArr)) {

            setIsLoadingSave(true)

            const formSend = {
                contract_id: Number(param_contract_id),
                cons_id: tkosParamArr
            }
            // const filename = `${t('or')} ${or_number} - ${t('detailing.view_detailing_title')} ${t('by_period')} ${data?.month || ''}-${data?.year || ''}.xls`;
            const filename = `${t('detailing.view_detailing_title')}.xlsx`;

            axios.post(`${baseApi}/api/ca/consumption-detailing/view-matrix-for-excel`, formSend)
                .then((response: any) => {
                    if (response.data.res === 'error') {
                        errorToast({
                            title: t('detailing.view_detailing_title'),
                            text: t('error_on_download')
                        })
                    } else {
                        setTimeout(() => {
                            axios.post(`${baseApi}/api/ca/consumption-detailing/view-matrix-for-excel-export`, {url: response.data.url, contract_id: Number(param_contract_id)}, { responseType: 'blob' })
                            .then((response: any) => {
                                fileDownload(response.data, filename)
                            })
                            .finally(() => {
                                setIsLoadingSave(false)
                            })
                        }, 3000)
                    }
                })
                .catch(error => {
                    console.error(error);
                    errorToast({
                        title: t('detailing.view_detailing_title'),
                        text: t('error_on_download')
                    })
                })

        }
    }
    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('detailing.view_detailing_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('detailing.view_detailing_title')}
                </title>
            </Helmet>
            {data?.tko && <TkoInfoCard tko={data.tko} />}
            {
                data?.detailing === 1 &&
                <MatrixDays
                    control={control}
                    data={data.matrix}
                    isEditAccess={false}
                    amount={data?.total}
                    handleNavigateBack={handleNavigateBack}
                    DownloadExcelButton={() => (
                        <Button
                            color='black'
                            className='whitespace-nowrap w-full'
                            onClick={handleDownloadConsExcel}
                            disabled={isLoadingSave || isLoading}
                            loading={isLoadingSave}
                        >
                            {t('consumption.download_excel')}
                        </Button>
                    )}
                />
            }
            {
                data?.detailing === 2 &&
                isIterableArray(data?.matrix) &&
                <MatrixMonth
                    amount={data?.total}
                    daysLength={data.matrix[0].length}
                    isEditAccess={false}
                    detailing={data.matrix}
                    handleNavigateBack={handleNavigateBack}
                    DownloadExcelButton={() => (
                        <Button
                            color='black'
                            className='whitespace-nowrap w-full'
                            onClick={handleDownloadConsExcel}
                            disabled={isLoadingSave || isLoading}
                            loading={isLoadingSave}
                        >
                            {t('consumption.download_excel')}
                        </Button>
                    )}
                />
            }
        </PageLayout>
    )
}

export default ViewDetailingAll
