import { Button, Spinner } from '@material-tailwind/react'  
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'

const BtnFilter = (props: {
    isLoading?: boolean,
    children: any,
    icon: any,
    onClick?: any,
    allowClear?: boolean,
    clearFilter?: any

}) => {
    const { isLoading, children, icon, onClick, allowClear, clearFilter } = props
    return (
        <Button
            onClick={() => { if (onClick) onClick() }}
            disabled={isLoading}
            color='white'
            className='group rounded w-full flex items-center justify-between px-[16px] hover:bg-gray-50 hover:border-gray-300 disabled:bg-white disabled:text-gray-700 overflow-hidden'
        >
            <span className='whitespace-nowrap text-gray-700' title={children}>
                {children}
            </span>
            {
                isLoading ?
                    <Spinner />
                    :
                    (allowClear && clearFilter) ?
                        <span>
                            <span className={`transition-all ${(allowClear && clearFilter) ? 'group-hover:hidden' : ''}`}>
                                {icon}
                            </span>
                            {
                                allowClear && clearFilter &&
                                <span
                                    onClick={(event: any) => {
                                        event.stopPropagation()
                                        clearFilter()
                                    }}
                                    className={`transition-all hidden group-hover:flex items-center justify-center bg-gray-200 rounded-full w-[24px] h-[24px] hover:bg-gray-300 `}
                                >
                                    <CloseDrawerIcon className='w-[16px] h-[16px]' />
                                </span>
                            }
                        </span>
                        :
                        icon
            }
        </Button>
    )
}

export default BtnFilter
