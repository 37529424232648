import { Button, Typography, Popover, PopoverContent, PopoverHandler } from '@material-tailwind/react'
import { placement } from '@material-tailwind/react/types/components/menu';
import { t } from 'i18next';
import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form';
import SelectIcon from '../../../images/icons/SelectIcon';
import BtnFilter from './BtnFilter';
import AirDatepickerMonth from './AirDatepickerMonth';

export type CalendarDaysType = {
    active: boolean,
    day: string
}
type propTypes = {
    isLoadingFetch: boolean,
    placement?: placement,
    control: any,
    fieldName: string,
    defaultValue: any,
    label?: string,
    filterStateValue: string,
    handleCloseFilter: any,
    valueLabelFormatter?: any,
}
const FilterDayMonthYear = (props: propTypes) => {

    const {
        valueLabelFormatter,
        isLoadingFetch,
        control,
        fieldName,
        defaultValue,
        label,
        handleCloseFilter,
        filterStateValue,
        placement = "bottom-end" } = props

    const {
        field: { value, onChange }
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (!isLoadingFetch) setIsLoading(false)
    }, [isLoadingFetch]);

    const clearFilter = () => {
        onChange([])
        setIsOpenDrawer(false)
        handleCloseFilter()
        setIsLoading(true)
    }
    const confirmFilter = () => {
        // setIsLoading(true)
        setIsOpenDrawer(false)
        handleCloseFilter()
    }

    return (
        <>
            <Popover
                placement={placement}
                open={isOpenDrawer}
                handler={(e) => {
                    setIsOpenDrawer(e)
                }}
            >
                <PopoverHandler>
                    <div className='w-[180px] lap:w-[200px] des:w-[240px]'>
                        <BtnFilter
                            allowClear={false}
                            onClick={() => setIsOpenDrawer(true)}
                            icon={<SelectIcon />}
                            isLoading={isLoading}
                        >
                            {
                                filterStateValue ?
                                    <span className='whitespace-nowrap'>
                                        {
                                            filterStateValue
                                        }
                                    </span>
                                    :
                                    <span className='whitespace-nowrap text-gray-700'>
                                        {label}
                                    </span>
                            }
                        </BtnFilter>
                    </div>
                </PopoverHandler>
                <PopoverContent className='w-[600px] bg-gray-50 p-0'>
                    <div className='p-[24px] rounded'>
                        <div className="mb-3 flex items-start justify-between">
                            <div className='flex flex-col gap-[16px]'> 
                                <Typography variant="lead" className='text-[18px] font-medium h-[24px]' >
                                    {
                                        value ?
                                            <span className='whitespace-nowrap text-gray-700 text-[16px] font-medium'>
                                                {
                                                    valueLabelFormatter ?
                                                        valueLabelFormatter(value)
                                                        :
                                                        value
                                                }
                                            </span>
                                            :
                                            ''
                                    }
                                </Typography>
                            </div>
                        </div>

                        <AirDatepickerMonth onChange={onChange} selectedDates={value} />
                        <Button
                            disabled={value?.length < 2}
                            color='white'
                            className=' w-full mt-[24px] font-normal'
                            onClick={confirmFilter}
                        >
                            {t('filters.confirm')}
                        </Button>
                    </div>

                </PopoverContent>
            </Popover>
        </>
    )
}

export default FilterDayMonthYear
