// import React, { useEffect, useRef } from 'react'
// import doughnutlabel from "chartjs-plugin-doughnutlabel"; 
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";
import { Doughnut } from 'react-chartjs-2'
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    DoughnutController,
    ArcElement,
    plugins,
    registerables
} from "chart.js";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { isIterableArray, toFormatPrice } from '../../../utils/utils';
import { Typography } from '@material-tailwind/react';
import moment from "moment";
import { useEffect, useState } from "react";
import { classCharDoughnutContainerHeight } from "../AnalyticsPage";
Chart.register(
    ...registerables,
    // ChartDataLabels, 
    DoughnutController,
    // LinearScale, 
    // LineElement, 
    DoughnutLabel,
    // Legend
);

const pieDoughnutLegendClickHandler = DoughnutController.overrides.plugins.legend.onClick;

// console.log(DoughnutLabel)
export const dougColors = [
    '#F87171',
    '#FCA5A5',
    '#FFC8C8',
    '#FEE2E2',
    '#FFF0F0',
    '#FFF2EC',
    '#FFDFD3',
    '#FFD0BE',
    '#FFAA8B',
    '#FF8E65',
    '#FF7340',
    '#F25219',
]

const DoughnutGraph = ({ currentData, period, isEmpty, isMobile, graphId }) => {

    const { t } = useTranslation()
    const [visibleDataIndex, setVisibleDataIndex] = useState([]);

    useEffect(() => {
        if (!isIterableArray(visibleDataIndex)) {
            const init = currentData.values.map((value, index) => ({
                index, value, hidden: true,
            }))
            setVisibleDataIndex(init)
        }
    }, [currentData.values]);


    const newLegendClickHandler = function (e, legendItem, legend, visibleDataIndex, setVisibleDataIndex) {
        const sliced = visibleDataIndex.slice()
        sliced[legendItem.index].hidden = legendItem.hidden
        setVisibleDataIndex(sliced)

        pieDoughnutLegendClickHandler(e, legendItem, legend)
    };

    const getTotal = function (arr, fixedCount, text) {
        const sum = arr.reduce(
            (acc, item, index) => {
                if (item.hidden) {
                    acc = acc + item.value
                    return acc
                }
                else return acc
                //  a + b
            }, 0
        );
        return `${toFormatPrice(sum, fixedCount)} ${text ? text : ''}`;
    };


    const options = {
        // cutoutPercentage: 75,
        plugins: {
            tooltip: {
                // enabled: false, 
                // external: function (context) {
                //     return externalTooltip(context, t('mv'))
                // }  
                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 12,
                borderColor: '#EDEDED',
                borderWidth: 2,
                // displayColors: false,
                padding: 16,
                titleFont: {
                    size: 18
                },
                bodyFont: {
                    size: 18
                },

                callbacks: {
                    title: function (tooltipItems) {
                        return tooltipItems[0].label
                    },
                    // label: function (context) {
                    //     let label = context.parsed.y !== 0 ? context.dataset.label : '';
                    //     if (context.parsed.y !== 0) {
                    //         if (label) {
                    //             label += ': ';
                    //         }
                    //         label += toFormatPrice(context.parsed.y, 3);
                    //     }
                    //     return label;
                    // },
                    labelColor: function (context) {
                        return {
                            backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                            border: 0,
                            boxWidth: 10,
                            boxHeight: 10,
                            pointStyle: 'circle',
                            useBorderRadius: true,
                            borderRadius: 5,
                        }
                    }
                }
            },
            legend: {
                onClick: (e, legendItem, legend) => newLegendClickHandler(e, legendItem, legend, visibleDataIndex, setVisibleDataIndex),
                // display: false,
                position: 'bottom',
                align: 'center',
                labels: {
                    boxWidth: 8,
                    boxHeight: 8,
                    usePointStyle: true,
                    pointStyle: 'circle',
                    useBorderRadius: true,
                    borderRadius: 4,
                }
            },
            datalabels: {
                formatter: function (value, context) {
                    return `${toFormatPrice(value)}`;
                },
                color: function (context) {
                    return '#444248';
                },
                font: {
                    weight: 500,
                    size: isMobile ? 9 : 12,
                    family: 'Inter'
                }
            },
            doughnutLabel: {
                labels: [
                    {
                        text: t('analytics.dataTotalTitle'),
                        font: {
                            size: isMobile ? '9' : "12",
                            family: "Inter",
                            weight: "500",
                            lineHeight: 2
                        },
                        color: "#767579"
                    },
                    {
                        text: (e) => getTotal(visibleDataIndex, 2, t('grn')),
                        font: {
                            size: isMobile ? '11' : "14",
                            family: "Inter",
                            weight: "400",
                            lineHeight: 1.2
                        },
                        color: "#2D2B32"
                    },
                    {
                        text: moment(period, 'MM-YYYY').format('MMMM YYYY'),
                        font: {
                            size: isMobile ? '9' : "12",
                            family: "Inter",
                            weight: "400",
                            lineHeight: 2
                        },
                        color: "#767579"
                    },
                ]
            }
        }
    }

    const data = {
        labels: currentData.labels.map(label => t(`analytics.pie_${label}`)),
        datasets: [
            {
                data: currentData.values,
                backgroundColor: dougColors,
                borderWidth: 0,
            },
        ],
        doughnutlabel: {
            display: true,
        }
    };


    return (
        <>
            {
                isEmpty ?
                    <div className={`flex items-center justify-center ${classCharDoughnutContainerHeight}`}>
                        <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                            {t('analytics.month_detailing_no_data')}
                        </Typography>
                    </div>
                    :
                    <Doughnut
                        id={graphId}
                        updateMode='resize'
                        data={data}
                        options={options}
                    />
            }
        </>
    )
}

export default DoughnutGraph