import { useEffect, useState } from 'react'
import { Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useController, useWatch } from 'react-hook-form'
import CheckIcon from '../../../images/icons/CheckIcon'
import BtnFilter from '../../tableHelpers/filters/BtnFilter'
import SelectIcon from '../../../images/icons/SelectIcon'
import { isIterableArray } from '../../../utils/utils'


const FilterBalance = (props: any) => {

    const { defaultValue, control, fieldName, label, filtersData, handleCloseFilter, isLoadingFetch } = props

    const { t } = useTranslation()
    const [open, setOpen] = useState(false);


    const [isLoading, setIsLoading] = useState(false);

    const {
        field: { value, onChange },
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });
    const checkboxIds = useWatch({ control, name: fieldName });

    const handleChange = (value: any) => {

        const newArray = [...checkboxIds];
        const item = value;

        if (newArray.length > 0) {
            const index = newArray.findIndex((x) => x === item);

            if (index === -1) {
                newArray.push(item);
            } else {
                newArray.splice(index, 1);
            }
        } else {
            newArray.push(item);
        }
        onChange(newArray);

        setIsLoading(true)
        handleCloseFilter()

    };

    // const isChecked = (val: any) => isIterableArray(value) && value?.some((checked: any) => checked === val)

    const getVisibleName = () => {
        const firstLabel = isIterableArray(filtersData) ? filtersData.find((item: any) => item.value === value[0]).label : ''
        const selectedCount = value.length > 1 ? `(+${value.length - 1})` : ''

        return `${t(firstLabel)} ${selectedCount}`
    }

    const clearFilter = () => {
        onChange([])
        setOpen(false)
        handleCloseFilter()
        setIsLoading(true)
    }
    return (
        <div className='w-full tab:w-[180px] lap:w-[200px] des:w-[240px]'>
            <Menu
                dismiss={{
                    itemPress: false,
                }}
                placement="bottom"
                open={open}
                handler={(e) => {
                    setOpen(e)
                }}
            >
                <MenuHandler>
                    <div className='w-full tab:w-[180px] lap:w-[200px] des:w-[240px]'>
                        <BtnFilter
                            icon={<SelectIcon />}
                            isLoading={(isLoadingFetch && isLoading)}
                        >
                            {value
                                ?
                                t(`balance_${value}`)
                                :
                                label
                            }
                        </BtnFilter>
                    </div>
                </MenuHandler>
                <MenuList className='w-[180px] lap:w-[200px] des:w-[240px]'>
                    {
                        isIterableArray(filtersData) && filtersData.map((item: any) => (
                            <MenuItem
                                key={item.value}
                                onClick={() => {
                                    onChange(item.value);
                                    setIsLoading(true)
                                    handleCloseFilter()
                                    // handleChange(item.value)
                                }}
                                disabled={item.disabled}
                                className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700 ${value === item.value ? 'bg-gray-100 font-medium' : ''}`}
                            >
                                {
                                    value === item.value ?
                                        <CheckIcon className='!stroke-gray-700' />
                                        :
                                        ''
                                }
                                {t(item.label)}
                            </MenuItem>
                        ))
                    }
                </MenuList>
            </Menu>
        </div>
    )
}

export default FilterBalance