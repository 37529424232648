import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@material-tailwind/react'
import ViewByPeriod from './ViewByPeriod'
import ViewByMonth from './ViewByMonth'
import ViewByDay from './ViewByDay'
import ArrowBack from '../../../images/icons/ArrowBack'
import ViewByMonthDetailing from './ViewByMonthDetailing'
import moment from 'moment'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import DoughnutGraphController from './DoughnutGraphController'
import { classCharContainerHeight } from '../AnalyticsPage'



export const monthFormatView = 'MMMM YYYY'
export const monthFormatFilter = 'MM-YYYY'
export const monthFormatChange = 'month'

export const dayFormatView = 'DD MMMM YYYY'
export const dayFormatFilter = 'DD-MM-YYYY'
export const dayFormatChange = 'day'

export type ConsFormType = {
    contract_id?: number,
    periodFrom: string,
    periodTo: string
}

export type ConsByMonthFormType = {
    contract_id: number,
    period: string,
}
export type ConsByDayFormType = {
    contract_id: number,
    period: string,
    day: number,
}

const ViewConsumption = ({ isMobile }: { isMobile: boolean }) => {

    const { t } = useTranslation()


    const [periodDay, setPeriodDay] = useState<string>(''); // DD-MM-YYYY
    const [periodMonth, setPeriodMonth] = useState<string>(''); // MM-YYYY

    const [isshow, setIsshow] = useState({
        period: false,
        month: true,
        day: false
    });

    useEffect(() => {
        setIsshow({
            period: !!!periodDay && !!!periodMonth,
            month: !!!periodDay && !!periodMonth,
            day: !!(periodDay && periodMonth)
        })
    }, [periodDay, periodMonth]);

    const DateForTitle = ({ periodM }: { periodM: string }) => {
        const lab = moment(periodM, monthFormatFilter).format(monthFormatView)
        return (
            <Typography variant='lead' className='text-gray-600 text-[16px] hover:cursor-pointer hover:text-gray-500' onClick={() => {
                setPeriodDay('')
            }}>
                <span>{String(lab).charAt(0).toUpperCase() + String(lab).slice(1)}</span>
            </Typography>
        )
    }

    return (
        <div>
            <div className='flex flex-wrap items-center gap-[8px] mb-[24px] tab:mb-[32px] '>
                <div className='flex items-center gap-[8px]'>
                    {
                        isshow.day &&
                        <Button color='white' size='sm' className='!bg-gray-50 !border-0' onClick={() => { setPeriodDay('') }}>
                            <ArrowBack />
                        </Button>
                    }
                    {
                        isshow.month &&
                        <Button color='white' size='sm' className='!bg-gray-50 !border-0' onClick={() => { setPeriodMonth('') }}>
                            <ArrowBack />
                        </Button>
                    }
                    <Typography variant='lead' className='text-gray-800  text-[24px] lap:text-[32px] font-medium leading-[1.2]'>
                        {t('analytics.analytics_cons_title')}
                    </Typography>
                </div>
                {
                    isshow.day &&
                    <div className='mob:pl-[44px] flex items-center gap-[8px]'>
                        <DateForTitle periodM={periodMonth} />
                        <div className='rotate-[90deg] '>
                            <ArrowAccordionIcon width={10} className='fill-gray-600' />
                        </div>
                        <Typography variant='lead' className='text-gray-800 text-[16px] font-medium '>
                            {Number(moment(periodDay, dayFormatFilter).format('DD'))}{t('iy')} {t('day')}
                        </Typography>
                    </div>
                }
            </div>
            <div className='relative'>
                {
                    isshow.period &&
                    <ViewByPeriod
                        periodMonth={periodMonth}
                        setPeriodMonth={setPeriodMonth}
                        isMobile={isMobile}
                    />
                }
                {
                    isshow.month &&
                    <ViewByMonth
                        setPeriodDay={setPeriodDay}
                        setPeriodMonth={setPeriodMonth}
                        periodMonth={periodMonth}

                    />
                }
                {
                    isshow.day &&
                    <ViewByDay
                        periodMonth={periodMonth}
                        periodDay={periodDay}
                        setPeriodDay={setPeriodDay}
                        setPeriodMonth={setPeriodMonth}
                    />
                }
            </div>
        </div>
    )
}

export default ViewConsumption