import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";
import axios from "axios";
import moment from "moment";
import { ReactPortal, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { errorToast, successToast } from "../../../components/toasts/toasts";
import { baseApi } from "../../../ConfigApi";
import CloseDrawerIcon from "../../../images/icons/CloseDrawerIcon";
import { TypeTkoPeriodItem } from "../_types";
import { useParams } from "react-router-dom";

const ModalEditDeclared = ({ open, handleConfirm, handleClose, modalValue }: {
    open: boolean,
    handleConfirm: () => void,
    handleClose: () => void,
    modalValue: TypeTkoPeriodItem
}): ReactPortal | null => {

    const { t } = useTranslation()

    // if (!open) {
    //     return null;
    // }

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const dateToday = moment().format("YYYY-MM-DD")
    const dateTodayFormatted = moment().format("DD.MM.YYYY")


    const defaultValue = 0
    const [inputValue, setInputValue] = useState<number>(defaultValue);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setInputValue(0)
        };
    }, [open]);

    const saveDeclared = () => {
        setIsLoadingSave(true)
        axios.post(`${baseApi}/api/ca/consumption-declared/create`, {
            contract_id: Number(param_contract_id),
            tko_id: modalValue.tko_id,
            month: modalValue.month,
            year: modalValue.year,
            date: dateToday,
            amount: inputValue
        })
            .then((response: any) => {
                if (response.data.res === 'error') {
                    errorToast({
                        title: t('consumption.consumption_title'),
                        text: t('consumption.error_on_save_declared')
                    })
                }
                else {
                    successToast({
                        title: t('consumption.declared_create_success')
                    })
                    handleConfirm()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('consumption.consumption_title'),
                    text: t('consumption.error_on_save_declared')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }


    return (
        createPortal(
            <>
                <Dialog
                    handler={handleClose} open={open} className='!transition-all !animate-none !min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0 !duration-100 ">
                        <div className="flex flex-col gap-[32px]">
                            <div className="flex items-center justify-between ">
                                <Typography variant="lead" className="text-[18px] tab:text-[24px] text-gray-800 font-medium ">
                                    {t('consumption.declared_create_modal_title')}
                                </Typography>
                                <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                    <CloseDrawerIcon />
                                </div>
                            </div>
                            <Typography variant="lead" className="text-[16px] tab:text-[24px] text-gray-800">
                                {modalValue?.month ? t(`month_${modalValue.month}`) : ''} {modalValue?.year ? modalValue.year : ''}
                            </Typography>
                            <div className="flex flex-col gap-[16px]">

                                {/* текущая заявка */}
                                {
                                    modalValue?.declared &&
                                    typeof modalValue?.declared?.amount === 'number' &&
                                    <div className="rounded-[16px] border-2 border-gray-100 p-[24px] grid grid-cols-2 tab:grid-cols-3 items-center">
                                        <Typography variant="lead" className="font-normal text-[14px] text-gray-800 mb-[16px] tab:mb-0">
                                            {t('consumption.current_declared')}
                                        </Typography>
                                        <div>
                                            <Typography variant="lead" className="font-normal text-[14px] text-gray-800 mb-[16px] tab:mb-0">
                                                {
                                                    modalValue.declared.date ?
                                                        moment(modalValue.declared.date, 'DD-MM-YYYY').format('DD.MM.YYYY')
                                                        :
                                                        ''
                                                }
                                            </Typography>
                                        </div>
                                        <div className="flex items-center gap-[8px]">
                                            <input
                                                disabled
                                                type={'number'}
                                                value={modalValue.declared.amount}
                                                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                                                className=' border-2 border-gray-200 rounded w-[120px] h-[56px] px-[4px] text-center text-gray-700 text-[18px] font-bold
                    hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                    disabled:bg-gray-50 disabled:border-gray-200 disabled:text-gray-500
                    focus:bg-white focus:border-gray-200 focus-visible:!border-gray-100 transition-all
                '
                                            />
                                            <Typography variant="lead" className="font-normal text-[14px] text-gray-600">
                                                {t('kvHour')}
                                            </Typography>
                                        </div>
                                    </div>
                                }

                                {/* новая заявка */}
                                <div className="rounded-[16px] border-2 border-gray-600 p-[24px] grid grid-cols-2 tab:grid-cols-3 items-center">
                                    <Typography variant="lead" className="font-normal text-[14px] text-gray-800 mb-[16px] tab:mb-0">
                                        {t('consumption.new_declared')}
                                    </Typography>
                                    <div>
                                        <Typography variant="lead" className="font-normal text-[14px] text-gray-800 mb-[16px] tab:mb-0">
                                            {dateTodayFormatted}
                                        </Typography>
                                    </div>
                                    <div className="flex items-center gap-[8px]">
                                        <input
                                            type={'number'}
                                            value={String(inputValue)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                console.log(e.target.value);
                                                const n = parseFloat(e.target.value)
                                                setInputValue(n || 0)
                                            }
                                            }
                                            className=' border-2 border-gray-200 rounded w-[120px] h-[56px] px-[4px] text-center text-gray-700 text-[18px] font-bold
                    hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                    focus:bg-white focus:border-orange-500 focus-visible:!border-orange-400 transition-all
                '
                                        />
                                        <Typography variant="lead" className="font-normal text-[14px] text-gray-600">
                                            {t('kvHour')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col tab:flex-row gap-[12px] item-center justify-between">
                                <Button color="white" onClick={handleClose}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    color="black"
                                    onClick={saveDeclared}
                                    loading={isLoadingSave}
                                    disabled={isLoadingSave}
                                >
                                    {t('consumption.save_declared')}
                                </Button>
                            </div>
                        </div>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ModalEditDeclared
