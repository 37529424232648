import React from 'react'
import DrawerCard from './DrawerCard'
import CheckFilledIcon from '../../images/icons/CheckFilledIcon'
import { useTranslation } from 'react-i18next'
import { IconButton, List, ListItem } from '@material-tailwind/react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import { topMenu } from '../../menu/topMenu'
import { NavLink } from 'react-router-dom'
import BalanceBadges from '../BalanceBadges'

const DrawerCompanyMenu = ({
    isOpen,
    closeDrawer,
    modalValue,
    isShowMenu = true,
    isShowMainButtons,
}: {
    isOpen: boolean,
    closeDrawer: any,
    modalValue: any,
    isShowMenu?: boolean
    isShowMainButtons?: boolean
}) => {

    const { t } = useTranslation()

    const textService = modalValue?.service_id === 1 ? 'text-orange-500' : 'text-blue-600'
    const fillService = modalValue?.service_id === 1 ? 'fill-orange-500' : 'fill-blue-600'

    return (
        <DrawerCard
            sizeDrawer={600}
            isOpen={isOpen}
            closeDrawer={closeDrawer}
            // modalValue={modalValue}
        >
            <div className="mb-[32px] flex items-center justify-between">
                {
                    modalValue &&
                    <div className='flex items-center gap-[5px] text-gray-800 text-[14px] font-bold'>

                        <CheckFilledIcon className={`${fillService} min-w-[14px] min-h-[14px] `} />
                        {t('or')} {modalValue.or_number} {modalValue.legal_address}
                    </div>
                }

                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>

            {
                modalValue &&
                <div className='flex flex-col h-full'>
                    {
                        isShowMenu &&
                        <List className='p-0 gap-[4px]'>
                            {
                                isShowMainButtons &&
                                <>
                                    <NavLink onClick={closeDrawer} to={'/'}>
                                        {
                                            ({ isActive }) => (
                                                <ListItem className={`${isActive ? textService : 'text-gray-800'} text-[18px] font-medium mx-[-12px] min-h-[32px] py-0`}>
                                                    {t('link_title_main')}
                                                </ListItem>
                                            )
                                        }
                                    </NavLink>
                                    <NavLink onClick={closeDrawer} to={'/my-companies'}>
                                        {
                                            ({ isActive }) => (
                                                <ListItem className={`${isActive ? textService : 'text-gray-800'} text-[18px] font-medium mx-[-12px] min-h-[32px] py-0`}>
                                                    {t('my_companies')}
                                                </ListItem>
                                            )
                                        }
                                    </NavLink>
                                </>
                            }
                            {

                                Object.keys(topMenu).map((key) => {
                                    const link = topMenu[key]
                                    return (
                                        <NavLink key={key} onClick={closeDrawer} to={link.menuUrl + '/' + modalValue.contract_id}>
                                            {
                                                ({ isActive }) => (
                                                    <ListItem className={`${isActive ? textService : 'text-gray-800'} text-[18px] font-medium mx-[-12px] min-h-[32px] py-0`}>
                                                        {t(link.title)}
                                                    </ListItem>
                                                )
                                            }
                                        </NavLink>
                                    )

                                })
                            }
                        </List>
                    }
                    <BalanceBadges
                        service_id={modalValue.service_id}
                        className='!px-[16px] !py-[10px] tab:!py-[10px] rounded-full tab:rounded !gap-[8px] !bg-gray-100'
                        contClassName='!grid w-full tab:!flex mt-auto'
                        items={
                            modalValue.service_id === 1 ?
                                {
                                    balance_1: modalValue?.balance_1,
                                    balance_2: modalValue?.balance_2,
                                } :
                                {

                                    balance_3: modalValue?.balance_3,
                                    balance_4: modalValue?.balance_4,
                                    balance_5: modalValue?.balance_5,
                                    balance_6: modalValue?.balance_6,
                                }}
                    />
                </div>
            }
        </DrawerCard>
    )
}

export default DrawerCompanyMenu