import { Button, Drawer, IconButton, Typography, } from '@material-tailwind/react';
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import { AppealType } from './types';
import useIsMobile from '../../utils/useIsMobile';
import { FILTER_SCREEN_MOBILE, MOBILE_DIVIDER_OFFSET } from '../../utils/variables';

const ViewAppeal = ({ open, handleClose, modalValue }: {
    open: boolean,
    handleClose: () => void,
    modalValue: AppealType | null
}) => {

    const { t } = useTranslation()

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)

    const placement = isMobile ? 'bottom' : 'left'
    const size = isMobile ? window.innerHeight - MOBILE_DIVIDER_OFFSET : 640

    return (
        <Drawer
            placement={placement}
            size={size}
            open={open}
            onClose={handleClose}
            className={`p-4 ${open ? 'tab:!left-[12px] opacity-1 backdrop-blur-sm' : 'tab:!left-0 opacity-0 duration-[250ms]'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto`}
        >
            <div className="mb-6 flex items-center justify-between">
                <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
                    {t('support.support_item_title')} {modalValue?.id ? ` №${modalValue.id}` : ''}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
            {
                modalValue &&
                <div className="flex flex-col gap-[24px]">
                    <div className="bg-white rounded border-2 border-gray-100 grid mob:grid-cols-2 gap-[12px] tab:gap-[24px] p-[16px] tab:p-[24px]">
                        <div className="flex flex-col gap-[8px] border-r-1 border-gray-500">
                            <Typography variant='lead' className='text-gray-700 text-[12px] mob:text-[14px]'>
                                {t('support.select_contract')}
                            </Typography>

                            <Typography variant='lead' className='text-gray-800 font-normal text-[14px] mob:text-[16px]'>
                                {modalValue.or_number ? `#${modalValue.or_number}` : ''}
                            </Typography>

                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <Typography variant='lead' className='text-gray-700 text-[12px] mob:text-[14px]'>
                                {t('support.select_service')}
                            </Typography>
                            <Typography variant='lead' className='text-gray-800 font-normal text-[14px] mob:text-[16px]'>
                                {
                                    modalValue.service_id === 1 ? t('mainpage.electro_title')
                                        :
                                        modalValue.service_id === 2 ? t('mainpage.gas_title')
                                            :
                                            t('support.select_service')
                                }
                            </Typography>
                        </div>
                    </div>
                    <div className="bg-white rounded border-2 border-gray-100 p-[16px] tab:p-[24px] flex flex-col gap-[16px]">
                        <div className="flex flex-col gap-[8px]">
                            <label className='text-gray-700 text-[14px] mob:text-[16px]'>
                                {t('support.theme_input_label')}
                            </label>
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {
                                    modalValue.theme ?
                                        modalValue.theme
                                        :
                                        ' - '
                                }
                            </Typography>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <label className='text-gray-700 text-[14px] mob:text-[16px]'>
                                {t('support.text_input_label')}
                            </label>
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {
                                    modalValue.text ?
                                        modalValue.text
                                        :
                                        ' - '
                                }
                            </Typography>
                        </div>
                    </div>

                    <div className='flex items-center tab:justify-end'>
                        <Button color='white' onClick={handleClose} className='w-full tab:w-auto'>
                            {t('close')}
                        </Button>
                    </div>
                </div>
            }
        </Drawer>
    )
}

export default ViewAppeal