import { Typography, Button } from '@material-tailwind/react'
import { data } from '@remix-run/router/dist/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DocumentIcon from '../../../images/icons/DocumentIcon'
import { RootState } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import { FileType } from '../ContractPage.types'
import axios from 'axios';
import { baseApi } from '../../../ConfigApi';
import fileDownload from 'js-file-download';


const DocumentsContract = ({ data }: { data: FileType[] }) => {

    const { t } = useTranslation()
    const { param_contract_id } = useParams()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const bgService = service_id === 1 ? 'bg-orange-500' : service_id === 2 ? 'bg-blue-600' : 'bg-gray-300'
    const hoverTextService = service_id === 1 ? 'group-hover:text-orange-500' : service_id === 2 ? 'group-hover:text-blue-600' : 'group-hover:text-gray-500'

    const downloadFile = (item: any, callback?: () => void) => {

        let formSend = {
            // contractId: param_contract_id,
            contract_id: Number(param_contract_id),
            fileName: item.fullName
        }

        axios.post(`${baseApi}/api/ca/docs/export-file`, formSend, { responseType: 'blob' })
            .then((response: any) => {
                if (response.data && response.data?.size > 0)
                    fileDownload(response.data, item.fullName)
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                if (callback) callback()
            })
    }

    return (
        <div className='mb-[96px] lap:mb-[128px]'>
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.files_title')}
            </Typography>
            {
                isIterableArray(data) ?
                    <div className='flex lap:grid lap:grid-cols-4 gap-[8px] tab:gap-[16px] lap:gap-[24px] overflow-scroll lap:overflow-hidden'>
                        {
                            data.map((item, key) => (
                                <div
                                    key={key}
                                    className='group bg-white hover:bg-gray-100 transition-all cursor-pointer border-2 border-gray-100 p-[16px] tab:p-[24px] lap:p-[32px] rounded-[16px]'
                                    onClick={() => downloadFile(item)}
                                >
                                    <div className='flex items-start gap-[16px] lap:gap-[24px]'>
                                        <div className={`${bgService} rounded-full flex items-center justify-center min-w-[32px] h-[32px] tab:min-w-[40px] tab:h-[40px] `}>
                                            <DocumentIcon className='h-[15px] tab:h-[32px]' />
                                        </div>
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className={`font-bold text-[14px] tab:text-[16px] text-gray-800 transition-all ${hoverTextService}`}>
                                                {item.name}
                                            </p>
                                            <p className=' font-medium text-[14px] tab:text-[16px] text-gray-600'>
                                                {item.extname} {item.size}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                    </div>
                    :
                    <p className='text-[18px] text-gray-600 text-center'> {t('contract_page.files_no_data')}</p>
            }
        </div>
    )
}

export default DocumentsContract