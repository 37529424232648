import { Button, Spinner, Tooltip } from '@material-tailwind/react'
import { size } from '@material-tailwind/react/types/components/button';
import { useState, forwardRef } from 'react'

const DownloadBtn = ({
    handleDownloadFile,
    record,
    tooltipText,
    icon,
    btnClass,
    size = 'sm',
    disabled
}: {
    handleDownloadFile: any,
    record: any,
    tooltipText?: string,
    icon?: any,
    btnClass?: string,
    size?: size,
    disabled?: boolean
}) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);


    const Btn = forwardRef<HTMLInputElement, any>((props, ref) => (
        <Button
            {...props}
            ref={ref}
            color="white"
            size={size}
            disabled={disabled}
            className={`w-[40px] h-[40px] flex gap-[12px] items-center justify-center ${btnClass}`}
            onClick={(event: any) => {
                event.stopPropagation()
                setIsLoading(true)
                handleDownloadFile(record, () => {
                    setIsLoading(false)
                })
            }}
        >
            {
                isLoading ?
                    <Spinner />
                    :
                    icon ? icon : ''
            }
            <span className='tab:hidden'>{tooltipText}</span>
        </Button>
    ))


    return (
        tooltipText ?
            <Tooltip content={tooltipText} placement='top'>
                <Btn />
            </Tooltip>
            :
            <Btn />
    )
}

export default DownloadBtn
