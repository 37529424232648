import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { checkToken, loginUser } from './authActions'
import { error } from 'console';

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null;

const defaultUserInfo = {
    // user_id: null,
    // name: ''
    user_avatar: null,
}
type UserInfoType = {
    // name: string,
    // user_id: number | null,
    user_avatar: string | null,
}
type LoginType = {
    loading: boolean,
    userInfo: UserInfoType,
    token: any,
    error: any,
    success: boolean
}
const initialState: LoginType = {
    loading: false,
    userInfo: defaultUserInfo,
    token: userToken, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearErrors: (state,) => {
            state.error = null
        },
        removeToken: (state, action?: PayloadAction<undefined>) => {
            state.userInfo = defaultUserInfo
            state.token = ''
        },
        setUserInfo: (state, action: PayloadAction<UserInfoType>) => {
            state.userInfo = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(loginUser.fulfilled, (state, { payload }) => {
            state.loading = false
            state.token = payload.token.token
            state.success = true
            state.error = null
            localStorage.setItem('userToken', payload.token.token)
        })
        builder.addCase(loginUser.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload
        })

        builder.addCase(checkToken.pending, (state) => {
            state.loading = true
            state.error = null
        })
        builder.addCase(checkToken.fulfilled, (state, { payload }) => {
            state.loading = false
            state.error = null
            state.success = true
        })
        builder.addCase(checkToken.rejected, (state, { payload }) => {
            state.loading = false
            state.error = payload
            window.location.href = '/auth'
            state.token = ''
            localStorage.setItem('userToken', '')
        })
    }
})
export const { removeToken, setUserInfo, clearErrors } = authSlice.actions

export default authSlice.reducer