// import React, { useEffect, useRef } from 'react'
// import doughnutlabel from "chartjs-plugin-doughnutlabel"; 
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";
import { Doughnut, Pie } from 'react-chartjs-2'
import {
    Chart,
    DoughnutController,
    registerables
} from "chart.js";
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import { toFormatPrice, toStringPrice } from '../../../utils/utils';
import { Typography } from "@material-tailwind/react";
import { classCharDoughnutContainerHeight, } from "../AnalyticsPage";
Chart.register(
    ...registerables,
    DoughnutController,
    DoughnutLabel,
);


export const dougColors = [
    '#F87171',
    '#FCA5A5',
    '#FFC8C8',
    '#FEE2E2',
    '#FFF0F0',
    '#FFF2EC',
    '#FFDFD3',
    '#FFD0BE',
    '#FFAA8B',
    '#FF8E65',
    '#FF7340',
    '#F25219',
]
const PieGraph = ({ currentData, isEmpty, isMobile, graphId }) => {

    const { t } = useTranslation()



    const options = {
        // cutoutPercentage: 75,
        plugins: {
            tooltip: {
                // enabled: false,
                // external: function (context) {
                //     return externalTooltip(context, t('mv'))
                // },
                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 12,
                borderColor: '#EDEDED',
                borderWidth: 2,
                // displayColors: false,
                padding: 16,
                titleFont: {
                    size: 18
                },
                bodyFont: {
                    size: 18
                },

                callbacks: {
                    title: function (tooltipItems) {
                        return ''
                    },
                    // label: function (context) {
                    //     let label = context.parsed.y !== 0 ? context.dataset.label : '';
                    //     if (context.parsed.y !== 0) {
                    //         if (label) {
                    //             label += ': ';
                    //         }
                    //         label += toFormatPrice(context.parsed.y, 3);
                    //     }
                    //     return label;
                    // },
                    labelColor: function (context) {
                        return {
                            backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                            border: 0,
                            boxWidth: 10,
                            boxHeight: 10,
                            pointStyle: 'circle',
                            useBorderRadius: true,
                            borderRadius: 8,
                        }
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom',
                align: 'center',
                // labels: {
                //     boxWidth: 10,
                //     boxHeight: 10,
                //     pointStyle: 'circle', 
                //     borderRadius: 10,
                // }
            },
            datalabels: {
                formatter: function (value) {
                    return `${toFormatPrice(value, 3)}`;
                },
                color: function (c) {
                    if (c.dataset.backgroundColor[0] === '#444248') return '#fff'
                    return '#444248';
                },
                font: {
                    weight: 500,
                    size: isMobile ? 9 : 12,
                    family: 'Inter'
                }
            },
            doughnutLabel: {
                labels: [
                    {
                        text: t('analytics.declared'),
                        font: {
                            size: isMobile ? "9" : '12',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 1
                        },
                        color: "#767579",
                    },
                    {
                        text: toStringPrice(currentData.declared) + ' ' + t('mvHour'),
                        font: {
                            size: isMobile ? "11" : '14',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 3,
                        },
                        color: "#2D2B32",
                    },
                    {
                        text: t('analytics.delta_cons_text'),
                        font: {
                            size: isMobile ? "9" : '12',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 2
                        },
                        color: "#2D2B32",
                    },
                    {
                        text: toStringPrice(currentData.delta_cons) + ' ' + t('mvHour'),
                        font: {
                            size: isMobile ? "11" : '14',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 1.2
                        },
                        color: "#EF4444",
                    },
                    {
                        text: t('analytics.fact'),
                        font: {
                            size: isMobile ? "9" : '12',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 3
                        },
                        color: "#767579",
                    },
                    {
                        text: toStringPrice(currentData.fact) + ' ' + t('mvHour'),
                        font: {
                            size: isMobile ? "11" : '14',
                            family: "Inter",
                            weight: "400",
                            lineHeight: 1
                        },
                        color: "#2D2B32",
                    },
                ]
            }
        }
    }

    // план  #444248
    // факт C4B5FD
    // minus  F87171
    // plus 73CD88
    const dataset = currentData.declared > currentData.fact ? [
        {
            label: t('analytics.declared'),
            backgroundColor: ['#444248'],
            data: [currentData.declared],
            borderWidth: 0,
        },
        {
            label: t('analytics.fact'),
            backgroundColor: ['#C4B5FD', '#F87171'],
            data: [currentData.fact, currentData.delta_cons],
            borderWidth: 0,
        },
    ] : [
        {
            label: t('analytics.fact'),
            backgroundColor: ['#C4B5FD'],
            data: [currentData.fact],
            borderWidth: 0,
        },
        {
            label: t('analytics.declared'),
            backgroundColor: ['#444248', '#F87171'],
            data: [currentData.declared, currentData.delta_cons],
            borderWidth: 0,
        },

    ]


    const data = {
        // labels: currentData.labels.map(label => t(`analytics.pie_${label}`)),
        // datasets: [
        //     {
        //         data: currentData.values,
        //         backgroundColor: dougColors,
        //         borderWidth: 0,
        //     },
        // ],
        // labels: ['Overall Yay', 'Overall Nay', 'Group A Yay', 'Group A Nay', 'Group B Yay', 'Group B Nay', 'Group C Yay', 'Group C Nay'],
        labels: currentData.declared > currentData.fact ? [t('analytics.declared'), t('analytics.delta_cons_plus'), t('analytics.fact'),] : [t('analytics.declared'), t('analytics.delta_cons_minus'), '', t('analytics.fact')],
        datasets: dataset,
        doughnutlabel: {
            display: true,
        }
    };


    return (
        <>
            {
                isEmpty ?
                    <div className={`flex items-center justify-center ${classCharDoughnutContainerHeight}`}>
                        <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                            {t('analytics.month_detailing_no_data')}
                        </Typography>
                    </div>
                    :
                    <Doughnut
                        id={graphId}
                        updateMode='resize'
                        data={data}
                        options={options}
                    />
            }
        </>
    )
}

export default PieGraph