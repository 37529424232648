import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { Typography } from '@material-tailwind/react';

import AuthCheckIcon from '../../images/icons/AuthCheckIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

import { Helmet } from "react-helmet";
import { NAME_PROVIDER, SITE_LINK, AUTH_BG_BODY } from '../../configAuthPage';

const AuthPage = ({ children }: { children: JSX.Element }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const { token } = useSelector((state: RootState) => state.auth)

    // const bg = '#0F1522'
    useEffect(() => {
        if (token) navigate('/', { replace: true })
        // document.body.style.background = bg
        // return () => {
        //     document.body.style.background = 'inherit'
        // };
    }, [token]);

    return (
        <div>
            <Helmet>
                <title>{t('page_title_login')}</title>
            </Helmet>
            {
                !token &&
                <>
                    <div className='fixed t-0 r-0 b-0 l-0  min-h-[100vh] w-screen'
                        style={{
                            backgroundImage: `url(${AUTH_BG_BODY})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            zIndex: -1
                        }}
                    />
                    {/* w-screen flex flex-col justify-center tab:flex-row */}
                    <div className='p-[16px] min-h-[100vh] flex flex-col justify-center lap:grid lap:grid-cols-2 lap:grid-cols-[60%_40%]' >
                        <div className='w-full p-[24px] lap:p-[48px] des:p-[96px] flex flex-col  justify-between flex-wrap items-center lap:items-start'>
                            <Link
                                to={SITE_LINK}
                                target='_blank'
                                className={'hidden tab:block py-[9px] px-[24px] text-[12px] des:text-[16px] bg-white text-gray-700 rounded-full hover:bg-blue-100 easy duration-300 mb-[16px]'}
                            >
                                {t('auth.site_link')}
                            </Link>
                            <div>
                                <Typography variant='lead' className='text-white text-[14px] lap:text-[16px] des:text-[24px] mb-[16px] tab:mb-[48px]  text-center lap:text-left' >
                                    <span dangerouslySetInnerHTML={{ __html: t(NAME_PROVIDER) }} />
                                </Typography>
                                <Typography variant='h1' className='text-black font-medium mb-0 tab:mb-[48px] text-center lap:text-left' >
                                    <span dangerouslySetInnerHTML={{ __html: t('auth.title') }} />
                                </Typography>
                                <ul className='hidden lap:grid grid-cols-2 gap-[8px] text-gray-800 font-medium text-[14px] des:text-[18px] '>
                                    <li className='flex gap-[8px] items-center pr-[24px]'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_1')}
                                    </li>
                                    <li className='flex gap-[8px] items-center pr-[24px]'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_2')}
                                    </li>
                                    <li className='flex gap-[8px] items-center pr-[24px]'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_3')}
                                    </li>
                                    <li className='flex gap-[8px] items-center pr-[24px]'>
                                        <AuthCheckIcon />
                                        {t('auth.title_list_text_4')}
                                    </li>
                                </ul>
                            </div>
                            <p className='hidden lap:block text-gray-700 text-xs'>{t('auth.copyright_text')}</p>
                        </div>
                        <div className='w-full px-[16px] py-[24px] tab:p-[24px] lap:p-[48px] des:p-[96px] rounded-24 flex flex-col justify-center items-center bg-white'>
                            {children}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default AuthPage
