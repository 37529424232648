import { IconProps } from "./Arrow"

const ArrowBack = (props: IconProps) => {
    return (
        <svg className={props.className ? props.className : ''} width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={props.className ? props.className : ''} d="M5.10182 9.00007L8.96849 12.8667C9.23516 13.1334 9.36293 13.4445 9.35182 13.8001C9.34071 14.1556 9.20182 14.4667 8.93516 14.7334C8.66849 14.9778 8.35738 15.1056 8.00182 15.1167C7.64627 15.1278 7.33516 15.0001 7.06849 14.7334L0.935156 8.60007C0.66849 8.3334 0.535156 8.02229 0.535156 7.66673C0.535156 7.31118 0.66849 7.00007 0.935156 6.7334L7.06849 0.600065C7.31293 0.355621 7.61849 0.233398 7.98516 0.233398C8.35182 0.233398 8.66849 0.355621 8.93516 0.600065C9.20182 0.866732 9.33516 1.1834 9.33516 1.55007C9.33516 1.91673 9.20182 2.2334 8.93516 2.50007L5.10182 6.3334H17.3352C19.1796 6.3334 20.7518 6.9834 22.0518 8.2834C23.3518 9.5834 24.0018 11.1556 24.0018 13.0001V17.0001C24.0018 17.3778 23.874 17.6945 23.6185 17.9501C23.3629 18.2056 23.0463 18.3334 22.6685 18.3334C22.2907 18.3334 21.974 18.2056 21.7185 17.9501C21.4629 17.6945 21.3352 17.3778 21.3352 17.0001V13.0001C21.3352 11.889 20.9463 10.9445 20.1685 10.1667C19.3907 9.38895 18.4463 9.00007 17.3352 9.00007H5.10182Z" fill="#767579" />
        </svg>
    )
}

export default ArrowBack