import { Button, Drawer, IconButton, Typography } from '@material-tailwind/react';
import { useEffect, useState } from 'react'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useRequestGetContractsViewQuery } from '../../store/auth/authService';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import SelectContract from './SelectContract';
import SelectService from './SelectService';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import { errorToast, successToast } from '../../components/toasts/toasts';
import useIsMobile from '../../utils/useIsMobile';
import { FILTER_SCREEN_MOBILE, MOBILE_DIVIDER_OFFSET } from '../../utils/variables';

const CreateAppeal = ({ open, handleClose }: {
    open: boolean,
    handleClose: () => void
}) => {

    const { t } = useTranslation()

    // const dispatch = useDispatch<AppDispatch>()

    // const { token } = useSelector((state: RootState) => state.auth)
    const { contract_id, service_id } = useSelector((state: RootState) => state.contract)


    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)


    const {
        register,
        control,
        setValue,
        handleSubmit,
        resetField,
        formState: { errors }
    } = useForm<{
        contract_id: number,
        service_id: number,
        theme: string,
        text: string
    }>({
        defaultValues: {},
    })


    const watchService = useWatch({ control, name: 'service_id', })

    const { currentData, isFetching } = useRequestGetContractsViewQuery({
        service_id: watchService ? watchService : 1
    }, {
        skip: !open
    }
    )

    useEffect(() => {
        if (contract_id) {
            setValue('contract_id', contract_id)
        }
        if (service_id) {
            setValue('service_id', service_id)
        }
    }, [contract_id, service_id])


    const onSubmit: SubmitHandler<{
        contract_id: number,
        service_id: number,
        theme: string,
        text: string
    }> = (data) => {
        // console.log('SUBMIT', data)
        // handleUpdatePage(data)

        setIsLoadingSave(true)
        axios.post(`${baseApi}/api/cabinet/profile-request-create`, data)
            .then((response: any) => {
                if (response.data.res === 'Error') {
                    errorToast({
                        title: t('support.new_appeal'),
                        text: t('support.error_on_new_appeal')
                    })
                }
                else {
                    successToast({
                        title: t('support.new_appeal'),
                        text: t('support.success_new_appeal')
                    })
                    handleClose()

                    setValue('contract_id', contract_id ? contract_id : 0)
                    setValue('service_id', service_id ? service_id : 1)
                    resetField('text')
                    resetField('theme')
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('support.new_appeal'),
                    text: t('support.error_on_new_appeal')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }

    const placement = isMobile ? 'bottom' : 'left'
    const size = isMobile ? window.innerHeight - MOBILE_DIVIDER_OFFSET : 640

    return (
        <Drawer
            placement={placement}
            size={size}
            open={open}
            onClose={handleClose}
            className={`p-4 ${open ? 'tab:!left-[12px] opacity-1' : 'tab:!left-0 opacity-0 duration-[250ms]'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto`}
        >
            <div className="mb-6 flex items-center justify-between">
                <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
                    {t('support.new_appeal')}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-[24px]">
                    <div className="bg-white rounded border-2 border-gray-100 grid grid-cols-2 gap-[24px] p-[16px_16px_0_16px] tab:p-[24px_24px_12px_24px]">
                        <div className="flex flex-col gap-[8px] border-r-1 border-gray-500">
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {t('support.select_contract')} <span className='text-red-500'>*</span>
                            </Typography>
                            <SelectContract
                                isFetching={isFetching}
                                currentData={currentData}
                                fieldName='contract_id'
                                control={control}
                                defaultValue={contract_id}
                                isErrorContract={!!errors.contract_id}
                            />
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <Typography variant='lead' className='text-gray-700 text-[14px]'>
                                {t('support.select_service')}
                            </Typography>
                            <SelectService
                                setValue={setValue}
                                fieldName='service_id'
                                control={control}
                                defaultValue={service_id || 1}
                            />
                        </div>
                    </div>
                    <div className="bg-white rounded border-2 border-gray-100 p-[16px] tab:p-[24px] flex flex-col gap-[16px]">
                        <div className="flex flex-col gap-[8px]">
                            <label htmlFor='themeInput' className='text-gray-700 text-[14px] mob:text-[16px]'>
                                {t('support.theme_input_label')} <span className='text-red-500'>*</span>
                            </label>
                            <input
                                {...register('theme', {
                                    required: t('support.required_input')
                                })}
                                id='themeInput'
                                className='border-2 border-gray-200 rounded w-full h-[50px] px-[16px] text-gray-700
                                hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                            '
                                placeholder={t('support.theme_input_placeholder')}
                            />
                            <Typography variant='lead' className={`leading-1 text-red-500 text-[12px] des:text-[14px] transition-all ${errors.theme ? 'opacity-1' : 'opacity-0'}`} >
                                {t('support.required_input')}
                            </Typography>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <label htmlFor='textInput' className='text-gray-700 text-[14px] mob:text-[16px]'>
                                {t('support.text_input_label')} <span className='text-red-500'>*</span>
                            </label>
                            <textarea
                                {...register('text', {
                                    required: t('support.required_input')
                                })}
                                rows={8}
                                id='textInput'
                                className='border-2 border-gray-200 rounded w-full px-[16px] py-[10px] text-gray-700
                                hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                focus:bg-white focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                            '
                                placeholder={t('support.text_input_placeholder')}
                                style={{ resize: 'none' }}
                            />
                            <Typography variant='lead' className={`leading-1 text-red-500 text-[12px] des:text-[14px] transition-all ${errors.text ? 'opacity-1' : 'opacity-0'}`} >
                                {t('support.required_input')}
                            </Typography>
                        </div>
                    </div>

                    <div className='flex flex-col tab:flex-row gap-[16px] items-center justify-between'>
                        <Button color='white' onClick={handleClose} className='w-full tab:w-auto'>
                            {t('cancel')}
                        </Button>
                        <Button color='black' type='submit' loading={isLoadingSave} className='w-full tab:w-auto'>
                            {t('support.confirm')}
                        </Button>
                    </div>
                </div>
            </form>
        </Drawer>
    )
}

export default CreateAppeal