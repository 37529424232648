import PageLayout from '../../layouts/pagesLayouts/PageLayout'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import ViewSaldo from './viewSaldo/ViewSaldo'
import ViewConsumption from './viewConsumption/ViewConsumption'
import ViewComparison from './viewComparison/ViewComparison'
import ViewComparisonByYear from './viewComparisonByYear/ViewComparisonByYear'
import useIsMobile from '../../utils/useIsMobile'
import ViewComparisonByYearPrice from './viewComparisonByYear/ViewComparisonByYearPrice'


// export const classCharContainerHeightDesktop = `lap:h-[536px] des:h-[590px] `
// export const classCharContainerWidth = ` w-[400px] mob:w-[480px] tab:w-[510px] lap:w-[536px] des:w-[590px] `
// export const classCharPieContainerHeight = `h-[220px] mob:h-[320px] tab:h-[480px] lap:h-[506px] des:h-[560px] 
//                                             w-[220px] mob:w-[320px] tab:w-[480px] lap:w-[506px] des:w-[560px]`

export const classCharContainerHeight = ` h-[400px] mob:h-[480px] tab:h-[510px] lap:h-[536px] des:h-[590px] `
export const classCharDoughnutContainerHeight = `h-[300px] mob:h-[380px] tab:h-[480px] lap:h-[506px] des:h-[560px] 
                                            w-[300px] mob:w-[380px] tab:w-[480px] lap:w-[506px] des:w-[560px]`

const AnalyticsPage = () => {


    const { t } = useTranslation()

    const isMobile = useIsMobile(1200, false)

    return (
        <PageLayout>
            <Helmet>
                <title>
                    {t('analytics.analytics_title')}
                </title>
            </Helmet>


            <div className='flex flex-col gap-[48px] lap:gap-[96px] des:gap-[128px]'>
                <ViewSaldo />
                <ViewConsumption isMobile={isMobile} />
                <ViewComparisonByYear isMobile={isMobile} />
                <ViewComparisonByYearPrice isMobile={isMobile} />
                <ViewComparison isMobile={isMobile} />
            </div>
        </PageLayout>
    )
}

export default AnalyticsPage
