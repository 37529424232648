import { Button, Input, Spinner, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/auth/authActions';
import { AppDispatch, RootState } from '../../store/store';

import ForgotForm from './ForgotForm';
import { clearErrors } from '../../store/auth/authSlice';
import EyeCloseIcon from '../../images/icons/EyeCloseIcon';
import EyeIcon from '../../images/icons/EyeIcon';
import { AUTH_LOGO } from '../../configAuthPage';


const LoginForm = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    const { loading, error } = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch<AppDispatch>()

    const handleEnter = () => {
        dispatch(loginUser({
            email: email,
            password: password,
            lang: 'ua'
        }))
            .then((res) => {
                if (res?.payload?.res === 'Success') navigate('/', { replace: true });
            }).catch(err => {
                console.log(err)
            })
    }

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isOpenForgot, setIsOpenForgot] = useState<boolean>(false);
    const handleForgotPassword = () => {
        setIsOpenForgot(true)
        handleClearError()
    }
    const handleCloseForgot = () => {
        setIsOpenForgot(false)
    }
    const handleClearError = () => {
        dispatch(clearErrors())
    }

    return (
        <div className='w-full'>
            <ForgotForm
                open={isOpenForgot}
                handleClose={handleCloseForgot}
            />
            <img src={AUTH_LOGO} alt='logo' className='mx-auto my-[12px]' />
            <Typography variant='lead' className='mb-[64px] font-normal text-center  pt-[20px] text-[24px] des:text-[40px] ' >
                <span dangerouslySetInnerHTML={{ __html: t('auth.form_title') }} />
            </Typography>
            <div className='w-full'>
                <div className='mt-[40px] text-[14px] tab:text-[16px]'>
                    <Input
                        variant='static'
                        type={'email'}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        label={t('auth.input_email_label')}
                        placeholder={t('auth.input_email_placeholder')}
                    />
                </div>
                <div className='mt-[64px] text-[14px] tab:text-[16px]'>

                    <div className='relative'>
                        <Input
                            variant='static'
                            type={isShowPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            label={t('auth.input_password_label')}
                            placeholder={t('auth.input_password_placeholder')}
                        />
                        <div
                            onClick={() => {
                                setIsShowPassword(prev => !prev)
                            }}
                            className='cursor-pointer absolute flex items-center justify-center w-[32px] h-[32px] top-[50%] translate-y-[-50%] right-[16px]'
                        >
                            {isShowPassword ? <EyeCloseIcon /> : <EyeIcon />}
                        </div>
                    </div>
                </div>

                <Typography variant='lead' onClick={handleForgotPassword} className='cursor-pointer inline-block mt-[24px] text-gray-600 hover:text-gray-700 text-[14px] tab:text-[18px]'>
                    {t('auth.forgot_text')}
                </Typography>
                <Typography variant='lead' className='flex items-center justify-center font-medium text-[14px] text-center text-red-500 min-h-[32px] mt-[16px]'>
                    {error ? t('auth.error_on_login') : ' '}
                </Typography>
                <div className='mt-[16px] flex flex-col gap-[8px]'>
                    <Button
                        disabled={loading}
                        variant='outlined'
                        className='font-normal rounded-full border-2 border-gray-800 text-gray-800 text-[14px] tab:text-[18px] w-full tab:py-[20px] '
                        onClick={() => {
                            handleEnter()
                        }}>
                        {
                            loading
                                ?
                                <Spinner color='blue' className='text-blue-100 mx-auto' />
                                :
                                t('auth.enter_btn')
                        }
                    </Button>
                    <NavLink to='/new-connection' onClick={handleClearError}>
                        <Button variant='outlined' className='font-normal rounded-full bg-blue-100 border-2 border-blue-200 text-gray-800 text-[14px] tab:text-[18px] w-full tab:py-[20px] '>
                            {t('auth.become_client')}
                        </Button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default LoginForm
