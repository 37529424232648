import { Spinner, Typography } from '@material-tailwind/react'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import TableLayout from '../../../layouts/pagesLayouts/TableLayout'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import GraphConsumption from './GraphConsumption'
import { saveGraphToPicture, toFormatPrice } from '../../../utils/utils'
import FilterByMonth from './FilterByMonth'
import { ConsByDayFormType, dayFormatChange, dayFormatFilter, dayFormatView, monthFormatFilter } from './ViewConsumption'
import { useViewConsumptionDayQuery } from '../../../store/auth/authService'
import { classCharContainerHeight } from '../AnalyticsPage'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const GRAPH_ID = "js-cons-by-day-graph"

const ViewByDay = ({ periodDay, setPeriodDay, periodMonth, setPeriodMonth }: {
    periodDay: any,
    setPeriodDay: any,
    setPeriodMonth: any,
    periodMonth: string,
}) => {
    const { t } = useTranslation()
    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)


    const [sendForm, setSendForm] = useState<ConsByDayFormType>({
        contract_id: Number(param_contract_id) || 0,
        period: periodMonth,
        day: Number(moment(periodDay, dayFormatFilter).format('DD'))
    });

    const { currentData, error, isFetching } = useViewConsumptionDayQuery<any>(sendForm, {
        skip: periodMonth === '',
    })


    const labels = Array.from(Array(24).keys())

    const datachar = useCallback(
        () => {
            const data = currentData
            if (data) return {
                labels: labels,
                datasets: [
                    {
                        order: 1,
                        label: t('analytics.data_cons'),
                        data: data.data_cons,
                        backgroundColor: '#444248',
                        borderRadius: 4,
                        stack: '1',
                        spanGaps: false,
                        datalabels: {
                            display: false,
                            align: 'center',
                            // rotation: '-90',
                            anchor: 'center',
                            formatter: function (value: any) {
                                return `${toFormatPrice(value, 3)}`;
                            },
                            color: function (context: any) {
                                if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                                return '#FDFDFD';
                            },
                            font: {
                                weight: 500,
                                size: 10,
                                family: 'Inter'
                            }
                        }

                    },
                    {
                        label: t('analytics.data_declared'),
                        data: data.data_declared,
                        backgroundColor: '#BDB4FE',
                        borderRadius: 4,
                        stack: '2',
                        spanGaps: true,
                        datalabels: {
                            display: false,
                            align: 'center',
                            // rotation: '-90',
                            anchor: 'center',
                            formatter: function (value: any) {
                                return `${toFormatPrice(value, 3)}`;
                            },
                            color: function (context: any) {
                                if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                                return '#444248';
                            },
                            font: {
                                weight: 500,
                                size: 10,
                                family: 'Inter'
                            }
                        }
                    },

                ],
            }
        },
        [currentData]
    )
    const setFormForFetch = (values: any) => {
        const newMonthPeriod = moment(values.period, dayFormatFilter).format(monthFormatFilter)
        const newday = moment(values.period, dayFormatFilter).format('DD')
        setPeriodMonth(newMonthPeriod)
        setPeriodDay(values.period)
        setSendForm({
            day: Number(newday),
            period: newMonthPeriod,
            contract_id: Number(param_contract_id)
        })
    }

    const titleTooltipFormatter = (val: string) => {
        return ''
    }


    return (

        // ${periodDay ? 'opacity- 1 block' : 'opacity- 0 hidden'} 
        <div className={` 
        transition-opacity delay-[150ms] duration-[250ms]`}>


            <FilterByMonth
                formatView={dayFormatView}
                formatFilter={dayFormatFilter}
                formatChange={dayFormatChange}
                value={{
                    period: periodDay,
                }}
                isLoadingFetch={false}
                fetchPage={setFormForFetch}
                savePicture={() => {
                    saveGraphToPicture(GRAPH_ID, `${t('or')} ${or_number} - ${t('analytics.analytics_cons_by_day_title')} ${t('by_period')} ${periodDay}.png`)
                }}
            />
            {

                currentData ?
                    <>
                        <div className='bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                            <Typography variant='lead' className='text-gray-800 text-center mb-[24px] tab:mb-[32px] text-[20px] lap:text-[24px] text-left'>
                                {t('analytics.analytics_cons_by_day_title')}
                            </Typography>
                            <div className={classCharContainerHeight}>
                                <GraphConsumption
                                    graphId={GRAPH_ID}
                                    titleTooltipFormatter={titleTooltipFormatter}
                                    datachar={datachar()}
                                />
                            </div>
                        </div>
                    </>
                    :
                    isFetching ?
                        <div className={`${classCharContainerHeight} bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            ''
            }
        </div>
    )
}

export default ViewByDay