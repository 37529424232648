import { Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export const ImageDownloadButton = ({ text, className = '', onClick, disabled }: { text?: any, className?: string, onClick: any, disabled?: boolean }) => {

    const { t } = useTranslation()
    const textBtn = text ? text : t('consumption.download_excel')

    return (
        <Button
            color='black'
            onClick={onClick}
            disabled={disabled}
            className={`w-full tab:w-auto text-[12px] mob:text-[14px] tab:text-[16px] ${className}`}
        >
            {textBtn}
        </Button>
    )
}