import { useEffect, useState } from 'react'
import { Popover, PopoverContent, PopoverHandler, Typography } from '@material-tailwind/react'

import { useController } from 'react-hook-form'
import MonthYearSelect from './MonthYearSelect'
import { DateSelectType } from '../../contract/ContractPage.types'
import { placement } from '@material-tailwind/react/types/components/menu'
import CalendarIcon from '../../../images/icons/CalendarIcon'
import BtnFilter from './BtnFilter'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

type propTypes = {
    control: any,
    fieldName1: string,
    fieldName2: string,
    label1: string,
    label2: string,
    datesData: DateSelectType,
    handleCloseFilter: any,
    placement: placement,
    isLoadingFetch: boolean,
    isDisabled: (date: string) => boolean,
    valueLabelFormatter?: (lab: string) => void
    dateFormatter1: (str: string) => void
    dateFormatter2: (str: string) => void
    // disabledDate?: string
}
const FilterDatePeriodYear = (props: propTypes) => {

    const { t } = useTranslation()

    const { placement, control, fieldName1, fieldName2, label1, label2, datesData, handleCloseFilter, isLoadingFetch, isDisabled, valueLabelFormatter, dateFormatter1, dateFormatter2 } = props

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const defaultValue = ''
    const field1 = useController({
        name: fieldName1,
        control,
        defaultValue: defaultValue
    });
    const value1 = field1.field.value
    const onChange1 = field1.field.onChange

    const field2 = useController({
        name: fieldName2,
        control,
        defaultValue: defaultValue
    });
    const value2 = field2.field.value
    const onChange2 = field2.field.onChange
    // const {
    //     field: { value, onChange }
    // } = useController({
    //     name: fieldName2,
    //     control,
    //     defaultValue: defaultValue
    // });

    const handleSelectDate1 = (date: string) => {
        const newDateTo = dateFormatter1(date)
        onChange1(date)
        onChange2(newDateTo)
        handleCloseFilter()
        setOpen(false)
    }
    const handleSelectDate2 = (date: string) => {
        const newDateFrom = dateFormatter2(date)
        onChange1(newDateFrom)
        onChange2(date)
        handleCloseFilter()
        setOpen(false)
    }


    return (
        <div className=''>
            <div className='flex items-center gap-[8px]'>
                <Typography className='text-gray-700'>
                    {t('filters.period')}
                </Typography>

                <Popover
                    placement={placement}
                    open={open}
                    handler={(e) => {
                        setOpen(e)
                        // if (!e && activeYear && activeMonth) {
                        //     setIsLoading(true)
                        //     onChange(`${activeMonth}-${activeYear}`)
                        //     handleCloseFilter()
                        // }
                    }
                    }
                >
                    <PopoverHandler >
                        <div className='w-[200px] des:w-[224px]'>
                            <BtnFilter
                                icon={<CalendarIcon />}
                            >
                                {value1 ?
                                    valueLabelFormatter ?
                                        valueLabelFormatter(value1)
                                        :
                                        value1
                                    : label1}
                            </BtnFilter>
                        </div>
                    </PopoverHandler >
                    <PopoverContent className='p-[24px] z-[11]'>
                        <Typography variant='h4' className='mb-[24px] text-gray-800 !text-[20px]'>
                            {label1}
                        </Typography>
                        <MonthYearSelect
                            handleSelectDate={handleSelectDate1}
                            isDisabled={isDisabled}
                            datesData={datesData}
                            value={value1}
                        />
                    </PopoverContent>
                </Popover>
                <span className='rotate-90'>
                    <ArrowAccordionIcon />
                </span>
                <Popover
                    placement={placement}
                    open={open2}
                    handler={setOpen2}
                >
                    <PopoverHandler >
                        <div className='w-[200px] des:w-[224px]'>
                            <BtnFilter
                                icon={<CalendarIcon />}
                            >
                                {value2 ?
                                    valueLabelFormatter ?
                                        valueLabelFormatter(value2)
                                        :
                                        value2
                                    : label2}
                            </BtnFilter>
                        </div>
                    </PopoverHandler >
                    <PopoverContent className='p-[24px] z-[11]'>
                        <Typography variant='h4' className='mb-[24px] text-gray-800 !text-[20px]'>
                            {label2}
                        </Typography>
                        <MonthYearSelect
                            handleSelectDate={handleSelectDate2}
                            isDisabled={isDisabled}
                            datesData={datesData}
                            value={value2}
                        />
                    </PopoverContent>
                </Popover>
            </div>
        </div >
    )
}

export default FilterDatePeriodYear
