import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useViewFactualQuery, useViewFactInfoTkoQuery } from '../../../store/auth/authService'
import { Button, Typography } from '@material-tailwind/react'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import AmountTitle from '../views/AmountTitle'
import TkoInfoCard from '../TkoInfoCard'
import { useEffect, useState } from 'react';
import DaysGraphs from './DaysGraphs';
import MonthGraph from './MonthGraph';
import { setContractId, setServiceId } from '../../../store/service/contractSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { Helmet } from 'react-helmet';
import MobileAmountTitle from '../views/MobileAmountTitle';
import { errorToast } from '../../../components/toasts/toasts';
import axios from 'axios';
import { baseApi } from '../../../ConfigApi';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';

const ViewFactual = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch<AppDispatch>()

    const { or_number } = useSelector((state: RootState) => state.contract)

    const { param_contract_id, param_tko_id } = useParams<{
        param_contract_id: string;
        param_tko_id: string;
    }>()


    // const isSummaryView = tkosParamArr


    //  isChecked - true = type - 1 - по дням
    //  isChecked - false = type - 2 - за месяц
    const [isChecked, setIsChecked] = useState(true);

    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const { data, isLoading } = useViewFactualQuery({
        contract_id: Number(param_contract_id),
        // fact_id: [Number(param_tko_id)],
        id: Number(param_tko_id),
        type: isChecked ? 1 : 2
    })

    const tkoData = useViewFactInfoTkoQuery({
        contract_id: Number(param_contract_id),
        id: Number(param_tko_id)
    })

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);

    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    const handleDownloadConsExcel = () => {
        if (param_tko_id) {

            setIsLoadingSave(true)

            const formSend = {
                contract_id: Number(param_contract_id),
                fact_id: [param_tko_id]
            }
            // const filename = `${t('or')} ${or_number} - ${t('factual.view_factual_title')} ${t('by_period')} ${data?.month || ''}-${data?.year || ''}.xls`
            const filename = `${t('factual.view_factual_title')}.xlsx`

            axios.post(`${baseApi}/api/ca/consumption-fact/view-matrix-for-excel`, formSend)
                .then((response: any) => {
                    if (response.data.res === 'error') {
                        errorToast({
                            title: t('factual.view_factual_title'),
                            text: t('error_on_download')
                        })
                    }
                    else {
                        setTimeout(() => {
                            axios.post(`${baseApi}/api/ca/consumption-fact/view-matrix-for-excel-export`, { url: response.data.url, contract_id: Number(param_contract_id) }, { responseType: 'blob' })
                                .then((response: any) => {
                                    fileDownload(response.data, filename)
                                })
                                .finally(() => {
                                    setIsLoadingSave(false)
                                })
                        }, 3000)
                    }
                })
                .catch(error => {
                    console.error(error);
                    errorToast({
                        title: t('factual.view_factual_title'),
                        text: t('error_on_download')
                    })
                })

        }
    }

    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('factual.view_factual_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('factual.view_factual_title')}
                </title>
            </Helmet>
            {tkoData?.data?.tko && <TkoInfoCard tko={tkoData.data.tko} />}

            <div className='flex flex-wrap mob:flex-row justify-between mob:items-center gap-[24px] my-[24px] tab:my-[32px]'>
                <div className='tab:hidden grow'>
                    <MobileAmountTitle
                        text={t('consumption.amount_3_month')}
                        amount={data?.total}
                        amountTitle={t('kvHour')}
                        isFetching={false}
                    />
                </div>
                <div className="hidden tab:flex">
                    <AmountTitle
                        text={t('consumption.amount_total_month')}
                        amount={data?.total}
                        // amount={samplePageData.total}
                        amountTitle={t('kvHour')}
                        isFetching={false}
                    />
                </div>
                <div className='grow tab:grow-0 flex items-center tab:items-normal gap-[4px]'>
                    <Button
                        // disabled={!computedLink}
                        color='white'
                        className={`relative hidden tab:flex  `}
                        onClick={handleNavigateBack}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        color='black'
                        className='whitespace-nowrap w-full'
                        onClick={handleDownloadConsExcel}
                        disabled={isLoadingSave || isLoading}
                        loading={isLoadingSave}
                    >
                        {t('consumption.download_excel')}
                    </Button>
                </div>
            </div>
            <div className={`rounded-[16px] lap:rounded-[24px] p-[16px] mob:p-[24px] tab:p-[32px] lap:p-[48px] bg-white border-2 border-gray-100`}>
                <div className='mb-[24px] tab:mb-[32px] flex flex-col mob:flex-row mob:items-center justify-between'>

                    <div className='flex flex-col gap-[8px] mb-[12px] mob:mb-0'>
                        <Typography variant='lead' className='text-gray-800 font-medium  text-[20px] tab:text-[24px]'>
                            {t('factual.view_factual_title')}
                        </Typography>
                        {
                            isChecked &&
                            <div className='flex flex-wrap items-center gap-[16px]'>
                                <Typography variant='lead' className='flex items-center gap-[5px] text-gray-700 font-normal text-[14px]'>
                                    <span className='block w-[10px] h-[10px] rounded-full bg-orange-500' />
                                    {t('factual.line_consumption_title')}
                                </Typography>
                                <Typography variant='lead' className='flex items-center gap-[5px] text-gray-700 font-normal text-[14px]'>
                                    <span className='block w-[10px] h-[10px] rounded-full bg-gray-400' />
                                    {t('factual.line_declared_title')}
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className="relative h-[44px] mob:w-[220px] w-full mt-[12px] tab:mb-0">
                        <input
                            // disabled={!service_id}
                            checked={isChecked}
                            onChange={() => {
                                setIsChecked(prev => !prev)
                            }}
                            id="switch-component-custom"
                            type="checkbox"
                            className="peer appearance-none h-[44px] mob:w-[220px] w-full rounded-full
                                bg-gray-50
                                cursor-pointer easy transition-colors duration-200
                                disabled:bg-gray-200
                                "
                        />
                        <label
                            htmlFor="switch-component-custom"
                            className=" 
                        flex items-center justify-center
                        absolute z-10 top-[4px] right-[4px] w-[50%] mob:w-[100px] h-[36px] 
                        peer-checked:translate-x-[-100%] 
                        mob:peer-checked:translate-x-[-112px] 
                        bg-gray-700 text-white text-[14px] font-medium
                        rounded-full  cursor-pointer
                        easy transition-all duration-200

                        peer-disabled:bg-gray-300
                        "
                        >
                            {
                                isChecked ?
                                    t('by_day')
                                    :
                                    t('by_month')
                            }
                        </label>
                        <div className="
                            flex items-center justify-around
                            absolute top-[0px] left-[0px] mob:w-[220px] w-full h-[44px] rounded-full
                            pointer-events-none
                            text-gray-600 text-[14px] font-medium
                            border-2 border-gray-200 
                        ">
                            <span>
                                {t('by_day')}
                            </span>
                            <span>
                                {t('by_month')}
                            </span>
                        </div>
                    </div>
                </div>

                {
                    isChecked &&
                    <DaysGraphs
                        year={data?.year}
                        month={data?.month}
                        array_cons={data?.array_cons}
                    />
                }
                {
                    !isChecked && data?.data_declared && data?.data_cons &&
                    <MonthGraph
                        tooltipDate={`${t(`r_month_${data.month}`)} ${data.year}`}
                        data_declared={data?.data_declared}
                        data_cons={data?.data_cons}
                    />
                }
            </div>

        </PageLayout >
    )
}

export default ViewFactual
