import { Fragment, useState } from 'react'
import { isIterableArray, toFormatPrice } from '../../../utils/utils'
import { Button, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DrawerCard from '../../../components/mobile/DrawerCard'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import CopyButton from '../../tableHelpers/buttons/CopyButton'
import { PaymentRowType } from '../PaymentsPage.types'

type propType = {
    visibleRows: PaymentRowType[],
    isLoadingTable: boolean,
}
const PaymentsCards = ({
    visibleRows,
    isLoadingTable,
}: propType) => {

    const { t } = useTranslation()

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<PaymentRowType | null>(null);

    const closeDrawer = () => {
        setIsOpenCard(false)
        setModalValue(null)
    }
    const openDrawer = (row: PaymentRowType) => {
        setIsOpenCard(true)
        setModalValue(row)
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }

    return (
        <div className='tab:hidden lap:pb-[100px]'>
            <DrawerCard
                isOpen={isOpenCard}
                closeDrawer={closeDrawer}
            // modalValue={modalValue}
            >
                {
                    modalValue &&
                    <Fragment>
                        <div className="mb-6 flex items-center justify-between">
                            {
                                modalValue &&
                                <Typography variant='lead' className='text-[16px] font-bold text-gray-800'>
                                    {t('invoices_page.history.invoice_title')}:
                                    {/* {modalValue.number} */}
                                </Typography>
                            }
                            <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                <CloseDrawerIcon />
                            </IconButton>
                        </div>
                        <div className='flex flex-col gap-[10px] mb-6'>

                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.period`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.period}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.period`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.period}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.number`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.invoice_number}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.row_type`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.row_type}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.invoice_type`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.invoice_type}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.balance_id`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.balance_id}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.order_pay`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.order_pay}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`payments_page.amount`)}</span>
                                <span className='text-[16px] font-bold'> {modalValue.amount ? toFormatPrice(modalValue.amount) : '—'}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <div className=' text-[14px] font-medium'>
                                    {t(`payments_page.pay_purpose`)}
                                </div>
                                <span className='text-[12px] font-gray-600'> {modalValue.pay_purpose}</span>
                            </div>
                            {/*  

                            dataIndex: 'invoice_type',
                            label: 'payments_page.invoice_type',


                            dataIndex: 'balance_id',
                            label: `payments_page.balance_id`,

                            dataIndex: 'order_pay',
                            label: `payments_page.order_pay`,


                            dataIndex: 'amount',
                            label: `payments_page.amount`,
                            {item ? toFormatPrice(item) : '—'}

                            dataIndex: 'pay_purpose',
                            label: `payments_page.pay_purpose`, */}
                        </div>
                        <div className='flex flex-col gap-[10px] my-6'>
                            <Button onClick={closeDrawer} size='sm' color='white' className='w-full !rounded-full min-h-[50px] flex gap-[12px] items-center justify-center'>
                                {t('close')}
                            </Button>
                        </div>
                    </Fragment>
                }
            </DrawerCard>
            {
                isIterableArray(visibleRows) ?
                    visibleRows.map((item: PaymentRowType, key: number) => (
                        <Fragment key={key}>

                            {
                                item.period !== visibleRows?.[key - 1]?.period &&
                                <Typography variant='lead' className='text-[14px] font-bold text-gray-800 mb-[6px] mt-[10px]'>
                                    {dateParser(item.period)}
                                </Typography>
                            }
                            <div onClick={() => openDrawer(item)} className="bg-white active:bg-gray-50 transition-all rounded border-2 border-gray-100 p-[12px] mb-[6px] animate-appear">
                                <div className='w-full flex flex-wrap items-center justify-between gap-[4px] mb-[8px]'>
                                    <Typography variant='lead' className='text-[14px]  text-gray-800'>
                                        {t(`${item.row_type}`)}
                                    </Typography>
                                    <span className='text-[12px] p-[4px_8px] rounded-full bg-gray-50  font-bold'>{t(`${item.balance_id}`)}</span>
                                </div>
                                <div className='flex flex-wrap items-center justify-between gap-[4px]'>
                                    <div className="grid grid-cols-1">
                                        {/* <span className='text-[14px] font-medium'> {t(`invoices_page.amount_with_nds`)}</span> */}
                                        <span className='text-[16px] font-medium'> {toFormatPrice(Number(item.amount))}</span>
                                    </div>
                                    <span className='whitespace-nowrap text-[12px] font-medium'>{t('payments_page.date')} {item.date}</span>
                                </div>
                            </div>
                        </Fragment>
                    ))
                    :
                    isLoadingTable ?
                        <Spinner className='my-6 mx-auto' />
                        :
                        <Typography variant='lead' className='text-[20px] font-medium text-center p-6 '>
                            {t('tables.nothing_to_show')}
                        </Typography>
            }
        </div>
    )
}

export default PaymentsCards