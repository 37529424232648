import { Typography } from '@material-tailwind/react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import SwitchGasIcon from '../../../images/icons/SwitchGasIcon'
import { TkosType } from '../ContractPage.types'
import { useTranslation } from 'react-i18next'
import { isIterableArray } from '../../../utils/utils'
import LightBulbIcon from '../../../images/icons/LightBulbIcon'

const PointsContract = ({ data }: { data: TkosType[] }) => {

    const { t } = useTranslation()
    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const bgService = service_id === 1 ? 'bg-orange-500' : service_id === 2 ? 'bg-blue-600' : 'bg-gray-300'

    const RowItem = ({ children, className = '' }: { children: any, className?: string }) => (
        <div className={`flex justify-between w-full tab:flex-col gap-[8px] ${className}`}>
            {children}
        </div>
    )

    const IconItem = ({ sm = false }: { sm?: boolean }) => (
        <div className={`${bgService} ${sm ? 'flex tab:hidden min-w-[24px] h-[24px] ' : ' hidden tab:flex  min-w-[40px]  h-[40px] '} rounded-full  items-center justify-center `}>
            {
                service_id === 1 &&
                <LightBulbIcon className={`${sm ? 'h-[15px]' : 'h-[20px]'}`} />
            }
            {
                service_id === 2 &&
                <SwitchGasIcon className={`${sm ? 'h-[15px]' : 'h-[20px] w-[20px]'}`} />
            }
        </div>
    )
    return (

        <div className='mb-[-150px] tab:mb-[-220px]'>
            {/* <div className='mb-[96px] lap:mb-[128px]'> */}
            <Typography variant='h3' className='text-center text-gray-800 mb-[24px] tab:mb-[48px] lap:mb-[64px]'>
                {t('contract_page.points_title')}
            </Typography>
            <div className={`
                bg-white border-2 border-gray-100 rounded-[16px] lap:rounded-[24px] px-[24px] tab:px-[32px] py-[8px] mb-[48px] tab:mb-[96px] lap:mb-[128px]
            `}>
                {
                    isIterableArray(data) ?
                        data.map((item, key) => (
                            <div key={key} className='tab:flex items-start items-center flex-row overflow-auto py-[16px] lap:py-[24px] gap-[16px] lap:gap-[24px] border-b border-gray-100 last:border-0'>
                                <IconItem />
                                <div className={`flex flex-col items-start tab:items-center tab:flex-row lap:grow tab:grid ${service_id === 1 ? 'grid-cols-6' : 'grid-cols-5'} gap-[24px] tab:gap-[16px]`}>

                                    <RowItem className='min-w-[200px] !justify-start'>
                                        <IconItem sm />
                                        <p className={`hidden tab:block text-[14px] lap2:text-[16px] text-gray-700`}>
                                            {t('contract_page.points_name')}
                                        </p>
                                        <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800  '>
                                            {item.name ? item.name : ' - '}
                                        </p>
                                    </RowItem>
                                    {
                                        service_id === 1 &&
                                        <RowItem>
                                            <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                                {t('contract_page.points_eic')}
                                            </p>
                                            <p className='font-medium text-[14px] tab:text-[12px] lap:text-[14px]  lap2:text-[18px] text-gray-800 break-words'>
                                                {item.eic ? item.eic : ' - '}
                                            </p>
                                        </RowItem>
                                    }
                                    {
                                        service_id === 2 &&
                                        <RowItem>
                                            <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                                {t('contract_page.xcode')}
                                            </p>
                                            <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                                {item.xcode ? item.xcode : ' - '}
                                            </p>
                                        </RowItem>
                                    }
                                    {
                                        service_id === 2 &&
                                        <RowItem>
                                            <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                                {t('contract_page.virtual_tko')}
                                            </p>
                                            <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                                {item.virtual_tko ? item.virtual_tko : ' - '}
                                            </p>
                                        </RowItem>
                                    }
                                    {
                                        service_id === 1 &&
                                        <RowItem>
                                            <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                                {t('contract_page.points_class')}
                                            </p>
                                            <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                                {item.class ? item.class : ' - '}
                                            </p>
                                        </RowItem>
                                    }
                                    {
                                        service_id === 1 &&
                                        <RowItem>
                                            <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                                {t('contract_page.points_group')}
                                            </p>
                                            <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                                {item.group ? t(`contract_page.group_${item.group}`) : ' - '}
                                            </p>
                                        </RowItem>
                                    }
                                    <RowItem>
                                        <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                            {t('contract_page.points_operator')}
                                        </p>
                                        <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                            {item.operator ? item.operator : ' - '}
                                        </p>
                                    </RowItem>
                                    <RowItem>
                                        <p className={` text-[14px] lap2:text-[16px] text-gray-700`}>
                                            {t('contract_page.points_date_start')}
                                        </p>
                                        <p className='font-medium text-[14px] lap:text-[16px] lap2:text-[18px] text-gray-800'>
                                            {item.date_start ? item.date_start : ' - '}
                                        </p>
                                    </RowItem>
                                </div>
                            </div>
                        ))
                        :
                        <p className='text-[18px] text-gray-600 text-center my-[24px]'> {t('contract_page.points_no_data')}</p>
                }
            </div>
        </div >
    )
}

export default PointsContract