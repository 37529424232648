import React, { useEffect, useState } from 'react'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { ConsFormType } from './ViewConsumption'
import FilterDatePeriodYear from '../../tableHelpers/filters/FilterDatePeriodYear'
import FilterDatePeriodYearDrawer from '../../tableHelpers/filters/FilterDatePeriodYearDrawer'
import { ImageDownloadButton } from '../ImageDownloadButton'

const FiltersConsumption = (props: {
    value: ConsFormType,
    isLoadingFetch: boolean,
    isForSave: boolean,
    fetchPage: any,
    savePicture: any,
    // defaulPeriodFrom: string,
    // defaulPeriodTo: string,
}) => {
    const {
        value,
        isLoadingFetch,
        isForSave,
        fetchPage,
        savePicture,
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    const {
        control,
        getValues,
        setValue,
    } = useForm<ConsFormType>({
        defaultValues: {},
    })

    useEffect(() => {
        setValue('periodFrom', value.periodFrom)
        setValue('periodTo', value.periodTo)

    }, [value]);


    const [filtersState, setFiltersState] = useState<ConsFormType>(value);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (

        <div className='grid grid-cols-2 tab:flex justify-between items-center mb-[24px] lap:mb-[32px] mt-[12px] gap-[8px]'>
            <FilterDatePeriodYearDrawer
                isLoadingFetch={isLoadingFetch || !value.periodFrom}
                placement={'bottom-start'}
                control={control}
                fieldName1={'periodFrom'}
                fieldName2={'periodTo'}
                label1={t("filters.period_from")}
                label2={t("filters.period_to")}
                datesData={data}
                handleCloseFilter={handleCloseFilter}
            />
            <div className='hidden tab:flex flex-wrap justify-between grow items-center gap-[8px]'>
                <FilterDatePeriodYear
                    isLoadingFetch={isLoadingFetch || !value.periodFrom}
                    placement={'bottom-start'}
                    control={control}
                    fieldName1={'periodFrom'}
                    fieldName2={'periodTo'}
                    label1={t("filters.period_from")}
                    label2={t("filters.period_to")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return false
                    }}
                    dateFormatter1={(date: string) => moment(date, 'MM-YYYY').add(1, 'year').format('MM-YYYY')}
                    dateFormatter2={(date: string) => moment(date, 'MM-YYYY').add(-1, 'year').format('MM-YYYY')}
                />
            </div>

            <ImageDownloadButton text={t('download')} onClick={savePicture} disabled={isLoadingFetch || !isForSave} />
        </div>
    )
}

export default FiltersConsumption