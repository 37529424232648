import { useEffect, useState } from 'react'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FilterDateItem from '../../tableHelpers/filters/FilterDateItem'
import { useForm } from 'react-hook-form'
import { ImageDownloadButton } from '../ImageDownloadButton'

const FilterComparison = (props: {
    value: {
        period1: string,
        period2: string,
    },
    isLoadingFetch: boolean,
    fetchPage: any,
    savePictures: any,
    // disabledSave: any,
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        savePictures,
        // disabledSave
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    const {
        control,
        getValues,
        setValue,
    } = useForm<{
        period1: string,
        period2: string,
    }>({
        defaultValues: {},
    })

    useEffect(() => {
        setValue('period1', value.period1)
        setValue('period2', value.period2)
    }, [value]);


    const [filtersState, setFiltersState] = useState<{
        period1: string,
        period2: string,
    }>({
        period1: value.period1,
        period2: value.period2,
    });

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState({
            period1: values.period1,
            period2: values.period2,
        })
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (

        <div className='flex flex-wrap tab:flex-nowrap justify-center tab:items-center gap-[16px] tab:gap-[75px] my-[24px] tab:my-[32px]'>
            <div className='min-w-[40%] tab:w-auto tab:min-w-[auto] order-[1]'>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.period1}
                    placement={'bottom-start'}
                    control={control}
                    fieldName={'period1'}
                    label={t("filters.period")}
                    // valueLabelFormatter={(label: string) => {
                    //     const lab = moment(label, formatFilter).format("MMMM YYYY")
                    //     return <span>{String(lab).charAt(0).toUpperCase() + String(lab).slice(1)}</span>
                    // }}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return false
                    }}
                />
            </div>
            <ImageDownloadButton
                className='order-[4] tab:order-[2]'
                onClick={savePictures}
                text={t('download')}
            // disabled={disabledSave}
            />
            <div className='min-w-[40%] tab:w-auto tab:min-w-[auto] order-[3]'>
                <FilterDateItem
                    isLoadingFetch={isLoadingFetch || !value.period2}
                    placement={'bottom-start'}
                    control={control}
                    fieldName={'period2'}
                    label={t("filters.period")}
                    // valueLabelFormatter={(label: string) => {
                    //     const lab = moment(label, formatFilter).format("MMMM YYYY")
                    //     return <span>{String(lab).charAt(0).toUpperCase() + String(lab).slice(1)}</span>
                    // }}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return false
                    }}
                />
            </div>
        </div>
    )
}

export default FilterComparison