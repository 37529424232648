import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { ConsFormType } from './ViewConsumption';
import { useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useViewConsumptionQuery } from '../../../store/auth/authService';
import { Spinner, Typography } from '@material-tailwind/react';
import FiltersConsumption from './FiltersConsumption';
import GraphConsumption from './GraphConsumption';
import TableLayout from '../../../layouts/pagesLayouts/TableLayout';
import { useTranslation } from 'react-i18next';
import { saveGraphToPicture, toFormatPrice } from '../../../utils/utils';
import { classCharContainerHeight } from '../AnalyticsPage';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

export const isData = (arr: number[]) => {
    const is = !!arr.filter(i => i !== 0).length
    return is
}

const GRAPH_ID = "js-cons-by-period-graph"

const ViewByPeriod = ({ setPeriodMonth, periodMonth, isMobile }: {
    setPeriodMonth: any,
    periodMonth: string,
    isMobile: boolean
}) => {

    const { t } = useTranslation()

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)


    const defaulPeriodFrom = moment().add(-11, 'month').format('MM-YYYY')
    const defaulPeriodTo = moment().add(1, 'month').format('MM-YYYY')

    const form: ConsFormType = {
        contract_id: Number(param_contract_id) || 0,
        periodFrom: defaulPeriodFrom,
        periodTo: defaulPeriodTo
    }


    const [sendForm, setSendForm] = useState<ConsFormType>(form);

    // sendForm
    const { currentData, error, isFetching } = useViewConsumptionQuery<any>(sendForm)


    const setFormForFetch = (values: any) => {
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }

    const handleClick = (index: number, el: any) => {
        const period = currentData?.formatted?.labels?.[index]
        setPeriodMonth(period)
    }

    const datachar = useCallback(
        () => {
            const data = currentData.formatted
            const datasets = []
            if (isData(data.declared)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.declared'),
                data: data.declared,
                backgroundColor: '#444248',
                borderRadius: 4,
                spanGaps: false,
                stack: 'Stack1',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value, 3)}`;
                    },
                    textStrokeColor: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    textStrokeWidth: 1,
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#FDFDFD';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }

            })

            if (isData(data.declared_delta_less_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_less_6'),
                data: data.declared_delta_less_6,
                backgroundColor: '#73CD88',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })

            if (isData(data.declared_delta_more_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_more_6'),
                data: data.declared_delta_more_6,
                backgroundColor: '#FFD770',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.declared_delta_more_10)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_more_10'),
                data: data.declared_delta_more_10,
                backgroundColor: '#F87171',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact'),
                data: data.fact,
                backgroundColor: '#BDB4FE',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value, 3)}`;
                    },
                    // textStrokeColor: function (context: any) {
                    //     if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                    //     return '#FDFDFD';
                    // },
                    // textStrokeWidth: 1,
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            })
            if (isData(data.fact_delta_less_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_less_6'),
                data: data.fact_delta_less_6,
                backgroundColor: '#73CD88',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact_delta_more_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_more_6'),
                data: data.fact_delta_more_6,
                backgroundColor: '#FFD770',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact_delta_more_10)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_more_10'),
                data: data.fact_delta_more_10,
                backgroundColor: '#F87171',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })


            if (data) return {
                labels: data?.labels_string,
                datasets: datasets
            }
        },
        [currentData?.formatted],
    )

    return (
        // ${periodMonth ? 'opacity- 0 hidden' : 'opacity- 1 block'} 
        <div className={`transition-opacity  delay-[150ms] duration-[250ms]`}>
            <FiltersConsumption
                value={{
                    periodFrom: sendForm.periodFrom,
                    periodTo: sendForm.periodTo,
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                savePicture={() => {
                    saveGraphToPicture(GRAPH_ID, `${t('or')} ${or_number} - ${t('analytics.analytics_cons_by_period_title')} ${t('by_period')} ${sendForm.periodFrom} - ${sendForm.periodTo}.png`)
                }}
                isForSave={!!currentData?.isForSave}
            />
            {

                currentData?.formatted ?
                    <div className='bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                        <Typography variant='lead' className='text-gray-800 text-center mb-[24px] tab:mb-[32px] text-[20px] lap:text-[24px] text-left'>
                            {t('analytics.analytics_cons_by_period_title')}
                        </Typography>
                        <div className={classCharContainerHeight}>
                            <GraphConsumption
                                graphId={GRAPH_ID}
                                handleClickGraph={handleClick}
                                datachar={datachar()}
                                isMobile={isMobile}
                            />
                        </div>
                    </div>
                    :
                    isFetching ?
                        <div className={`${classCharContainerHeight} bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            ''
            }

        </div>
    )
}

export default ViewByPeriod