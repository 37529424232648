import { Drawer, IconButton, Spinner, Typography } from '@material-tailwind/react'
import React from 'react'
import axios from 'axios';
import { baseApi } from '../../ConfigApi';

import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store/store'
import { removeToken } from '../../store/auth/authSlice'
import { useGetInfoManagerViewQuery } from '../../store/auth/authService'
import UserManager from './UserManager'
import { createPortal } from 'react-dom'
import { FILTER_SCREEN_MOBILE, MOBILE_DIVIDER_OFFSET } from '../../utils/variables'
import useIsMobile from '../../utils/useIsMobile'

const DrawerUserMenu = ({ open, handleClose, handleNewConnection }: any): any => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)

    const { service_id, contract_id } = useSelector((state: RootState) => state.contract)

    const { currentData, isFetching } = useGetInfoManagerViewQuery({
        contract_id: contract_id || 0
    }, {
        skip: !open || !contract_id
    })

    const handleLogout = () => {

        axios.get(`${baseApi}/api/auth/logout/${localStorage.getItem('userToken')}`)
            .then((response: any) => { })
            .catch(error => { })
            .finally(() => { })

        localStorage.removeItem('userToken')
        navigate('/auth')
        dispatch(removeToken())
    }

    const textService = service_id === 1 ? 'hover:bg-blue-500' : 'hover:bg-orange-600'

    const placement = isMobile ? 'bottom' : 'right'
    const size = isMobile ? window.innerHeight - MOBILE_DIVIDER_OFFSET : 500

    return (
        // <Drawer
        //     placement={placement}
        //     size={size}
        //     open={open}
        //     onClose={handleClose}
        //     // ${open ? 'tab:!right-[12px] tab:!top-[12px] tab:!bottom-[-12px]' : 'tab:!right-0'}
        //     className={`p-4  left-0 right-0  tab:!left-auto !bottom-0  tab:!max-w-[500px]  flex flex-col overflow-auto`}
        // >
        //     <div className='flex flex-col h-[100%]'>
        //         <div className="mb-6 flex items-center justify-between">
        //             <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
        //                 {t('profile_menu.profile_menu_title')}
        //             </Typography>
        //             <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
        //                 <CloseDrawerIcon />
        //             </IconButton>
        //         </div>
        //         <div className="flex flex-col items-start gap-[16px] my-[24px]">
        //             <NavLink to='/profile' onClick={handleClose} className={'block leading-5 px-[16px] py-[6px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] hover:bg-gray-800 transition-all'}>
        //                 {t('profile_menu.my_profile')}
        //             </NavLink>
        //             <NavLink to='/support' onClick={handleClose} className={'group flex items-center gap-[4px]'}>
        //                 <div className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] group-hover:bg-gray-800 transition-all'}>
        //                     {t('profile_menu.my_appeals')}
        //                 </div>
        //             </NavLink>
        //             <NavLink to='#' onClick={handleNewConnection} className={`${textService} block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] transition-all`}>
        //                 {t('profile_menu.new_connection')}
        //             </NavLink>
        //             <NavLink to='#' onClick={handleLogout} className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] hover:bg-gray-800 transition-all'}>
        //                 {t('profile_menu.logout')}
        //             </NavLink>
        //         </div>
        //         <div className='mt-auto'>
        //             {
        //                 isFetching ?
        //                     <div className='flex items-center justify-center w-full min-h-200'>
        //                         <Spinner />
        //                     </div>
        //                     :
        //                     currentData ? <UserManager data={currentData} />
        //                         :
        //                         ''
        //             }
        //         </div>
        //     </div>

        // </Drawer>
        createPortal(
            <Drawer
                placement={placement}
                size={size}
                open={open}
                onClose={handleClose}
                className={`p-4 ${open ? 'tab:!right-[12px] tab:!top-[12px] tab:!bottom-[-12px] opacity-1' : 'tab:!right-0 opacity-0 duration-[250ms]'} left-0 right-0  tab:!left-auto !bottom-0  tab:!max-w-[500px]  flex flex-col overflow-auto`}
            >
                <div className='flex flex-col h-[100%]'>
                    <div className="mb-6 flex items-center justify-between">
                        <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
                            {t('profile_menu.profile_menu_title')}
                        </Typography>
                        <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleClose}>
                            <CloseDrawerIcon />
                        </IconButton>
                    </div>
                    <div className="flex flex-col items-start gap-[16px] my-[24px]">
                        <NavLink to='/profile' onClick={handleClose} className={'block leading-5 px-[16px] py-[6px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] hover:bg-gray-800 transition-all'}>
                            {t('profile_menu.my_profile')}
                        </NavLink>
                        <NavLink to='/support' onClick={handleClose} className={'group flex items-center gap-[4px]'}>
                            <div className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] group-hover:bg-gray-800 transition-all'}>
                                {t('profile_menu.my_appeals')}
                            </div>
                        </NavLink>
                        <NavLink to='#' onClick={handleNewConnection} className={`${textService} block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] transition-all`}>
                            {t('profile_menu.new_connection')}
                        </NavLink>
                        <NavLink to='#' onClick={handleLogout} className={'block leading-5 px-[16px] py-[5px] rounded-full bg-gray-700 text-white text-[14px] tab:text-[18px] hover:bg-gray-800 transition-all'}>
                            {t('profile_menu.logout')}
                        </NavLink>
                    </div>
                    <div className='mt-auto'>
                        {
                            isFetching ?
                                <div className='flex items-center justify-center w-full min-h-200'>
                                    <Spinner />
                                </div>
                                :
                                currentData ? <UserManager data={currentData} />
                                    :
                                    ''
                        }
                    </div>
                </div>

            </Drawer>,
            document.getElementById('root')!
        )
    )
}

export default DrawerUserMenu