import toast from "react-hot-toast";
import CheckFilledIcon from "../../images/icons/CheckFilledIcon";
import CloseDrawerIcon from "../../images/icons/CloseDrawerIcon";

export const TOAST_POSITION = 'top-right'

const TOAST_DURATION_ERROR = 5000
const TOAST_DURATION_SUCCESS = TOAST_DURATION_ERROR / 2

export const errorToast = ({ title, text }: { title: string, text?: string }) => (
    toast.custom(
        (t) => (
            <ToastItem
                title={title}
                text={text}
                status="error"
                close={() => toast.dismiss(t.id)}
            />
        ), {
        duration: TOAST_DURATION_ERROR,
    }
    )
)
export const successToast = ({ title, text }: { title: string, text?: string }) => (
    toast.custom(
        (t) => (
            <ToastItem
                title={title}
                text={text}
                status="success"
                close={() => toast.dismiss(t.id)}
            />
        ),
        {
            duration: TOAST_DURATION_SUCCESS
        }
    )
)

const ToastItem = ({ title, text, status, close }:
    {
        title: string,
        text?: string,
        close: () => void,
        status: 'error' | 'success'

    }) => (
    <div style={{ backdropFilter: 'blur(2px)', background: '#FFF' }} className='transition-all min-w-[320px] max-w-[480px] flex items-start justify-between gap-[24px] border-2 border-gray-200 rounded-[16px] px-[24px] py-[20px]'>
        <div className="flex items-center gap-[24px]">
            {
                status === 'error'
                &&
                <div className="flex items-center justify-center rounded-full min-w-[20px] h-[20px] bg-red-500">
                    <CloseDrawerIcon className='stroke-white w-[10px] h-[10px] ' />
                </div>
            }
            {
                status === 'success'
                &&
                <CheckFilledIcon className='fill-green-500  w-[20px] h-[20px] ' />
            }
            <div className='flex flex-col gap-[4px] '>
                <span className='text-gray-800 text-[18px] font-medium'>{title}</span>
                {text && <span className='text-gray-800 text-[16px]'>{text}</span>}
            </div>
        </div>
        <button onClick={(e) => {
            e.stopPropagation()
            close()
        }}>
            <CloseDrawerIcon />
        </button>
    </div>
)