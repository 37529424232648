import { useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, Button, Drawer, IconButton, ListItem, Popover, PopoverContent, PopoverHandler, Typography } from '@material-tailwind/react'

import { useController } from 'react-hook-form'
import MonthYearSelect from './MonthYearSelect'
import { DateSelectType } from '../../contract/ContractPage.types'
import { placement } from '@material-tailwind/react/types/components/menu'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { createPortal } from 'react-dom'
import { MOBILE_DIVIDER_OFFSET } from '../../../utils/variables'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import FilterIcon from '../../../images/icons/FilterIcon'

type propTypes = {
    control: any,
    fieldName1: string,
    fieldName2: string,
    label1: string,
    label2: string,
    datesData: DateSelectType,
    handleCloseFilter: any,
    placement: placement,
    isLoadingFetch: boolean,
    valueLabelFormatter?: (lab: string) => void
    // disabledDate?: string
}
const FilterDatePeriodYearDrawer = (props: propTypes) => {

    const { t } = useTranslation()

    const { placement, control, fieldName1, fieldName2, label1, label2, datesData, handleCloseFilter, isLoadingFetch } = props



    const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false); // drawer 

    const size = window.innerHeight - MOBILE_DIVIDER_OFFSET


    const handleOpenFilters = () => {
        setIsOpenFilters(true)
        document.body.style.overflowY = 'hidden'
        document.body.style.paddingRight = '6px'
    }

    const closeDrawer = () => {
        document.body.style.overflowY = 'auto'
        document.body.style.paddingRight = "0px"
        setIsOpenFilters(false)
        handleCloseFilter()
    }

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);


    // const defaultYear = 0
    // const defaultMonth = ''
    // const defaultYear = Number(moment().format('YYYY'))
    // const defaultMonth = moment().format('MM') 




    // const defaultValue = defaultMonth + '-' + defaultYear
    const defaultValue = ''
    const field1 = useController({
        name: fieldName1,
        control,
        defaultValue: defaultValue
    });
    const value1 = field1.field.value
    const onChange1 = field1.field.onChange

    const field2 = useController({
        name: fieldName2,
        control,
        defaultValue: defaultValue
    });
    const value2 = field2.field.value
    const onChange2 = field2.field.onChange


    const handleSelectDate1 = (date: string) => {
        const newDateTo = moment(date, 'MM-YYYY').add(1, 'year').format('MM-YYYY')
        onChange1(date)
        onChange2(newDateTo)
    }
    const handleSelectDate2 = (date: string) => {
        const newDateFrom = moment(date, 'MM-YYYY').add(-1, 'year').format('MM-YYYY')
        onChange1(newDateFrom)
        onChange2(date)
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }
    return (
        <div className='tab:hidden grow'>
            {
                createPortal(
                    <Drawer
                        placement={'bottom'}
                        open={isOpenFilters}
                        onClose={closeDrawer}
                        className={`tab:hidden p-4 !pb-[48px] ${!isOpenFilters && 'tab:!left-0'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto no-scrollbar`}
                        size={size}
                    >
                        <div className="mb-6 flex items-center justify-end">

                            <div className="flex items-center gap-[40px]">

                                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                    <CloseDrawerIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <div className='flex flex-col gap-[8px]'>
                                <Accordion
                                    open={open}
                                    icon={<div className={`${open ? '' : 'rotate-180'}`}><ArrowAccordionIcon /></div>}
                                    className='tab:mb-[24px]'
                                >
                                    <ListItem className='p-0'>
                                        <AccordionHeader
                                            className='bg-white border-2 border-gray-100 rounded p-[16px]'
                                            onClick={() => setOpen(prev => !prev)}
                                        >
                                            <div className="flex items-start justify-between">
                                                <div className='flex flex-col gap-[0px]'>
                                                    <Typography variant="lead" className='text-[14px] text-gray-800 font-bold flex items-center gap-[10px]' >
                                                        <span>{label1}</span>
                                                        {
                                                            value1 ? <>
                                                                <span>{dateParser(value1)}</span>
                                                            </> : ''
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </AccordionHeader>
                                    </ListItem>
                                    <AccordionBody className='mt-0 tab:mt-[16px]'>
                                        <MonthYearSelect
                                            valueForce={value1}
                                            handleSelectDate={handleSelectDate1}
                                            isDisabled={(date: string) => {
                                                return moment(value2, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                                            }}
                                            datesData={datesData}
                                            value={value1}
                                        />
                                    </AccordionBody>
                                </Accordion>
                                <Accordion
                                    open={open2}
                                    icon={<div className={`${open2 ? '' : 'rotate-180'}`}><ArrowAccordionIcon /></div>}
                                    className='tab:mb-[24px]'
                                >
                                    <ListItem className='p-0'>
                                        <AccordionHeader
                                            className='bg-white border-2 border-gray-100 rounded p-[16px]'
                                            onClick={() => setOpen2(prev => !prev)}
                                        >
                                            <div className="flex items-start justify-between">
                                                <div className='flex flex-col gap-[0px]'>
                                                    <Typography variant="lead" className='text-[14px] text-gray-800 font-bold flex items-center gap-[10px]' >
                                                        <span>{label1}</span>
                                                        {
                                                            value2 ? <>
                                                                <span>{dateParser(value2)}</span>
                                                            </> : ''
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                        </AccordionHeader>
                                    </ListItem>
                                    <AccordionBody className='mt-0 tab:mt-[16px]'>
                                        <MonthYearSelect
                                            valueForce={value2}
                                            handleSelectDate={handleSelectDate2} isDisabled={(date: string) => {
                                                return moment(value1, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                                            }}
                                            datesData={datesData}
                                            value={value1}
                                        />
                                    </AccordionBody>
                                </Accordion>
                            </div>
                        </div >
                    </Drawer>,
                    document.getElementById('root')!
                )
            }
            <Button
                onClick={handleOpenFilters}
                color='white'
                className='flex justify-center items-center gap-[8px] text-center text-[14px] w-full'
            >
                <FilterIcon />
                <span>{t('filters.filters_title')}</span>
            </Button>
        </div>
    )
}

export default FilterDatePeriodYearDrawer
