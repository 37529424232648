import { Button, Spinner, Typography } from '@material-tailwind/react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableLayout from '../../../layouts/pagesLayouts/TableLayout'
import GraphConsumption from './GraphConsumption'
import { useViewConsumptionMonthQuery } from '../../../store/auth/authService'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import FilterByMonth from './FilterByMonth'
import { ConsByMonthFormType, dayFormatFilter, dayFormatView, monthFormatChange, monthFormatFilter, monthFormatView } from './ViewConsumption'
import { saveGraphToPicture, toFormatPrice } from '../../../utils/utils'
import ViewByMonthDetailing from './ViewByMonthDetailing'
import { isData } from './ViewByPeriod'
import { classCharContainerHeight } from '../AnalyticsPage'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const GRAPH_ID = "js-cons-by-month-graph"
export const GRAPH_TARIF_MONTH = "js-tarif-by-month-graph"
export const GRAPH_PRICE_MONTH = "js-price-by-month-graph"

const ViewByMonth = ({ setPeriodMonth, setPeriodDay, periodMonth }: {
    setPeriodMonth: any,
    setPeriodDay: any,
    periodMonth: string,
}) => {
    const { t } = useTranslation()
    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)


    const [sendForm, setSendForm] = useState<ConsByMonthFormType>({
        contract_id: Number(param_contract_id) || 0,
        period: periodMonth,
    });

    const { currentData, error, isFetching } = useViewConsumptionMonthQuery<any>(sendForm, {
        skip: periodMonth === '',
    })

    useEffect(() => {
        setSendForm({
            contract_id: Number(param_contract_id) || 0,
            period: periodMonth,
        })
    }, [periodMonth]);

    const handleClickGraph = (index: number, el: any) => {
        const day = currentData?.formatted?.labels?.[index]
        setPeriodDay(`${day}-${periodMonth}`)
    }

    const setFormForFetch = (values: any) => {
        setPeriodMonth(values.period)
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }

    const datachar = useCallback(
        () => {
            const data = currentData.formatted
            const datasets = []
            if (isData(data.declared)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.declared'),
                data: data.declared,
                backgroundColor: '#444248',
                borderRadius: 4,
                spanGaps: false,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value, 3)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#FDFDFD';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }

            })
            if (isData(data.declared_delta_less_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_less_6'),
                data: data.declared_delta_less_6,
                backgroundColor: '#73CD88',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.declared_delta_more_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_more_6'),
                data: data.declared_delta_more_6,
                backgroundColor: '#FFD770',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.declared_delta_more_10)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.declared_delta_more_10'),
                data: data.declared_delta_more_10,
                backgroundColor: '#F87171',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact'),
                data: data.fact,
                backgroundColor: '#BDB4FE',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value, 3)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            })
            if (isData(data.fact_delta_less_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_less_6'),
                data: data.fact_delta_less_6,
                backgroundColor: '#73CD88',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact_delta_more_6)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_more_6'),
                data: data.fact_delta_more_6,
                backgroundColor: '#FFD770',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })
            if (isData(data.fact_delta_more_10)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.fact_delta_more_10'),
                data: data.fact_delta_more_10,
                backgroundColor: '#F87171',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack2',
                datalabels: {
                    display: false,
                }
            })
            if (data) return {
                labels: data?.labels,
                datasets: datasets,
            }
        },
        [currentData?.formatted],
    )

    const titleTooltipFormatter = (val: string) => {
        return moment(`${val}-${periodMonth}`, dayFormatFilter).format(dayFormatView)
    }

    return (
        // ${(periodMonth && !periodDay) ? 'opacity- 1 block' : 'opacity- 0 hidden'}
        <div className={`  transition-opacity delay-[150ms] duration-[250ms]`}>


            <FilterByMonth
                formatView={monthFormatView}
                formatFilter={monthFormatFilter}
                formatChange={monthFormatChange}
                value={{
                    period: periodMonth,
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                savePicture={() => {
                    saveGraphToPicture(GRAPH_ID, `${t('or')} ${or_number} - ${t('analytics.analytics_cons_by_month_title')} ${t('by_period')} ${periodMonth}.png`)
                    saveGraphToPicture(GRAPH_TARIF_MONTH, `${t('or')} ${or_number} - ${t('analytics.dataPrice')} ${t('by_period')} ${periodMonth}.png`)
                    saveGraphToPicture(GRAPH_PRICE_MONTH, `${t('or')} ${or_number} - ${t('analytics.dataTotal')} ${t('by_period')} ${periodMonth}.png`)
                }}
            />

            <div className='grid lap:grid-cols-[65%_35%] lap2:grid-cols-[75%_25%] gap-[24px] lap:gap-[32px]'>
                <div className='h-full'>
                    {

                        currentData?.formatted ?
                            <>
                                <div className='h-full bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                                    <Typography variant='lead' className='text-gray-800 text-center mb-[24px] tab:mb-[32px] text-[20px] lap:text-[24px] text-left'>
                                        {t('analytics.analytics_cons_by_month_title')} {moment(periodMonth, 'MM-YYYY').format('MMMM')}
                                    </Typography>
                                    <div className={classCharContainerHeight}>
                                        <GraphConsumption
                                            graphId={GRAPH_ID}
                                            titleTooltipFormatter={titleTooltipFormatter}
                                            handleClickGraph={handleClickGraph}
                                            datachar={datachar()}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            isFetching ?
                                <div className={`${classCharContainerHeight} !h-full  bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] flex justify-center items-center`}>
                                    <Spinner />
                                </div>
                                :
                                error ?
                                    <TableLayout>
                                        <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                            {t('tables.error_on_loading')}
                                        </Typography>
                                    </TableLayout>
                                    :
                                    ''
                    }
                </div>
                <div className={`h-full bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] min-h-[400px]`}>
                    <ViewByMonthDetailing
                        periodMonth={periodMonth}
                    />
                </div>
            </div>
        </div>
    )
}

export default ViewByMonth