import { Button, Checkbox, Dialog, DialogBody, Typography } from '@material-tailwind/react'
import React, { ReactPortal } from 'react'
import { createPortal } from 'react-dom'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import { useTranslation } from 'react-i18next'

const ModalAddition = ({ open, handleClose, register }: {
    register: any,
    open: boolean,
    handleClose: () => void,
}): ReactPortal | null => {

    const { t } = useTranslation()

    return (
        createPortal(
            <>
                <Dialog open={open} handler={handleClose} className='!min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px]'>

                    <DialogBody className="p-0">
                        <div className="flex items-center justify-between mb-[32px]">
                            <Typography variant="lead" className="text-[18px] tab:text-[24px] text-gray-800 font-medium ">
                                {t('new_connection.addition_modal_title')}
                            </Typography>
                            <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                <CloseDrawerIcon />
                            </div>
                        </div>
                        <Checkbox
                            {...register(`addition_service_1`)}
                            label={
                                <div className='flex items-center flex-wrap mb-[12px] tab:mb-0 gap-[4px] tab:gap-[16px]'>
                                    {t('new_connection.addition_service_1')}
                                    {/* <span className='block px-[12px] py-[4px] bg-blue-100 text-blue-700 text-[14px] font-normal rounded-full '>{t('new_connection.free_for_client')}</span> */}
                                </div>
                            }
                        />
                        <Checkbox
                            {...register(`addition_service_2`)}
                            label={
                                <div className='flex items-center flex-wrap mb-[12px] tab:mb-0 gap-[4px] tab:gap-[16px]'>
                                    {t('new_connection.addition_service_2')}
                                    {/* <span className='block px-[12px] py-[4px] bg-blue-100 text-blue-700 text-[14px] font-normal rounded-full '>{t('new_connection.free_for_client')}</span> */}
                                </div>
                            }
                        />
                        <Checkbox
                            {...register(`addition_service_3`)}
                            label={
                                <div className='flex items-center flex-wrap mb-[12px] tab:mb-0 gap-[4px] tab:gap-[16px]'>
                                    {t('new_connection.addition_service_3')}
                                    {/* <span className='block px-[12px] py-[4px] bg-blue-100 text-blue-700 text-[14px] font-normal rounded-full '>{t('new_connection.free_for_client')}</span> */}
                                </div>
                            }
                        />
                        <Checkbox
                            {...register(`addition_service_4`)}
                            label={t('new_connection.addition_service_4')}
                        />
                        <Button color='black' className='mt-[32px] w-full' onClick={handleClose}>
                            {t('new_connection.addition_btn_confirm')}
                        </Button>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ModalAddition