import { Button, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewDetailingInfoQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import TkoInfoCard from '../TkoInfoCard'
import MatrixDays from '../matrix/MatrixDays'
import MatrixMonth from '../matrix/MatrixMonth'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { DownloadExcelButton } from '../views/DownloadExcelButton'
import axios from 'axios'
import { errorToast } from '../../../components/toasts/toasts'
import fileDownload from 'js-file-download'
import { baseApi } from '../../../ConfigApi'
import { useSelector } from 'react-redux'

const ViewDetailing = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { param_contract_id, param_cons_id, param_year, param_month } = useParams()


    const { or_number } = useSelector((state: RootState) => state.contract)

    const dispatch = useDispatch<AppDispatch>()

    const { data, isLoading, error } = useViewDetailingInfoQuery({
        contract_id: Number(param_contract_id),
        id: Number(param_cons_id)
    })

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);


    const {
        control,
    } = useForm<any>()


    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const handleDownloadConsExcel = () => {
        if (param_cons_id) {

            setIsLoadingSave(true)

            const formSend = {
                contract_id: Number(param_contract_id),
                cons_id: [param_cons_id]
            }
            // const filename = `${t('or')} ${or_number} - ${t('detailing.view_detailing_title')} ${t('by_period')} ${data?.month || ''}-${data?.year || ''}.xls`
            const filename = `${t('detailing.view_detailing_title')}.xlsx`
            
            axios.post(`${baseApi}/api/ca/consumption-detailing/view-matrix-for-excel`, formSend)
                .then((response: any) => {
                    if (response.data.res === 'error') {
                        errorToast({
                            title: t('detailing.view_detailing_title'),
                            text: t('error_on_download')
                        })
                    }
                    else {
                        setTimeout(() => {
                            axios.post(`${baseApi}/api/ca/consumption-detailing/view-matrix-for-excel-export`, {url: response.data.url, contract_id: Number(param_contract_id)}, { responseType: 'blob' })
                            .then((response: any) => {
                                fileDownload(response.data, filename)
                            })
                            .finally(() => {
                                setIsLoadingSave(false)
                            })
                        }, 3000)
                    }
                })
                .catch(error => {
                    console.error(error);
                    errorToast({
                        title: t('detailing.view_detailing_title'),
                        text: t('error_on_download')
                    })
                })

        }
    }
    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('detailing.view_detailing_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('detailing.view_detailing_title')}
                </title>
            </Helmet>
            {
                data?.tko && <TkoInfoCard tko={data.tko} />
            }
            {
                data?.matrix_detailing === 1 &&
                <MatrixDays
                    control={control}
                    data={data.matrix}
                    isEditAccess={false}
                    DownloadExcelButton={() => (
                        <Button
                            color='black'
                            className='whitespace-nowrap w-full'
                            onClick={handleDownloadConsExcel}
                            disabled={isLoadingSave || isLoading}
                            loading={isLoadingSave}
                        >
                            {t('consumption.download_excel')}
                        </Button>
                    )}
                    amount={data?.amount}
                    handleNavigateBack={handleNavigateBack}
                />
            }
            {
                data?.matrix_detailing === 2 &&
                isIterableArray(data?.matrix) &&
                <MatrixMonth
                    amount={data?.amount}
                    daysLength={data.matrix[0].length}
                    isEditAccess={false}
                    detailing={data.matrix}
                    DownloadExcelButton={() => (
                        <Button
                            color='black'
                            className='whitespace-nowrap w-full'
                            onClick={handleDownloadConsExcel}
                            disabled={isLoadingSave || isLoading}
                            loading={isLoadingSave}
                        >
                            {t('consumption.download_excel')}
                        </Button>
                    )}
                    handleNavigateBack={handleNavigateBack}
                />
            }
            {/* <ConsumptionsPage /> */}
        </PageLayout>
    )
}

export default ViewDetailing
