import { Spinner, Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TableLayout from '../../../layouts/pagesLayouts/TableLayout'
import { useViewConsumptionDetailMonthQuery } from '../../../store/auth/authService'
import { useParams } from 'react-router-dom'
import { ConsByMonthFormType } from './ViewConsumption'
import DoughnutGraphController from './DoughnutGraphController'
import { classCharContainerHeight } from '../AnalyticsPage'

const ViewByMonthDetailing = ({
    periodMonth,
}: {
    periodMonth: string,
}) => {
    const { t } = useTranslation()
    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()



    const [sendForm, setSendForm] = useState<ConsByMonthFormType>({
        contract_id: Number(param_contract_id) || 0,
        period: periodMonth,
    });

    const { currentData, error, isFetching } = useViewConsumptionDetailMonthQuery<any>(sendForm, {
        skip: periodMonth === '',
    })

    useEffect(() => {
        setSendForm({
            contract_id: Number(param_contract_id) || 0,
            period: periodMonth,
        })
    }, [periodMonth]);

    return (
        // ${(periodMonth && !periodDay) ? 'opacity- 1 block' : 'opacity- 0 hidden'}
        <div className={`transition-opacity delay-[150ms] duration-[250ms]`}>
            {
                currentData ?
                    Object.keys(currentData)?.length === 0 ?
                        <div className={`flex items-center justify-center ${classCharContainerHeight}`}>
                            <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                {t('analytics.month_detailing_no_data')}
                            </Typography>
                        </div>
                        :
                        <DoughnutGraphController
                            currentData={currentData}
                        />
                    :
                    isFetching ?
                        <div className={`${classCharContainerHeight} flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                {t('analytics.month_detailing_no_data')}
                            </Typography>
            }
        </div>
    )
}

export default ViewByMonthDetailing