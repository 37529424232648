import { Button, Drawer, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import AccordionFilter from './AccordionFilter'
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon'
import FilterIcon from '../../images/icons/FilterIcon'
import { isIterableArray } from '../../utils/utils'
import { FilterDataType, IFormInput } from './types'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { createPortal } from 'react-dom'
import { FILTER_SCREEN_MOBILE, MOBILE_DIVIDER_OFFSET } from '../../utils/variables'
import useIsMobile from '../../utils/useIsMobile'

const statusesFilters = [
    {
        name: 'filters.status.archive',
        id: 2,
    },
    {
        name: 'filters.status.active',
        id: 1,
    },
]

const Filters = ({
    isFetching,
    defaultValue,
    legalFilters,
    handleUpdatePage,
    isOpenFilters,
    setIsOpenFilters
}: {
    isFetching: boolean,
    defaultValue: any,
    legalFilters: FilterDataType,
    handleUpdatePage: any,
    isOpenFilters: any,
    setIsOpenFilters: any
}) => {

    const { t } = useTranslation()


    const { service_id } = useSelector((state: RootState) => state.contract)

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        resetField,
        formState: { touchedFields },
    } = useForm<IFormInput>({
        defaultValues: {
            contract_id: defaultValue?.contracts || [],
            status: defaultValue?.status || [],
        },
    })

    const statusWatch = watch('status')
    const contractWatch = watch('contract_id')


    useEffect(() => {
        // при изменении сервиса - чистим фильтры  
        setValue('contract_id', [])
        setValue('status', [])
    }, [service_id]);



    const handleOpenFilters = () => {
        setIsOpenFilters(true)
        document.body.style.overflowY = 'hidden'
        document.body.style.paddingRight = '6px'
    }
    const handleCloseFilters = async () => {
        // проверем есть ли измененые фильтры. если есть - делаем фетч  

        // UPD: с этой проверкой не срабатывает в первый раз 
        // if (isIterableArray(Object.keys(touchedFields))) {
        handleSubmit(onSubmit)()
        // }
        setIsOpenFilters(false)
        document.body.style.overflowY = 'auto'
        document.body.style.paddingRight = "0px"
    }

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        handleUpdatePage(data)
    }

    const Btn = ({ label, handleDeleteFilter }: { label: string, handleDeleteFilter: any }) => (
        <Button color='white' className='px-[14px] py-[7px]' onClick={handleDeleteFilter}>
            <div className='flex items-center gap-[8px]'>
                <span className='font-normal'>
                    {t(label)}
                </span>
                <CloseDrawerIcon />
            </div>
        </Button>
    )
    const SelectedFiltersRender = ({ isClear, isUpdate }: { isClear?: boolean, isUpdate?: boolean }) => {
        return (
            <div className='flex justify-between items-center'>
                <div className='flex flex-wrap items-center gap-[8px]'>
                    {
                        isIterableArray(contractWatch) &&
                        contractWatch.map((item: any, index) => {
                            const label = legalFilters.find(i => i.id === item)?.name || ''
                            return (
                                <Btn
                                    key={index}
                                    label={label}
                                    handleDeleteFilter={() => {

                                        const val = contractWatch.filter(i => i !== item)
                                        setValue(`contract_id`, val)
                                        if (isUpdate) {
                                            handleUpdatePage({
                                                contract_id: val,
                                                status: statusWatch
                                            })
                                        }
                                    }}
                                />
                            )
                        })
                    }
                    {
                        isIterableArray(statusWatch) &&
                        statusWatch.map((item: any, index) => {
                            const label = statusesFilters.find(i => i.id === item)?.name || ''
                            return (
                                <Btn
                                    key={index}
                                    label={label}
                                    handleDeleteFilter={() => {
                                        const val = statusWatch.filter(i => i !== item)
                                        setValue(`status`, val)
                                        if (isUpdate) {
                                            handleUpdatePage({
                                                contract_id: contractWatch,
                                                status: val
                                            })
                                        }
                                    }}
                                />
                            )
                        })
                    }
                </div>
                {
                    isClear &&
                    (isIterableArray(contractWatch) || isIterableArray(statusWatch)) &&
                    <div className='font-medium text-gray-600 hover:text-gray-500 transition-color easy duration-200 cursor-pointer'
                        onClick={() => {
                            setValue(`contract_id`, [])
                            setValue(`status`, [])
                        }}
                    >
                        {t('filters.clear')}
                    </div>
                }
            </div>
        )
    }

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)

    const placement = isMobile ? 'bottom' : 'left'
    const size = isMobile ? window.innerHeight - MOBILE_DIVIDER_OFFSET : 500

    const classN = isOpenFilters ? 'tab:!left-[12px]' : 'tab:!left-0'
    return (
        <>
            {
                createPortal(
                    <Drawer
                        placement={placement}
                        size={size}
                        open={isOpenFilters}
                        onClose={handleCloseFilters}
                        // ${isOpenFilters ? 'tab:!left-[12px] opacity-1' : 'tab:!left-0 opacity-0 duration-[250ms]'} 

                        className={`p-4 ${isOpenFilters ? 'tab:!left-[12px] opacity-1' : 'tab:!left-0 opacity-0 duration-[250ms]'}  !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto`}
                    >
                        <div className="mb-6 flex items-center justify-between">
                            <Typography variant="lead" className='text-[18px] tab:text-[24px] font-medium' >
                                {t('filters.filters_title')}
                            </Typography>
                            <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={handleCloseFilters}>
                                <CloseDrawerIcon />
                            </IconButton>
                        </div>
                        <div className='grow flex flex-col'>

                            {
                                isFetching ? <div className='h-[150px] flex items-center justify-center'><Spinner /></div>
                                    :
                                    <>
                                        {
                                            (
                                                isIterableArray(Object.keys(statusWatch)) ||
                                                isIterableArray(Object.keys(contractWatch))
                                            ) &&
                                            <div className='hidden tab:block mb-[24px] mt-[24px] min-h-[72px]'>
                                                <SelectedFiltersRender isClear={true} />
                                            </div>
                                        }
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {
                                                legalFilters &&
                                                <AccordionFilter
                                                    control={control}
                                                    items={legalFilters}
                                                    filterName={'contract_id'}
                                                    fieldName={'contract_id'}
                                                    isOpenDefault={!isMobile}
                                                />
                                            }
                                            <AccordionFilter
                                                control={control}
                                                items={statusesFilters}
                                                filterName={'statuses'}
                                                fieldName={'status'}
                                            />
                                        </form>
                                        <Button color='black' className='mt-auto' onClick={handleCloseFilters}>
                                            {t('filters.confirm')}
                                        </Button>
                                    </>
                            }

                        </div>
                    </Drawer>,
                    document.getElementById('root')!
                )
            }
            <div className=' 1sticky top-0 left-[0px] right-[0px] z-10 tab:static bg-gray-50  flex justify-between items-center pt-[24px] pb-[12px]  tab:my-[32px]'>
                <Button
                    onClick={handleOpenFilters}
                    color='white'
                    className=' sticky top-0 left-[20px] right-[20px] z-10 tab:static  flex justify-center items-center gap-[8px] text-center  text-[14px] tab:text-base'
                >
                    <FilterIcon />
                    <span>{t('filters.filters_title')}</span>
                </Button>
                <div className='hidden tab:block'>
                    <SelectedFiltersRender isUpdate={true} />
                </div>
            </div>
        </>
    )
}

export default Filters