import { Button, Dialog, DialogBody, Typography } from '@material-tailwind/react';
import React, { ReactPortal, useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { baseApi } from '../../ConfigApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { errorToast, successToast } from '../../components/toasts/toasts';
import AvatarChanger from './AvatarChanger';

const ChangeAvatarModal = ({ open, handleConfirm, handleClose, modalValue }: {
    open: boolean,
    handleConfirm: any,
    handleClose: () => void,
    modalValue?: any
}): ReactPortal | null => {

    const { t } = useTranslation()

    const { token } = useSelector((state: RootState) => state.auth)

    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const [fileForSend, setFileForSend] = useState<any>(null);

    const saveAvatar = () => {
        setIsLoadingSave(true)

        const newFile = dataURLtoFile(fileForSend, 'avatar')

        axios.post(`${baseApi}/api/cabinet/profile-updateavatar`, {
            file: newFile
        }, {
            headers: {
                'Authorization': token,
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response: any) => {
                if (response.data.res === 'Error') {
                    errorToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.error_on_upload_avatar')
                    })
                }
                else {
                    successToast({
                        title: t('profile.profile_avatar'),
                        text: t('profile.success_upload_avatar')
                    })
                    handleClose()
                    handleConfirm()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('profile.profile_avatar'),
                    text: t('profile.error_on_upload_avatar')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }

    useEffect(() => {
        if (!open) {
            setFileForSend(null)
        }
    }, [open])

    function dataURLtoFile(dataurl: string, filename: string) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)?.[1]
        let bstr = atob(arr[arr.length - 1])
        let n = bstr.length
        let u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });

    }


    return (
        createPortal(
            <>
                <Dialog
                    handler={handleClose} open={open} className='!transition-all !animate-none !min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0 !duration-100 ">
                        <div className="flex flex-col gap-[32px]">
                            <div className="flex items-center justify-between ">
                                <Typography variant="lead" className="text-[20px] tab:text-[24px] text-gray-800 font-medium ">
                                    {t('profile.new_avatar')}
                                </Typography>
                                <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                    <CloseDrawerIcon />
                                </div>
                            </div>
                            <div className='self-center'>
                                <AvatarChanger
                                    setFileForSend={setFileForSend}
                                />
                            </div>
                            <div className="flex item-center justify-between">
                                <Button color="white" onClick={handleClose}>
                                    {t('cancel')}
                                </Button>
                                <Button
                                    color="black"
                                    onClick={saveAvatar}
                                    loading={isLoadingSave}
                                    disabled={isLoadingSave || !fileForSend}
                                >
                                    {t('confirm')}
                                </Button>
                            </div>
                        </div>
                    </DialogBody>
                </Dialog >
            </>,
            document.getElementById('root')!
        )
    )
}

export default ChangeAvatarModal