
import DoughnutLabel from "chartjs-plugin-doughnutlabel-v3";
import { Doughnut } from 'react-chartjs-2'
import {
    Chart,
    Legend,
    DoughnutController,
    registerables
} from "chart.js";
import { useTranslation } from 'react-i18next';
import { isIterableArray, toFormatPrice } from '../../../utils/utils';
import moment from "moment";
import { useEffect, useState } from "react";
import useIsMobile from "../../../utils/useIsMobile";
import { GRAPH_PRICE_MONTH, GRAPH_TARIF_MONTH } from "./ViewByMonth";
import { pluginBg } from "../viewSaldo/GraphSaldo";
Chart.register(
    ...registerables,
    DoughnutController,
    DoughnutLabel,
    Legend
);


// console.log(DoughnutLabel)
export const dougColors = [
    '#F87171',
    '#FCA5A5',
    '#FFC8C8',
    '#FEE2E2',
    '#FFF0F0',
    '#FFF2EC',
    '#FFDFD3',
    '#FFD0BE',
    '#FFAA8B',
    '#FF8E65',
    '#FF7340',
    '#F25219',
]
const defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
const pieDoughnutLegendClickHandler = DoughnutController.overrides.plugins.legend.onClick;


export const getDoughnutTotal = function (myDoughnutChart, fixedCount, text, forRemove) {
    const arr = myDoughnutChart.config.data.datasets[0].data
    const sum = arr.reduce(
        (acc, item, index) => {
            const isR = isIterableArray(forRemove) ? forRemove.findIndex(i => i.index === index) : 0
            if (isR <= 0) {
                acc = acc + item
                return acc
            }
            else return acc
            //  a + b
        }, 0
    );
    return `${toFormatPrice(sum, fixedCount)} ${text ? text : ''}`;
};
const DoughnutGraph = ({ currentData, month, year }) => {

    const isMobile = useIsMobile(1450)

    const { t } = useTranslation()
    const [visibleDataIndexPrice, setVisibleDataIndexPrice] = useState([]);
    const [visibleDataIndexTotal, setVisibleDataIndexTotal] = useState([]);


    useEffect(() => {
        if (!isIterableArray(visibleDataIndexPrice)) {
            const init = currentData.values1.map((value, index) => ({
                index, value, hidden: true,
            }))
            setVisibleDataIndexPrice(init)
        }
    }, [currentData.values1, visibleDataIndexPrice]);

    useEffect(() => {
        if (!isIterableArray(visibleDataIndexTotal)) {
            const init = currentData.values2.map((value, index) => ({
                index, value, hidden: true,
            }))
            setVisibleDataIndexTotal(init)
        }
    }, [currentData.values2, visibleDataIndexTotal]);


    const newLegendClickHandler = function (e, legendItem, legend, visibleDataIndex, setVisibleDataIndex) {
        const sliced = visibleDataIndex.slice()
        sliced[legendItem.index].hidden = legendItem.hidden
        setVisibleDataIndex(sliced)

        pieDoughnutLegendClickHandler(e, legendItem, legend)
    };
    const getTotal = function (arr, fixedCount, text) {
        const sum = arr.reduce(
            (acc, item, index) => {
                if (item.hidden) {
                    acc = acc + item.value
                    return acc
                }
                else return acc
                //  a + b
            }, 0
        );
        return `${toFormatPrice(sum, fixedCount)} ${text ? text : ''}`;
    };
    const options = {
        maintainAspectRatio: false,
        // cutoutPercentage: 75, 
        plugins: {
            tooltip: {
                // enabled: false,
                // external: function (context) {
                //     return externalTooltip(context, t('mv'))
                // }
                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 12,
                borderColor: '#EDEDED',
                borderWidth: 2,
                // displayColors: false,
                padding: 16,
                titleFont: {
                    size: 18
                },
                bodyFont: {
                    size: 18
                },

                callbacks: {
                    title: function (tooltipItems) {
                        return tooltipItems[0].label
                    },
                    // label: function (context) {
                    //     let label = context.parsed.y !== 0 ? context.dataset.label : '';
                    //     if (context.parsed.y !== 0) {
                    //         if (label) {
                    //             label += ': ';
                    //         }
                    //         label += toFormatPrice(context.parsed.y, 3);
                    //     }
                    //     return label;
                    // },
                    labelColor: function (context) {
                        return {
                            backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                            border: 0,
                            boxWidth: 10,
                            boxHeight: 10,
                            pointStyle: 'circle',
                            useBorderRadius: true,
                            borderRadius: 5,
                        }
                    }
                }
            },
            legend: {
                // display: !isMobile,
                position: 'bottom',
                align: 'center',
                labels: {
                    boxWidth: 8,
                    boxHeight: 8,
                    usePointStyle: true,
                    pointStyle: 'circle',
                    useBorderRadius: true,
                    borderRadius: 4,

                },
            },
            datalabels: {
                formatter: function (value, context) {
                    return `${toFormatPrice(value)}`;
                },
                color: function (context) {
                    return '#444248';
                },
                font: {
                    weight: 500,
                    size: isMobile ? 9 : 12,
                    family: 'Inter'
                }
            },
        }
    }

    const dataPrice = {
        labels: currentData.labels1.map(label => t(`analytics.pie_${label}`)),
        datasets: [
            {
                data: currentData.values1,
                backgroundColor: dougColors,
                borderWidth: 0,
            },
        ],
        doughnutlabel: {
            display: true,
        }
    };
    const dataTotal = {
        labels: currentData.labels2.map(label => t(`analytics.pie_${label}`)),
        datasets: [
            {
                data: currentData.values2,
                backgroundColor: dougColors,
                borderWidth: 0,
                callbacks: {
                    // label: (context) => {
                    //     console.log(context)
                    //     return 'lab: '
                    // },
                }
            },
        ],
    };

    return (
        <div className='flex flex-col tab:flex-row lap:flex-col items-center gap-[30px]'>
            {/* <div className={classCharContainerHeight}> */}
            <div className={` h-[300px] mx-auto`}>
                <Doughnut
                    id={GRAPH_TARIF_MONTH}
                    className="w-full"
                    updateMode='resize'
                    data={dataPrice}
                    options={{
                        plugins: {
                            ...options.plugins, ...{
                                legend: {
                                    ...options.plugins.legend,
                                    onClick: (e, legendItem, legend) => newLegendClickHandler(e, legendItem, legend, visibleDataIndexPrice, setVisibleDataIndexPrice),
                                },
                                doughnutLabel: {
                                    spacing: 15,
                                    labels: [
                                        {
                                            text: t('analytics.dataPriceTitle'),
                                            font: {
                                                size: isMobile ? "9" : '11',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 2
                                            },
                                            color: "#767579",
                                        },
                                        {
                                            text: (e) => getTotal(visibleDataIndexPrice, 2),
                                            font: {
                                                size: isMobile ? "11" : '14',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 1.2
                                            },
                                            color: "#2D2B32",
                                        },
                                        {
                                            text: t('grnMvtGod'),
                                            font: {
                                                size: isMobile ? "11" : '14',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 1.2
                                            },
                                            color: "#2D2B32",
                                        },
                                        {
                                            text: moment(`${month}-${year}`, 'MM-YYYY').format('MMMM YYYY'),
                                            font: {
                                                size: isMobile ? "9" : '12',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 2
                                            },
                                            color: "#767579"
                                        },
                                    ]
                                }
                            }
                        }
                    }}
                    // plugins={[pluginBg]}
                />
            </div>
            {/* <div className={classCharContainerHeight}> */}
            <div className={` h-[300px] mx-auto`}>
                <Doughnut
                    id={GRAPH_PRICE_MONTH}
                    className="w-full"
                    updateMode='resize'
                    data={dataTotal}
                    options={{
                        plugins: {
                            ...options.plugins, ...{
                                legend: {
                                    ...options.plugins.legend,
                                    onClick: (e, legendItem, legend) => newLegendClickHandler(e, legendItem, legend, visibleDataIndexTotal, setVisibleDataIndexTotal),
                                },
                                doughnutLabel: {
                                    labels: [
                                        {
                                            text: t('analytics.dataTotalTitle'),
                                            font: {
                                                size: isMobile ? "9" : '12',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 2,
                                            },
                                            color: "#767579"
                                        },
                                        {
                                            text: (e) => getTotal(visibleDataIndexTotal, 2),
                                            // text: (e) => getDoughnutTotal(e, 2, t('grn'), []),
                                            font: {
                                                size: isMobile ? "11" : '14',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 1.2,
                                            },
                                            color: "#2D2B32"
                                        },
                                        {
                                            text: moment(`${month}-${year}`, 'MM-YYYY').format('MMMM YYYY'),
                                            font: {
                                                size: isMobile ? "9" : '12',
                                                family: "Inter",
                                                weight: "400",
                                                lineHeight: 2,
                                            },
                                            color: "#767579"
                                        },
                                    ]
                                }
                            }
                        }
                    }}
                // plugins={[pluginBg]}
                />
            </div>
        </div>
    )
}

export default DoughnutGraph