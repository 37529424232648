import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar-edit'
import { useTranslation } from 'react-i18next';
import { errorToast } from '../../components/toasts/toasts';
import useIsMobile from '../../utils/useIsMobile';
import { FILTER_SCREEN_MOBILE } from '../../utils/variables';


const MAX_FILE_SIZE = 15 * 1024 * 1024 // 15 MB

const AvatarChanger = ({
    setFileForSend
}: {
    setFileForSend: any,
}) => {

    const { t } = useTranslation()

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)

    const [src, setSrc] = useState('');
    const [preview, setPreview] = useState('');
    const onClose = () => {
        setPreview('')
    }

    useEffect(() => {
        return () => {
            setSrc('')
            setPreview('')
        };
    }, []);

    const onCrop = (view: any) => {
        setFileForSend(view)
        setPreview(view)
    }

    const onBeforeFileLoad = (elem: any) => {
        if (elem.target.files[0].size > MAX_FILE_SIZE) {
            errorToast({
                title: t('profile.error_avatar_large_size')
            });
            elem.target.value = "";
        };
    }


    return (
        <div className='flex flex-col items-center gap-[24px]'>
            {
                <Avatar
                    width={isMobile ? 200 : 300}
                    height={isMobile ? 200 : 300}
                    onCrop={onCrop}
                    onClose={onClose}
                    onBeforeFileLoad={onBeforeFileLoad}
                    src={src}
                    label={t('profile.choise_avatar')}
                    labelStyle={{
                        display: 'block',
                        lineHeight: 1.5,
                        color: '#2d2b32',
                        fontWeight: 500,
                        cursor: 'pointer'
                    }}
                />
            }
            {
                preview &&
                <img src={preview} alt="Preview" className='border-2 border-gray-200 rounded-full w-[100px] h-[100px]' />
            }
        </div>
    )
}

export default AvatarChanger 