
import Chart, { CategoryScale } from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { toFormatPrice, toStringPrice } from "../../../utils/utils";
import { useState } from "react";
import { classCharContainerHeight } from "../../analytics/AnalyticsPage";

Chart.register(CategoryScale);
// Chart.register({
//   id: 'paddingBelowLegends',
//   beforeInit: function (chart: any, options) {
//     chart.legend.afterFit = function () {
//       this.height = this.height + 50;
//     };
//   }
// });
export const legendMargin = {
  id: "legendMargin",
  beforeInit: function (chart: any) {
    const fitValue = chart.legend.fit;
    chart.legend.fit = function fit() {
      fitValue.bind(chart.legend)();
      return (this.height += 40);
    };
  }
};

const MonthGraph = ({ tooltipDate, data_declared, data_cons }: any) => {

  const { t } = useTranslation()

  // const labels = Array.from(Array(data_declared?.length).keys())
  const labels = Array.from(Array(data_declared?.length).keys()).map(item => ++item)
  // const labels = Array.from(Array(data_declared?.length).keys()).map(item => (item % 2 !== 0 ? item : ''))
  const dataGraph = {
    labels: labels,
    datasets: [
      {
        datalabels: {
          display: false,
        },
        label: t('factual.line_consumption_title'),
        data: data_cons,
        borderColor: '#FF7340', // orange-500
        borderWidth: 2,
        backgroundColor: '#FF7340',
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 5
      },
      {
        datalabels: {
          display: false,
        },
        label: t('factual.line_declared_title'),
        data: data_declared,
        borderColor: '#bfbfc1', // gray-400
        borderWidth: 2,
        backgroundColor: '#bfbfc1',
        pointStyle: 'circle',
        pointRadius: 3,
        pointHoverRadius: 5

      }
    ]
  }
  const [lineWidth, setlineWidth] = useState(26)

  // const paddingBelowLegends = {
  //   id: 'paddingBelowLegends',
  //   beforeInit: function (chart: any) {
  //     chart.legend.afterFit = function () {
  //       console.log(chart)
  //       this.height = this.height + 50;
  //     };
  //   }
  // }
  // const footer = (tooltipItems: any) => {
  //   let sum = 0;


  //   return tooltipItems.forEach(function (tooltipItem: any) {
  //     return tooltipItem.parsed.y
  //   })

  // }
  const options: any = {
    interaction: {
      mode: 'x',
      includeInvisible: true,
      intersect: false,
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: 0,
      autoPadding: false,
      // background: '#000'
    },
    plugins: {
      // beforeInit: function(chart: any) {
      //   chart.legend.afterFit = function() {
      //     console.log(chart)
      //     this.height = this.height + 50;
      //   };
      // },
      chartArea: {
        top: 200
      },
      legend: {
        position: 'top',
        reverse: true,
        align: 'start',
        labels: {
          usePointStyle: true,
          boxWidth: 6,
          boxHeight: 6,
          font: {
            size: 14
          },
        },
        title: {
          display: false,
        },
        // display: false
      },
      tooltip: {
        xAlign: 'center',
        yAlign: 'bottom',
        backgroundColor: '#fff',
        bodyColor: '#444248',
        titleColor: '#444248',
        titleSpacing: 8,
        caretSize: 0,
        cornerRadius: 4,
        borderColor: '#EDEDED',
        borderWidth: 2,
        displayColors: false,
        padding: 12,
        textDirection: 'top',
        // label: {
        //   formattedValue: 'string'
        // },
        callbacks: {
          title: function (tooltipItems: any) {
            return tooltipDate ? (tooltipItems[0].label + ' ' + tooltipDate) : ''
          },
          label: function (context: any) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += toFormatPrice(context.parsed.y);
            }
            return label;
          }
        }
      },
    },
    scales: {
      x: {
        offset: true,
        grid: {
          // drawBorder: false,
          // display: false,
          // color: "#f6f6f6",
          color: "#f6f6f6",
          // lineWidth: 2,
          lineWidth: lineWidth,
          // lineWidth: lineWidth,
        },
        stacked: true,
        border: {
          // display: false
          width: 0,
          color: '#e4e4e4',
        },
        ticks: {
          maxRotation: 0,
          // callback: (value: any, index: any, arr: any) => {
          //   console.log(value, index)
          //   return index === arr.length - 1 ? t('days') : ++value
          // }
        },

      },
      y: {
        offset: true,
        min: 0,
        ticks: {
          callback: (value: any, index: any, arr: any) => {
            return index === arr.length - 1 ? t('kvHour') : toStringPrice(value)
          }
        },
        grid: {
          display: false,
          color: "#f6f6f6",
          lineWidth: 1
        },
        border: {
          width: 0,
          color: '#fff',
          // display: false
        }
      },
    },
  };

  return (
    <div className={classCharContainerHeight}>
      <Line
        updateMode='resize'
        data={dataGraph}
        options={options}
        plugins={[legendMargin]}
      />
    </div>
  )
}

export default MonthGraph
