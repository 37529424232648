import React from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from "react-i18next";
import { isIterableArray, toFormatPrice } from "../../../utils/utils";
import { externalTooltip, pluginBg } from "../viewSaldo/GraphSaldo";


Chart.register(CategoryScale);
Chart.register(
    ChartDataLabels,
    CategoryScale,
    BarController,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const GraphConsumption = (props) => {

    const { t } = useTranslation()

    const { datachar, handleClickGraph, titleTooltipFormatter, isMobile, plugins = [], graphId, fixedCount = 3, isSummTooltip = false } = props

    const options = {
        onClick: function (_, element) {
            const index = element?.[0]?.index
            if (typeof index === 'number' && handleClickGraph) handleClickGraph(index, element)
        },
        // animation: false,
        // transitions: false,
        // transitions: {
        //     colors: false,
        //     show: false,
        //     hide: false
        // },
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    boxWidth: 10,
                    boxHeight: 10,
                    pointStyle: 'circle',
                    useBorderRadius: true,
                    borderRadius: 5,
                }
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            tooltip: {
                // enabled: false, 
                // external: function (context) {
                //     return externalTooltip(context, 'mv')
                //     // return externalTooltip(context, t('mv'))
                // },
                position: 'nearest',
                mode: 'index',

                intersect: false,
                mode: 'index',
                xAlign: 'center',
                yAlign: 'bottom',

                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                footerColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 12,
                borderColor: '#EDEDED',
                borderWidth: 2,
                // displayColors: false,
                padding: 16,
                textDirection: 'top',
                titleFont: {
                    size: 18
                },
                bodyFont: {
                    size: 18
                },
                footerFont: {
                    size: 18
                },

                callbacks: {
                    title: function (tooltipItems, context) {
                        const label = titleTooltipFormatter ? titleTooltipFormatter(tooltipItems[0].label) : tooltipItems[0].label
                        const isDataInCol = isIterableArray(tooltipItems) ? tooltipItems.reduce((acc, item) => {
                            acc += item.parsed.y
                            return acc
                        }, 0) : false
                        if (!isDataInCol) return t('analytics.no_data_for') + label
                        return label
                    },
                    label: function (context) {
                        let label = context.parsed.y !== 0 ? context.dataset.label : '';
                        if (context.parsed.y !== 0) {
                            if (label) {
                                label += ': ';
                            }
                            label += toFormatPrice(context.parsed.y, fixedCount);
                        }
                        return label;
                    },
                    footer: isSummTooltip ? function (tooltipItems, context) {
                        const isDataInCol = isIterableArray(tooltipItems) ? tooltipItems.reduce((acc, item) => {
                            acc += item.parsed.y
                            return acc
                        }, 0) : false
                        return isDataInCol ? `    ${t('analytics.total')} ${toFormatPrice(isDataInCol, fixedCount)}` : ''
                    } : () => (''),
                    labelColor: function (context) {
                        return {
                            backgroundColor: context.dataset.backgroundColor,
                            border: 0,
                            boxWidth: 10,
                            boxHeight: 10,
                            pointStyle: 'circle',
                            useBorderRadius: true,
                            borderRadius: 8,
                        }
                    }
                }
            },
            datalabels: {
                display: !isMobile,
                align: 'center',
                // rotation: '-90',
                anchor: 'top',
                formatter: function (value) {
                    return `${toFormatPrice(value, fixedCount)}`;
                },
                // color: 'red',
                font: {
                    weight: 500,
                    size: 10,
                    family: 'Inter'
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                display: true,
                beginAtZero: true,
                offset: true,
                stacked: true,
                border: {
                    display: false,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    beginAtZero: true,
                    maxRotation: 0
                }
            },
            y: {
                display: false,
                offset: true,
                stacked: true,
                // grid: {
                //     drawBorder: false,
                //     display: false,
                // },
                // ticks: {
                //     mirror: true
                // }
            },
        },
    };

    return (
        <Bar
            id={graphId}
            options={options}
            data={datachar}
            plugins={[...plugins, pluginBg]}
        />
    )
}

export default GraphConsumption