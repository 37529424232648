import { Spinner, Typography } from '@material-tailwind/react';
import moment from 'moment';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useViewComparisonQuery } from '../../../store/auth/authService';
import FilterComparison from './FilterComparison';
import TableLayout from '../../../layouts/pagesLayouts/TableLayout';
import TabController from './TabController';
import { classCharContainerHeight } from '../AnalyticsPage';
import { saveGraphToPicture } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

export type ComparisonFormType = {
    contract_id?: number,
    period1: string,
    period2: string,

}

export const TAB_0_GRAPH_1_ID = "js-tab-0-period1-graph"
export const TAB_0_GRAPH_2_ID = "js-tab-0-period2-graph"
export const TAB_1_GRAPH_1_ID = "js-tab-1-period1-graph"
export const TAB_1_GRAPH_2_ID = "js-tab-1-period2-graph"
export const TAB_2_GRAPH_1_ID = "js-tab-2-period1-graph"
export const TAB_2_GRAPH_2_ID = "js-tab-2-period2-graph"

const ViewComparison = ({ isMobile }: { isMobile: boolean }) => {

    const { t } = useTranslation()

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)

    // ключи из рута consumption-comparison/view
    const tabs = ['consumption', 'dataPrice', 'dataTotal']

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const defaulPeriod1 = moment().add(-3, 'month').format('MM-YYYY')
    const defaulPeriod2 = moment().add(-2, 'month').format('MM-YYYY')


    const form: ComparisonFormType = {
        contract_id: Number(param_contract_id) || 0,
        period1: defaulPeriod1,
        period2: defaulPeriod2
    }


    const [sendForm, setSendForm] = useState<ComparisonFormType>(form);

    // sendForm
    const { currentData, error, isFetching } = useViewComparisonQuery<any>(sendForm)

    const setFormForFetch = (values: any) => {
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }


    return (
        <div>
            <Typography variant='lead' className='text-gray-800 text-left tab:text-center text-[24px] lap:text-[32px] font-medium leading-[1.2]'>
                {t('analytics.analytics_comparison_title')}
            </Typography>
            <FilterComparison
                value={{
                    period1: sendForm.period1,
                    period2: sendForm.period2,
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                savePictures={() => {
                    if (selectedTab === tabs[0]) {
                        saveGraphToPicture(TAB_0_GRAPH_1_ID, `${t('or')} ${or_number} - ${t('analytics.consumption')} ${t('by_period')} ${sendForm.period1}.png`)
                        saveGraphToPicture(TAB_0_GRAPH_2_ID, `${t('or')} ${or_number} - ${t('analytics.consumption')} ${t('by_period')} ${sendForm.period2}.png`)
                    }
                    if (selectedTab === tabs[1]) {
                        saveGraphToPicture(TAB_1_GRAPH_1_ID, `${t('or')} ${or_number} - ${t('analytics.dataPrice')} ${t('by_period')} ${sendForm.period1}.png`)
                        saveGraphToPicture(TAB_1_GRAPH_2_ID, `${t('or')} ${or_number} - ${t('analytics.dataPrice')} ${t('by_period')} ${sendForm.period2}.png`)
                    }
                    if (selectedTab === tabs[2]) {
                        saveGraphToPicture(TAB_2_GRAPH_1_ID, `${t('or')} ${or_number} - ${t('analytics.dataTotal')} ${t('by_period')} ${sendForm.period1}.png`)
                        saveGraphToPicture(TAB_2_GRAPH_2_ID, `${t('or')} ${or_number} - ${t('analytics.dataTotal')} ${t('by_period')} ${sendForm.period2}.png`)
                    }
                }}
                // disabledSave={
                //     // selectedTab === tabs[0] ?
                //     //     (currentData?.period1[tabs[0]]?.declared === 0 && currentData?.period1[tabs[0]]?.fact === 0)
                //     //     :
                //     //     selectedTab === tabs[1] ?
                //     //         currentData?.period1?.[tabs[1]]?.values.length === 0 :
                //     //         selectedTab === tabs[2] ?
                //     //             currentData?.period1?.[tabs[2]]?.values.length === 0 : false
                // }
            />
            {
                currentData?.formatted ?
                    <div className='bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                        {/* <div className={classComparisonContainerHeight}> */}
                        <TabController
                            currentData={currentData?.formatted}
                            period1={sendForm.period1}
                            period2={sendForm.period2}
                            isMobile={isMobile}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            tabs={tabs}
                        />
                        {/* </div> */}
                    </div>
                    :
                    isFetching ?
                        <div className={`${classCharContainerHeight} bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                {t('analytics.month_detailing_no_data')}
                            </Typography>
            }
        </div>
    )
}

export default ViewComparison