import { Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import ItemLayout from '../ItemLayout'

const SummaryConsumpiton = ({ data, isFetching }: any) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const serviceTitleText = service_id === 1 ? 'text-orange-500' : service_id === 2 ? 'text-blue-600' : 'text-gray-100'


    return (
        <ItemLayout isSummary tkoData={{
            data: data
        }} isFetching={isFetching}>
            <>
                {/* <Typography variant='lead' className='text-gray-700 font-[400] mb-[8px] text-[14px] lap:text-[16px] '>
                    {t('consumption.tko_text_title')}
                </Typography> */}
                <Typography variant='lead' className={`${serviceTitleText} font-bold text-[20px] tab:text-[24px] lap:text-[32px]`}>
                    {t('consumption.all_tko_text_title')}
                </Typography>
            </>
        </ItemLayout>
    )
}

export default SummaryConsumpiton