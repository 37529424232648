import { Button, Dialog, DialogBody, Input, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { sendRefresh, sendRefreshFinish } from '../../store/auth/authActions';
import { AppDispatch } from '../../store/store';
import { createPortal } from 'react-dom';
import CloseDrawerIcon from '../../images/icons/CloseDrawerIcon';
import { errorToast, successToast } from '../toasts/toasts';


const ForgotForm = (props: {
    open: boolean,
    handleClose: any
}) => {

    const { open, handleClose } = props
    const { t } = useTranslation()
    const navigate = useNavigate()

    const dispatch = useDispatch<AppDispatch>()

    const [isCodeEnterMode, setIsCodeEnterMode] = useState<boolean>(false);
    const [isLoadingSendCode, setIsLoadingSendCode] = useState<boolean>(false);

    const [emailValue, setEmailValue] = useState('');
    const [codeValue, setCodeValue] = useState('');

    const handleSendCode = () => {
        setIsLoadingSendCode(true)
        dispatch(sendRefresh({
            email: emailValue,
            lang: 'ua'
        }))
            .then((res) => {
                setIsLoadingSendCode(false)
                if (res?.payload?.res === 'Success') setIsCodeEnterMode(true)
                else {
                    errorToast({ title: t('auth.error_on_send_code') })
                }
            });
    }

    const handleConfirmCode = () => {
        setIsLoadingSendCode(true)
        dispatch(sendRefreshFinish({
            code: codeValue,
            lang: 'ua'
        }))
            .then((res) => {
                setIsLoadingSendCode(false)
                if (res?.payload?.res === 'Success') {
                    successToast({ title: t('auth.success_on_code_confirm') })
                    setIsCodeEnterMode(false)
                    setEmailValue('')
                    setCodeValue('')
                    handleClose()
                }
                else {
                    errorToast({ title: t('auth.error_on_code_confirm') })
                }
            });
    }
    return (
        createPortal(
            <>
                <Dialog open={open} handler={handleClose} className='!min-w-0 !max-w-[640px] bg-white p-[24px] tab:p-[32px] rounded-[16px] '>
                    <DialogBody className="p-0">
                        <div className="flex items-center justify-between mb-[32px]">
                            <Typography variant="lead" className="text-[18px] lap:text-[24px] text-gray-800 font-medium ">
                                {t('auth.forgot_text')}
                            </Typography>
                            <div onClick={handleClose} className="rounded-full w-[32px] h-[32px] transition-all flex items-center justify-center active:bg-gray-200 hover:bg-gray-100 cursor-pointer">
                                <CloseDrawerIcon />
                            </div>
                        </div>
                        <div className='w-full'>
                            {
                                isCodeEnterMode ?
                                    // ввод код подтверждения
                                    <>
                                        <Typography variant="lead" className="text-[14px] leading-[1.2] lap:text-[20px] text-gray-800 my-[32px] ">
                                            {t('auth.forgot_text_paragraph2')}
                                        </Typography>
                                        <div className='mb-[30px]'>
                                            <Input
                                                value={codeValue}
                                                onChange={(e) => setCodeValue(e.target.value)}
                                                variant='static'
                                                type={'text'}
                                                placeholder={t('auth.input_code_placeholder')}
                                            />
                                        </div>

                                        <Button
                                            loading={isLoadingSendCode}
                                            color='black'
                                            className='font-normal rounded-full text-[18px] w-full py-[20px] flex justify-center'
                                            onClick={handleConfirmCode}
                                        >
                                            {t('auth.send_code_btn')}
                                        </Button>
                                        <Button
                                            color='white'
                                            className='whitespace-nowrap text-[14px] font-normal rounded-full border-2 text-[18px] w-full mt-[16px]'
                                            onClick={() => {
                                                setIsCodeEnterMode(false)
                                            }}
                                        >
                                            {t('back')}
                                        </Button>
                                    </>
                                    :
                                    // ввод почты
                                    <>
                                        <Typography variant="lead" className="text-[14px] leading-[1.2] lap:text-[20px] text-gray-800 my-[32px] ">
                                            {t('auth.forgot_text_paragraph')}
                                        </Typography>
                                        <div className='mb-[30px]'>
                                            <Input
                                                value={emailValue}
                                                onChange={(e) => setEmailValue(e.target.value)}
                                                variant='static'
                                                type={'email'}
                                                placeholder={t('auth.input_email_placeholder')}
                                                className='grow'
                                            />
                                        </div>
                                        <Button
                                            loading={isLoadingSendCode}
                                            color='black'
                                            className='whitespace-nowrap text-[14px] font-normal rounded-full text-[18px] w-full mt-[16px] flex justify-center'
                                            onClick={handleSendCode}
                                        >
                                            {t('auth.send_code_btn1')}
                                        </Button>
                                        <Button
                                            color='white'
                                            className='whitespace-nowrap text-[14px] font-normal rounded-full border-2 text-[18px] w-full mt-[16px]'
                                            onClick={handleClose}
                                        >
                                            {t('back')}
                                        </Button>
                                    </>
                            }

                        </div>
                    </DialogBody>
                </Dialog>
            </>,
            document.getElementById('root')!
        )
    )
}

export default ForgotForm
