import { useState } from 'react'
import DoughnutGraphPrice from './DoughnutGraphPrice'
import DoughnutGraphTotal from './DoughnutGraphTotal'
import { useTranslation } from 'react-i18next';
import PieGraph from './PieGraph';
import { classCharDoughnutContainerHeight } from '../AnalyticsPage';
import { TAB_0_GRAPH_1_ID, TAB_0_GRAPH_2_ID, TAB_1_GRAPH_1_ID, TAB_1_GRAPH_2_ID, TAB_2_GRAPH_1_ID, TAB_2_GRAPH_2_ID } from './ViewComparison';

const TabController = ({
    currentData,
    period1,
    period2,
    isMobile,
    selectedTab,
    setSelectedTab,
    tabs
}: any) => {


    const { t } = useTranslation()



    return (
        <div className='flex flex-col lap:flex-row justify-center items-center gap-[24px] lap:gap-[32px] des:gap-[75px]'>
            <div className={classCharDoughnutContainerHeight}>
                {
                    selectedTab === tabs[0] &&
                    <PieGraph
                        graphId={TAB_0_GRAPH_1_ID}
                        isMobile={isMobile}
                        currentData={currentData?.period1[selectedTab]}
                        isEmpty={currentData?.period1[selectedTab].declared === 0 && currentData?.period1[selectedTab].fact === 0}
                    />
                }
                {
                    selectedTab === tabs[1] &&
                    <DoughnutGraphPrice
                        graphId={TAB_1_GRAPH_1_ID}
                        isMobile={isMobile}
                        period={period1}
                        currentData={currentData?.period1[selectedTab]}
                        isEmpty={currentData?.period1[selectedTab].values.length === 0}
                    />
                }
                {

                    selectedTab === tabs[2] &&
                    <DoughnutGraphTotal
                        graphId={TAB_2_GRAPH_1_ID}
                        isMobile={isMobile}
                        period={period1}
                        currentData={currentData?.period1[selectedTab]}
                        isEmpty={currentData?.period1[selectedTab].values.length === 0}
                    />
                }
            </div>
            <div className="flex flex-col mob:flex-row lap:flex-col items-center gap-[8px]">
                {
                    tabs.map((tabName: any) => (
                        <div
                            key={tabName}
                            onClick={() => setSelectedTab(tabName)}
                            className={`px-[16px] py-[8px] text-[14px] font-medium rounded-full text-center
                                            ${tabName === selectedTab ? 'text-white bg-gray-700' : 'text-gray-700 bg-white cursor-pointer hover:bg-gray-200 transition-all'}    
                                            `}
                        >
                            {t(`analytics.${tabName}`)}
                        </div>
                    ))
                }
            </div>
            <div className={classCharDoughnutContainerHeight}>
                {
                    selectedTab === tabs[0] &&
                    <PieGraph
                        graphId={TAB_0_GRAPH_2_ID}
                        isMobile={isMobile}
                        currentData={currentData?.period2[selectedTab]}
                        isEmpty={currentData?.period2[selectedTab].declared === 0 && currentData?.period2[selectedTab].fact === 0}
                    />
                }
                {
                    selectedTab === tabs[1] &&
                    <DoughnutGraphPrice
                        graphId={TAB_1_GRAPH_2_ID}
                        isMobile={isMobile}
                        period={period2}
                        currentData={currentData?.period2[selectedTab]}
                        isEmpty={currentData?.period2[selectedTab].values.length === 0}
                    />
                }
                {

                    selectedTab === tabs[2] &&
                    <DoughnutGraphTotal
                        graphId={TAB_2_GRAPH_2_ID}
                        isMobile={isMobile}
                        period={period2}
                        currentData={currentData?.period2[selectedTab]}
                        isEmpty={currentData?.period2[selectedTab].values.length === 0}
                    />
                }
            </div>
        </div>
    )
}

export default TabController