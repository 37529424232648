import { Button, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewFactualGasAllQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch, RootState } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import AmountTitle from '../views/AmountTitle'
import GasDaysGraphs from './GasDaysGraphs'
import GasMonthGraph from './GasMonthGraph'
import MobileAmountTitle from '../views/MobileAmountTitle'
import { errorToast } from '../../../components/toasts/toasts';
import axios from 'axios';
import { baseApi } from '../../../ConfigApi';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';

const ViewFactualGasAll = () => {
    const { t } = useTranslation()

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch<AppDispatch>()

    let [searchParams, setSearchParams] = useSearchParams();

    const fact_param = searchParams.get('arr_fact')
    const gas_param = searchParams.get('arr_gas')
    const power_param = searchParams.get('arr_power')

    const factParamArr = fact_param ? fact_param.split(',').map(i => parseInt(i)) : []
    const gasParamArr = gas_param ? gas_param.split(',').map(i => parseInt(i)) : []
    const powerParamArr = power_param ? power_param.split(',').map(i => parseInt(i)) : []


    const { param_contract_id, param_tko_id } = useParams<{
        param_contract_id: string;
        param_tko_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)

    //  isChecked - true = type - 1 - по дням
    //  isChecked - false = type - 2 - за месяц
    const [isChecked, setIsChecked] = useState(true);

    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const { data, isLoading } = useViewFactualGasAllQuery({
        contract_id: Number(param_contract_id),
        id: Number(param_tko_id),
        arr_gas: isIterableArray(gasParamArr) ? gasParamArr : [],
        arr_power: isIterableArray(powerParamArr) ? powerParamArr : [],
        arr_fact: isIterableArray(factParamArr) ? factParamArr : [],
    })


    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);

    useEffect(() => {
        if (data?.service_id) dispatch(setServiceId(Number(data.service_id)))
    }, [data]);

    const handleNavigateBack = () => {
        if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
        else navigate(`/consumptions/${param_contract_id}`)
    }

    const handleDownloadConsExcel = () => {
        if (isIterableArray(factParamArr) || isIterableArray(gasParamArr) || isIterableArray(powerParamArr)) {

            setIsLoadingSave(true)

            const formSend = {
                contract_id: Number(param_contract_id),
                arr_fact: factParamArr,
                arr_gas: gasParamArr,
                arr_power: powerParamArr
            }
            const filename = `${t('or')} ${or_number} - ${t('factual.view_factual_gas_title')} ${t('by_period')} ${data?.month || ''}-${data?.year || ''}.xls`
            axios.post(`${baseApi}/api/ca/consumption-fact-gas/view-matrix-for-excel`, formSend, { responseType: 'blob' })
                .then((response: any) => {
                    if (response.data.res === 'error') {
                        errorToast({
                            title: t('factual.view_factual_gas_title'),
                            text: t('error_on_download')
                        })
                    }
                    else fileDownload(response.data, filename)
                })
                .catch(error => {
                    console.error(error);
                    errorToast({
                        title: t('factual.view_factual_gas_title'),
                        text: t('error_on_download')
                    })
                })
                .finally(() => {
                    setIsLoadingSave(false)
                })
        }
    }
    return (
        <PageLayout title={
            <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
                {t('factual.view_factual_gas_title')}
            </Typography>
        }>
            <Helmet>
                <title>
                    {t('factual.view_factual_gas_title')}
                </title>
            </Helmet>

            <div className='flex flex-wrap mob:flex-row justify-between mob:items-center gap-[24px] my-[24px] tab:my-[32px]'>
                <div className='tab:hidden grow'>
                    <MobileAmountTitle
                        text={t('consumption.amount_3_month')}
                        amount={data?.total}
                        amountTitle={t('cubicMeter')}
                        isFetching={false}
                    />
                </div>
                <div className="hidden tab:flex">
                    <AmountTitle
                        text={t('consumption.amount_total_month')}
                        amount={data?.total}
                        // amount={samplePageData.total}
                        amountTitle={t('cubicMeter')}
                        isFetching={false}
                    />
                </div>
                <div className='grow tab:grow-0 flex items-center tab:items-normal gap-[4px]'>
                    <Button
                        color='white'
                        className={`relative hidden tab:flex  `}
                        onClick={handleNavigateBack}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        color='black'
                        className='whitespace-nowrap w-full'
                        onClick={handleDownloadConsExcel}
                        disabled={isLoadingSave || isLoading}
                        loading={isLoadingSave}
                    >
                        {t('consumption.download_excel')}
                    </Button>
                </div>
            </div>
            <div className={`rounded-[16px] lap:rounded-[24px] p-[16px] mob:p-[24px] tab:p-[32px] lap:p-[48px] bg-white border-2 border-gray-100`}>
                <div className='mb-[24px] tab:mb-[32px] flex flex-col tab:flex-row tab:items-center justify-between'>
                    <div className='flex flex-col gap-[8px] mb-[12px] mob:mb-0'>
                        <Typography variant='lead' className='text-gray-800 font-medium text-[20px] tab:text-[24px]'>
                            {t('factual.view_factual_title')}
                        </Typography>
                        {
                            isChecked &&
                            <div className='flex flex-wrap items-center gap-[16px]'>
                                <Typography variant='lead' className='flex items-center gap-[5px] text-gray-700 font-normal text-[14px]'>
                                    <span className='block w-[10px] h-[10px] rounded-full bg-blue-600' />
                                    {t('factual.line_fact_matrix_title')}
                                </Typography>
                                <Typography variant='lead' className='flex items-center gap-[5px] text-gray-700 font-normal text-[14px]'>
                                    <span className='block w-[10px] h-[10px] rounded-full bg-orange-500' />
                                    {t('factual.line_gas_matrix_title')}
                                </Typography>
                                <Typography variant='lead' className='flex items-center gap-[5px] text-gray-700 font-normal text-[14px]'>
                                    <span className='block w-[10px] h-[10px] rounded-full bg-gray-500' />
                                    {t('factual.line_power_matrix_title')}
                                </Typography>
                            </div>
                        }
                    </div>
                    <div className="relative h-[44px] mob:w-[220px] w-full mt-[12px] tab:mb-0">
                        <input
                            // disabled={!service_id}
                            checked={isChecked}
                            onChange={() => {
                                setIsChecked(prev => !prev)
                            }}
                            id="switch-component-custom"
                            type="checkbox"
                            className="peer appearance-none h-[44px] mob:w-[220px] w-full rounded-full
                                bg-gray-50
                                cursor-pointer easy transition-colors duration-200
                                disabled:bg-gray-200
                                "
                        />
                        <label
                            htmlFor="switch-component-custom"
                            className=" 
                        flex items-center justify-center
                        absolute z-10 top-[4px] right-[4px]  w-[50%] mob:w-[100px] h-[36px] 
                        peer-checked:translate-x-[-100%] 
                        mob:peer-checked:translate-x-[-112px] 
                        bg-gray-700 text-white text-[14px] font-medium
                        rounded-full  cursor-pointer
                        easy transition-all duration-200

                        peer-disabled:bg-gray-300
                        "
                        >
                            {
                                isChecked ?
                                    t('by_day')
                                    :
                                    t('by_month')
                            }
                        </label>
                        <div className="
                            flex items-center justify-around
                            absolute top-[0px] left-[0px] mob:w-[220px] w-full h-[44px] rounded-full
                            pointer-events-none
                            text-gray-600 text-[14px] font-medium
                            border-2 border-gray-200 
                        ">
                            <span>
                                {t('by_day')}
                            </span>
                            <span>
                                {t('by_month')}
                            </span>
                        </div>
                    </div>
                </div>

                {
                    isChecked &&
                    <GasDaysGraphs
                        // year={data?.year}
                        // month={data?.month}
                        fact_matrix={data?.fact_matrix}
                        gas_matrix={data?.gas_matrix}
                        power_matrix={data?.power_matrix}
                    />
                }
                {
                    !isChecked &&
                    <GasMonthGraph
                        tooltipDate={`${t(`r_month_${data.month}`)} ${data.year}`}
                        fact_matrix={data?.fact_matrix}
                        gas_matrix={data?.gas_matrix}
                        power_matrix={data?.power_matrix}
                    />
                }
            </div>

        </PageLayout>
    )
}

export default ViewFactualGasAll