import * as React from 'react';
import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuHandler, MenuItem, MenuList, Typography } from '@material-tailwind/react';

import { isIterableArray, } from '../../utils/utils';

import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import PaginationEl from './PaginationEl';
import ArrowAccordionIcon from '../../images/icons/ArrowAccordionIcon';
import { OrderType, ROWS_PER_PAGE } from './tableConfig';
import TableLayout from '../../layouts/pagesLayouts/TableLayout';


export default function TableSortingCreator(
    {
        rows,
        Component,
        isLoadingTable
        // searchValue,
    }: {
        // RowsType: InvoiceRowType | InvoiceHistoryRowType | ActRowType | PaymentRowType,
        rows: any,
        Component: React.ElementType<any>,
        isLoadingTable: boolean
        // searchValue: string,
    }
) {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)


    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);


    // ______ SORTING
    const [order, setOrder] = React.useState<OrderType>('desc');
    const [orderBy, setOrderBy] = React.useState<string>('date_sorter');

    // кол-во страниц в пагинации
    const paginationCount = Math.ceil(rows?.length / rowsPerPage)

    useEffect(() => {
        setPage(1)
    }, [rows])

    // array rows (sorted and spliced)
    const visibleRows = React.useMemo(() => stableSort(rows, getComparator(order, orderBy)).slice(
        ((page - 1) * rowsPerPage) || 0,
        (page - 1) * rowsPerPage + rowsPerPage,
    ),
        [order, orderBy, page, rowsPerPage, rows]
    );
    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }
    function getComparator<Key extends keyof any>(
        order: OrderType,
        orderBy: Key,
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    // ______





    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const handleChangeRowsPerPage = (value: any) => {
        setRowsPerPage(parseInt(value, 10));
        setPage(1);
    };

    return (
        <>
            <div className='flex justify-end tab:hidden items-center gap-[8px] my-[24px]'>
                <Typography className='whitespace-nowrap text-[16px] text-gray-700'>
                    {t('pagination.rowsPerPage')}
                </Typography>

                <Menu placement="top" >
                    <MenuHandler>
                        <Button color='white' className='w-[64px] h-[40px] rounded px-[16px] flex items-center justify-between gap-[6px]'>
                            <span className='text-gray-700 text-[14px] font-normal'>{rowsPerPage}</span>
                            <span className='rotate-180'><ArrowAccordionIcon /></span>
                        </Button>
                    </MenuHandler>
                    <MenuList className='!min-w-[64px]'>
                        {ROWS_PER_PAGE.map((i, key) =>
                            <MenuItem key={key} className='text-center' onClick={() => handleChangeRowsPerPage(i)}>
                                <span className='text-gray-700 text-[14px] font-normal'>
                                    {i}
                                </span>
                            </MenuItem>
                        )}
                    </MenuList>
                </Menu>
            </div>
            <TableLayout>
                <Component
                    isLoadingTable={isLoadingTable}
                    rows={rows}
                    visibleRows={visibleRows}
                    orderBy={orderBy}
                    order={order}
                    service_id={service_id}
                    handleRequestSort={handleRequestSort}
                />
            </TableLayout>
            {
                isIterableArray(visibleRows) &&
                <div className='flex justify-center lap:grid lap:grid-cols-[1fr_4fr_1fr] mt-[64px]'>
                    <div className='hidden lap:flex items-center gap-[8px]'>
                        <Typography className='whitespace-nowrap text-[16px] text-gray-700'>
                            {t('pagination.rowsPerPage')}
                        </Typography>

                        <Menu placement="top" >
                            <MenuHandler>
                                <Button color='white' className='w-[64px] h-[40px] rounded px-[16px] flex items-center justify-between gap-[6px]'>
                                    <span className='text-gray-700 text-[14px] font-normal'>{rowsPerPage}</span>
                                    <span className='rotate-180'><ArrowAccordionIcon /></span>
                                </Button>
                            </MenuHandler>
                            <MenuList className='!min-w-[64px]'>
                                {ROWS_PER_PAGE.map((i, key) =>
                                    <MenuItem key={key} className='text-center' onClick={() => handleChangeRowsPerPage(i)}>
                                        <span className='text-gray-700 text-[14px] font-normal'>
                                            {i}
                                        </span>
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Menu>
                    </div>
                    {
                        paginationCount > 1 &&
                        <div className='flex  justify-center items-center'>
                            <PaginationEl
                                count={paginationCount}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </div>
                    }
                </div>
            }
        </>
    );
}