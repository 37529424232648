

import Chart, { CategoryScale, ChartOptions } from "chart.js/auto";
import { Line, ChartProps } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { toFormatPrice } from "../../../utils/utils";
import { classCharContainerHeight } from "../../analytics/AnalyticsPage";
import { legendMargin } from "../factual/MonthGraph";

Chart.register(CategoryScale);

const GasMonthGraph = ({ tooltipDate, fact_matrix, gas_matrix, power_matrix }: any) => {
    const { t } = useTranslation()

    // const labels = Array.from(Array(data_declared?.length).keys())
    const labels = Array.from(Array(fact_matrix?.length).keys()).map(item => ++item)
    // const labels = Array.from(Array(data_declared?.length).keys()).map(item => (item % 2 !== 0 ? item : ''))

    const dataGraph = {
        labels: labels,
        datasets: [
            {
                datalabels: {
                    display: false,
                },
                label: t('factual.line_fact_matrix_title'),
                data: fact_matrix,
                borderColor: '#4680FF', // blue-600
                borderWidth: 2,
                backgroundColor: '#4680FF',
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 5
            },
            {
                datalabels: {
                    display: false,
                },
                label: t('factual.line_gas_matrix_title'),
                data: gas_matrix,
                borderColor: '#FF7340', // orange-500
                borderWidth: 2,
                backgroundColor: '#FF7340',
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 5

            },
            {
                label: t('factual.line_power_matrix_title'),
                data: power_matrix,
                borderColor: '#BFBFC1', // gray-400
                borderWidth: 2,
                backgroundColor: '#BFBFC1',
                pointStyle: 'circle',
                pointRadius: 3,
                pointHoverRadius: 5

            }
        ]
    }
    const lineWidth = 26


    const options: any = {
        interaction: {
            mode: 'x',
            includeInvisible: true,
            intersect: false,
        },
        // aspectRatio: 3,
        layout: {
            padding: 0,
            autoPadding: false,
            // background: '#000'
        },
        plugins: {
            // beforeInit: function(chart: any) {
            //   chart.legend.afterFit = function() {
            //     console.log(chart)
            //     this.height = this.height + 50;
            //   };
            // },
            chartArea: {
                top: 200
            },
            legend: {
                position: 'top',
                reverse: true,
                align: 'start',
                labels: {
                    usePointStyle: true,
                    boxWidth: 6,
                    boxHeight: 6,
                    font: {
                        size: 14
                    },
                },
                title: {
                    display: false,
                },
                // display: false
            },
            tooltip: {
                xAlign: 'center',
                yAlign: 'bottom',
                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 4,
                borderColor: '#EDEDED',
                borderWidth: 2,
                displayColors: false,
                padding: 12,
                textDirection: 'top',
                // label: {
                //   formattedValue: 'string'
                // },
                callbacks: {
                    title: function (tooltipItems: any) {
                        return tooltipDate ? (tooltipItems[0].label + ' ' + tooltipDate) : ''
                    },
                    label: function (context: any) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += toFormatPrice(context.parsed.y);
                        }
                        return label;
                    }
                }
            },
        },
        scales: {
            x: {
                offset: true,
                grid: {
                    // drawBorder: false,
                    // display: false,
                    // color: "#f6f6f6",
                    color: "#f6f6f6",
                    // lineWidth: 2,
                    lineWidth: lineWidth,
                    // lineWidth: lineWidth,
                },
                stacked: true,
                border: {
                    // display: false
                    width: 0,
                    color: '#e4e4e4',
                }
            },
            y: {
                offset: true,
                min: 0,
                ticks: {
                    callback: (value: any) => value !== 0 ? value : t('cubicMeter')
                },
                grid: {
                    display: false,
                    color: "#f6f6f6",
                    lineWidth: 1
                },
                border: {
                    width: 0,
                    color: '#fff',
                    // display: false
                }
            },
        },
    };

    return (
        <div className={classCharContainerHeight}>
            <Line
                updateMode='resize'
                data={dataGraph}
                options={options}
                plugins={[legendMargin]}
            />
        </div>
    )
}

export default GasMonthGraph