import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Crumbs from './Crumbs';
import HeaderContract from './HeaderContract';
import Notifications from './Notifications';
import SwitchServiceType from './SwitchServiceType';
import { useContractBalancesViewQuery, useProfileInfoViewQuery } from '../../store/auth/authService';
import { AppDispatch, RootState } from '../../store/store';
import { setContractOrNumber, setContractTotal, setServiceId } from '../../store/service/contractSlice';
import { setCrumbsContract } from '../../store/service/headerSlice';
import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu';
import { setUserInfo } from '../../store/auth/authSlice';
import HeaderContractMobile from './HeaderContractMobile';
const Header = () => {

    const { t } = useTranslation()

    const dispatch = useDispatch<AppDispatch>()

    // const { param_contract_id } = useParams()

    const { contract_id } = useSelector((state: RootState) => state.contract)

    const { data, isFetching } = useContractBalancesViewQuery({
        contract_id: Number(contract_id)
    }, {
        skip: !contract_id
    })

    const userInfoData = useProfileInfoViewQuery({})

    useEffect(() => {
        if (userInfoData?.currentData) {
            dispatch(setUserInfo({
                user_avatar: userInfoData?.currentData.user_avatar
            }))
        }
    }, [userInfoData]);

    useEffect(() => {
        if (data) {
            dispatch(setServiceId(data.service_id))

            const total = data.service_id === 1 ? {
                balance_1: data.balance_1,
                balance_2: data.balance_2,
            }
                :
                data.service_id === 2 ? {
                    balance_3: data.balance_3,
                    balance_4: data.balance_4,
                    balance_5: data.balance_5,
                    balance_6: data.balance_6,
                }
                    :
                    {}
            dispatch(setContractTotal(total))
            dispatch(setContractOrNumber(data.or_number))


            dispatch(setCrumbsContract([
                {
                    text: `${t('or')} ${data.or_number} ${data.address}`,
                    to: `/contract/${data.contract_id}`,
                    icon: true
                }
            ]))
        }
    }, [data])


    // const [dark, setDark] = React.useState(false);

    // const darkModeHandler = () => {
    //     setDark(!dark);
    //     document.body.classList.toggle("dark");
    // }

    return (
        //  fixed top-0 z-10
        <header className='px-[16px] tab:px-[32px] lap:px-[48px] des:px-[96px] bg-white w-full shadow-header-2'>
            <div className='flex justify-between items-center py-[12px]'>
                <SwitchServiceType />

                {/* <div className="">
                    <button className='bg-dark p-3 rounded-full' onClick={() => darkModeHandler()}>
                        {
                            dark && "Light"
                        }
                        {
                            !dark && "Dark"
                        }
                    </button> 
                </div> */}

                <Crumbs />
                <div className='flex items-center justify-end gap-[8px] min-w-[80px] lap:min-w-[230px]'>
                    {
                        contract_id ?
                            <HeaderContractMobile data={data} />
                            :
                            ''
                    }
                    <Notifications />
                    <UserMenu />
                </div>
            </div>
            <HeaderContract isFetching={isFetching} />
        </header>
    )
}

export default Header
