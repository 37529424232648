
// Логотип на стр. авторизации
import logo from './images/logoAuthPage.svg'

// Фоновая картинка на стр. авторизации, стать клиентом
import bgAuth from './images/auth-bg.jpg'

// Ссылка у кнопки Веб-сайт Постачальника
export const SITE_LINK = 'https://cabinet.tviy.energy/'

// Название поставщика (ключ для перевода из файла локализации)
export const NAME_PROVIDER = 'auth.name_provider'

export { logo as AUTH_LOGO }
export { bgAuth as AUTH_BG_BODY }