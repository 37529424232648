import { Button, Tooltip } from '@material-tailwind/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '../../../images/icons/CheckIcon'
import CopyIcon from '../../../images/icons/CopyIcon'
import { errorToast } from '../../../components/toasts/toasts'

const CopyButton = (props: { copyText: string }) => {

    const { t } = useTranslation()
    const { copyText } = props

    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleCopy = (value: string) => {
        if (!value) {
            errorToast({
                title: t('nothing_to_copy')
            })
            return
        }
        try {
            navigator.clipboard.writeText(value)
                .then(() => {
                    setIsCopied(true)

                    setTimeout(() => {
                        setIsCopied(false)
                    }, 1000);
                })
                .catch(err => {
                    // возможно, пользователь не дал разрешение на чтение данных из буфера обмена
                    console.log('Something went wrong', err);
                });
        }
        catch {
            console.log('Catched error on copy!')
        }
    }


    return (
        <Tooltip content={isCopied ? t('copied') : t('copy')} placement='top'>
            <Button
                color="white"
                size='sm'
                className={`${isCopied && 'border-gray-50 !bg-white'} w-[40px] h-[40px] flex items-center justify-center`}
                onClick={(event: any) => {
                    event.stopPropagation()
                    handleCopy(copyText)
                }}
            >
                {isCopied ? <CheckIcon className='stroke-green-300' /> : <CopyIcon />}
            </Button>
        </Tooltip>
    )
}

export default CopyButton
