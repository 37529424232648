import React, { useEffect, useState } from 'react'
import { useViewCaAllocationQuery, useViewSaldoQuery } from '../../../store/auth/authService'
import { Spinner, Typography } from '@material-tailwind/react'
import moment from 'moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppDispatch, RootState } from '../../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import TableLayout from '../../../layouts/pagesLayouts/TableLayout'
import FiltersSaldo from './FiltersSaldo'
import GraphSaldo from './GraphSaldo'
import { classCharContainerHeight } from '../AnalyticsPage'
import { saveGraphToPicture } from '../../../utils/utils'

export type SaldoFormType = {
    contract_id?: number,
    balance_id: number,
    periodFrom: string,
    periodTo: string
}
export const SALDO_GRAPH_ID = "js-saldo-graph"

const ViewSaldo = () => {


    const { t } = useTranslation()

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)

    const defaulPeriodFrom = moment().add(-6, 'month').format('MM-YYYY')
    const defaulPeriodTo = moment().format('MM-YYYY')

    const form: SaldoFormType = {
        contract_id: Number(param_contract_id) || 0,
        periodFrom: defaulPeriodFrom,
        periodTo: defaulPeriodTo,
        balance_id: 1,
    }

    const [sendForm, setSendForm] = useState<SaldoFormType>(form);

    const { currentData, error, isFetching } = useViewSaldoQuery<any>(sendForm)

    const allocationData = useViewCaAllocationQuery<any>({
        contract_id: Number(param_contract_id)
    })


    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        dispatch(setContractId(Number(param_contract_id)))
    }, [param_contract_id]);


    const setFormForFetch = (values: any) => {
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }

    return (
        <div>

            <Typography variant='lead' className='text-gray-800 mb-[24px] tab:mb-[32px] text-[24px] lap:text-[32px] font-medium leading-[1.2]'>
                {t('analytics.analytics_saldo_title')}
            </Typography>
            <FiltersSaldo
                value={{
                    periodFrom: sendForm.periodFrom,
                    periodTo: sendForm.periodTo,
                    balance_id: sendForm.balance_id
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                savePicture={() => {
                    saveGraphToPicture(SALDO_GRAPH_ID, `${t('or')} ${or_number} - ${t('analytics.analytics_saldo_title')} ${t('by_period')} ${sendForm.periodFrom} - ${sendForm.periodTo}.png`)
                }}
                isAllocation={!!allocationData?.currentData}
            />
            {

                currentData?.formatted ?
                    <>
                        <div className='bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                            <Typography variant='lead' className='text-gray-800 mb-[12px] lap:mb-[24px] text-[20px] lap:text-[24px] font-medium'>
                                {t('analytics.analytics_saldo_subtitle')}
                            </Typography>
                            <div className={classCharContainerHeight}>
                                <GraphSaldo
                                    data={currentData.formatted}
                                />
                            </div>
                        </div>
                    </>
                    :
                    isFetching ?
                        <div className={`bg-white border-2 border-gray-200 rounded-[24px] p-[32px] ${classCharContainerHeight} flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            ''
            }

        </div>
    )
}

export default ViewSaldo