import { Drawer, IconButton, Typography } from '@material-tailwind/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import { isIterableArray } from '../../../utils/utils'
import { ActiveDateType, CalendarType } from '../ContractPage.types'
import { IVENTS_DATA } from './Calendar'
import { FILTER_SCREEN_MOBILE, MOBILE_DIVIDER_OFFSET } from '../../../utils/variables'
import useIsMobile from '../../../utils/useIsMobile'

const CalendarDrawer = ({ open, closeDrawer, drawerValue, activeDate }: {
    open: boolean, closeDrawer: () => void, drawerValue: CalendarType | undefined, activeDate: ActiveDateType
}) => {

    const { t } = useTranslation()

    const isMobile = useIsMobile(FILTER_SCREEN_MOBILE)

    const placement = isMobile ? 'bottom' : 'left'
    const size = isMobile ? window.innerHeight - MOBILE_DIVIDER_OFFSET : 500 
    
    return (
        <Drawer
            open={open}
            onClose={closeDrawer}
            // className={`!p-[30px] ${!open && '!left-0'}`}
            // size={width / 2}
            // size={640}
            className={`p-4 ${open ? 'tab:!left-[16px] opacity-1' : '!left-0 opacity-0 duration-[250ms]'} !bottom-0  lap:!max-w-[500px]  flex flex-col overflow-auto`}
            placement={placement}
            size={size}
        >
            <div className="mb-[30px] flex items-center justify-between">
                <Typography variant="h5" className='text-[18px] lap:text-[24px] font-medium text-gray-800'>
                    {t('contract_page.events_title')}
                </Typography>
                <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                    <CloseDrawerIcon />
                </IconButton>
            </div>
            {
                drawerValue &&
                <div>
                    <Typography variant="h5" className='text-[16px] lap:text-[20px] font-medium text-gray-800 mb-[16px] tab:mb-[24px]'>
                        {drawerValue.day} {t(`r_month_${activeDate.month}`)} {activeDate.year}
                    </Typography>
                    {
                        isIterableArray(drawerValue.data) &&
                        drawerValue.data.map((item, key) => {
                            const badge = IVENTS_DATA[item.type]
                            return (
                                <div key={key} className='bg-white border-2 border-gray-100 rounded-[16px] p-[16px] tab:p-[24px] mb-[8px]'>
                                    <div className='flex flex-wrap items-center justify-between mb-[8px] gap-[8px]'>
                                        <div className={`flex items-center gap-[5px] rounded px-[5px] py-[4px] ${badge.bg} text-sm text-white`}>
                                            <div className={`rounded-full w-[16px] h-[16px] p-[3px] border border-white`}>
                                                {badge.icon}
                                            </div>
                                            <span className='lowercase whitespace-nowrap'>
                                                {t(badge.name)}
                                            </span>
                                        </div>
                                        <div className='flex flex-col gap-[6px]'>
                                            {
                                                isIterableArray(item.target_number) &&
                                                item.target_number.map((num, key) => (
                                                    <p key={key} className='text-gray-700 text-[14px]'>
                                                        {t(`contract_page.target_type_${item.target_type}`)} #{num}
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <p className='text-gray-800'>
                                        {t(`contract_page.calendar_target_${item.target_text}`)}
                                    </p>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </Drawer>
    )
}

export default CalendarDrawer