import React, { useCallback, useState } from 'react'
import { useViewComparisonByYearPriceQuery } from '../../../store/auth/authService';
import { ConsFormType } from '../viewConsumption/ViewConsumption';
import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FiltersConsumption from '../viewConsumption/FiltersConsumption';
import { Spinner, Typography } from '@material-tailwind/react';
import TableLayout from '../../../layouts/pagesLayouts/TableLayout';
import { isData } from '../viewConsumption/ViewByPeriod';
import { saveGraphToPicture, toFormatPrice } from '../../../utils/utils';
import GraphConsumption from '../viewConsumption/GraphConsumption';
import { classCharContainerHeight } from '../AnalyticsPage';
import { topLabels } from './ViewComparisonByYear';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const GRAPH_ID = "js-comparison-by-year-graph"

const ViewComparisonByYearPrice = ({ isMobile }: { isMobile: boolean }) => {


    const { t } = useTranslation()

    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    const { or_number } = useSelector((state: RootState) => state.contract)

    const defaulPeriodFrom = moment().add(-11, 'month').format('MM-YYYY')
    const defaulPeriodTo = moment().add(1, 'month').format('MM-YYYY')

    const form: ConsFormType = {
        contract_id: Number(param_contract_id) || 0,
        periodFrom: defaulPeriodFrom,
        periodTo: defaulPeriodTo
    }


    const [sendForm, setSendForm] = useState<ConsFormType>(form);

    // sendForm
    const { currentData, error, isFetching } = useViewComparisonByYearPriceQuery<any>(sendForm)


    const setFormForFetch = (values: any) => {
        setSendForm(prev => ({
            ...prev, ...{
                ...values,
                contract_id: param_contract_id
            }
        }))
    }

    const datachar = useCallback(
        () => {
            const data = currentData.formatted
            const datasets = []
            if (isData(data.rdn)) datasets.push({
                animation: false,
                transitions: false,
                order: 4,
                label: t('analytics.tarifs_rdn'),
                data: data.rdn,
                backgroundColor: '#E4DDFF',
                borderRadius: 4,
                spanGaps: false,
                stack: 'Stack1',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }

            })

            if (isData(data.transfer)) datasets.push({
                animation: false,
                transitions: false,
                order: 3,
                label: t('analytics.tarifs_transfer'),
                data: data.transfer,
                backgroundColor: '#C4B5FD',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            })

            if (isData(data.class1)) datasets.push({
                animation: false,
                transitions: false,
                order: 2,
                label: t('analytics.tarifs_class1'),
                data: data.class1,
                backgroundColor: '#8E70FF',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#444248';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            })
            if (isData(data.class2)) datasets.push({
                animation: false,
                transitions: false,
                order: 1,
                label: t('analytics.tarifs_class2'),
                data: data.class2,
                backgroundColor: '#444248',
                borderRadius: 4,
                spanGaps: true,
                stack: 'Stack1',
                datalabels: {
                    display: !isMobile,
                    align: 'center',
                    // rotation: '-90',
                    anchor: 'center',
                    formatter: function (value: any) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context: any) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        return '#fff';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            })


            if (data) return {
                labels: data?.labels_string,
                datasets: datasets
            }
        },
        [currentData?.formatted, isMobile],
    )


    return (
        <div>
            <Typography variant='lead' className='text-gray-800 text-[24px] lap:text-[32px] text-left mb-[24px] tab:mb-[32px] font-medium leading-[1.2]'>
                {t('analytics.analytics_comparison_by_year_price_title')}
            </Typography>
            <FiltersConsumption
                value={{
                    periodFrom: sendForm.periodFrom,
                    periodTo: sendForm.periodTo,
                }}
                isLoadingFetch={isFetching}
                fetchPage={setFormForFetch}
                savePicture={() => {
                    saveGraphToPicture(GRAPH_ID, `${t('or')} ${or_number} - ${t('analytics.analytics_comparison_by_year_price_title')} ${t('by_period')} ${sendForm.periodFrom} - ${sendForm.periodTo}.png`)
                }}
                isForSave={!!currentData?.isForSave}
            />
            {

                currentData?.formatted ?
                    <div className='bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px]'>
                        <Typography variant='lead' className='text-gray-800 text-center mb-[24px] tab:mb-[32px] text-[20px] lap:text-[24px] text-left'>
                            {t('analytics.tarifs_by_period_title')}
                        </Typography>
                        <div className={classCharContainerHeight}>
                            <GraphConsumption
                                graphId={GRAPH_ID}
                                datachar={datachar()}
                                isMobile={isMobile}
                                plugins={isMobile ? [] : [topLabels]}
                                fixedCount={2}
                                isSummTooltip={true}
                            />
                        </div>
                    </div>
                    :
                    isFetching ?
                        <div className={`bg-white border-2 border-gray-200 rounded-[16px] lap:rounded-[24px] p-[24px] lap:p-[32px] ${classCharContainerHeight} flex justify-center items-center`}>
                            <Spinner />
                        </div>
                        :
                        error ?
                            <TableLayout>
                                <Typography variant='lead' className='text-[16px] lap:text-[20px] font-medium text-center my-[40px]'>
                                    {t('tables.error_on_loading')}
                                </Typography>
                            </TableLayout>
                            :
                            ''
            }

        </div>
    )
}

export default ViewComparisonByYearPrice