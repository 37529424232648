import { Checkbox, Typography } from '@material-tailwind/react'
import { FormControlLabel } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ElectroConnection = ({ control, register, setValue, setErrorsValidService }: any) => {


    const { t } = useTranslation()


    const {
        field: { ref, value, onChange, ...inputProps },
        formState: { errors }
    } = useController({
        name: 'service_1',
        control,
        defaultValue: false
    });


    return (
        <div className='flex flex-col gap-[24px]'>
            <FormControlLabel
                control={
                    <Checkbox
                        // checked={value?.some(
                        //   (checked: any) => checked === item.id
                        // )}
                        {...inputProps}
                        inputRef={ref}
                        onChange={(e) => {
                            onChange(e.target.checked)
                            if (e.target.checked) setErrorsValidService(false)
                        }}
                    />
                }
                label={t('new_connection.service_1')}
            />
            {
                value &&
                <div className='flex flex-col gap-[24px]'>
                    <div className='flex flex-col gap-[16px]'>
                        <Typography variant='lead' className='text-gray-800 text-[16px] font-normal' >
                            {t('new_connection.group_title')}
                        </Typography>
                        <div className='flex flex-col gap-[8px]'>
                            <Checkbox
                                {...register(`group_1`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.group_1')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('group_2', false)
                                        setValue('group_3', false)
                                        setValue('group_4', false)
                                    }
                                }}
                            />
                            <Checkbox
                                {...register(`group_2`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.group_2')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('group_1', false)
                                        setValue('group_3', false)
                                        setValue('group_4', false)
                                    }
                                }}
                            />
                            <Checkbox
                                {...register(`group_3`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.group_3')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('group_1', false)
                                        setValue('group_2', false)
                                        setValue('group_4', false)
                                    }
                                }}
                            />
                            <Checkbox
                                {...register(`group_4`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.group_4')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('group_1', false)
                                        setValue('group_2', false)
                                        setValue('group_3', false)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-[16px]'>
                        <Typography variant='lead' className='text-gray-800 text-[16px] font-normal' >
                            {t('new_connection.class_title')}
                        </Typography>
                        <div className='flex flex-col gap-[8px]'>
                            <Checkbox
                                {...register(`class_1`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.class_1')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('class_2', false)
                                        setValue('class_3', false)
                                    }
                                }}
                            />
                            <Checkbox
                                {...register(`class_2`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.class_2')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('class_1', false)
                                        setValue('class_3', false)
                                    }
                                }}
                            />
                            <Checkbox
                                {...register(`class_3`)}
                                labelProps={{
                                    className: 'text-[14px] des:text-[16px]'
                                }}
                                className='rounded-full checked:bg-white border-2'
                                icon={<span className='block w-[10px] h-[10px] bg-black rounded-full ' />}
                                label={t('new_connection.class_3')}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setValue('class_1', false)
                                        setValue('class_2', false)
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default ElectroConnection