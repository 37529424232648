import { useEffect, useState } from 'react'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import { Button } from '@material-tailwind/react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FilterDateItem from '../../tableHelpers/filters/FilterDateItem'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { useForm } from 'react-hook-form'
import FilterDayMonthYear from '../../tableHelpers/filters/FilterDayMonthYear'
import { ImageDownloadButton } from '../ImageDownloadButton'

const FilterByMonth = (props: {
    value: {
        period: string,
    },
    isLoadingFetch: boolean,
    savePicture: any,
    fetchPage: any,
    formatView: 'MMMM YYYY' | 'DD MMMM YYYY',
    formatFilter: 'MM-YYYY' | 'DD-MM-YYYY',
    formatChange: 'month' | 'day'
    // defaulPeriodFrom: string,
    // defaulPeriodTo: string,
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        formatFilter,
        formatChange,
        formatView,
        savePicture
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    const {
        control,
        getValues,
        setValue,
    } = useForm<{
        period: string,
    }>({
        defaultValues: {},
    })

    useEffect(() => {
        setValue('period', value.period)
    }, [value.period]);


    const [filtersState, setFiltersState] = useState<{
        period: string,
    }>({
        period: moment(value.period, formatFilter).format(formatView)
    });

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState({
            period: moment(values.period, formatFilter).format(formatView)
        })
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    const handleChangeMonth = (val: 1 | -1) => {
        const period = moment(value.period, formatFilter).add(val, formatChange).format(formatFilter)
        fetchPage({
            period: period
        })
    }

    return (

        <div className='flex justify-between items-center mb-[24px] tab:mb-[32px] mt-[12px] gap-[8px]'>
            <div className='flex flex-wrap justify-between grow items-center gap-[8px]'>
                <div className='flex w-full items-center gap-[8px]'>
                    {
                        formatFilter === 'DD-MM-YYYY' &&
                        <FilterDayMonthYear
                            isLoadingFetch={false}
                            control={control}
                            fieldName={'period'}
                            defaultValue={value.period}
                            filterStateValue={moment(value.period, formatFilter).format(formatView)}
                            handleCloseFilter={handleCloseFilter}
                            valueLabelFormatter={(label: string) => {
                                return moment(label, formatFilter).format("DD MMMM YYYY")
                            }}
                        />
                    }

                    {
                        formatFilter === 'MM-YYYY' &&
                        <FilterDateItem
                            isLoadingFetch={isLoadingFetch || !value.period}
                            placement={'bottom-start'}
                            control={control}
                            fieldName={'period'}
                            label={t("filters.period")}
                            valueLabelFormatter={(label: string) => {
                                const lab = moment(label, formatFilter).format("MMMM YYYY")
                                return <span>{String(lab).charAt(0).toUpperCase() + String(lab).slice(1)}</span>
                            }}
                            datesData={data}
                            handleCloseFilter={handleCloseFilter}
                            isDisabled={(date: string) => {
                                return false
                            }}
                            containerClass={'w-full mob:w-auto'}
                        />
                    }
                    <Button color='white' className='h-[50px] w-[50px] flex items-center justify-center rounded ' onClick={() => handleChangeMonth(-1)}>
                        <div className='rotate-[270deg] mr-[0px]'>
                            <ArrowAccordionIcon />
                        </div>
                    </Button>
                    <Button color='white' className='h-[50px] w-[50px] flex items-center justify-center rounded ' onClick={() => handleChangeMonth(1)}>
                        <div className='rotate-90 ml-[2px]'>
                            <ArrowAccordionIcon />
                        </div>
                    </Button>
                </div>
            </div>
            <ImageDownloadButton text={t('download')} onClick={savePicture} disabled={isLoadingFetch} />
        </div>
    )
}

export default FilterByMonth