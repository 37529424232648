import { Button, Menu, MenuHandler, MenuItem, MenuList, Spinner, Typography } from '@material-tailwind/react'
import React from 'react'
import { ContractsArrType } from './types'
import { useController } from 'react-hook-form';
import SelectIcon from '../../images/icons/SelectIcon';
import { isIterableArray } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../images/icons/CheckIcon';

const SelectContract = ({ currentData, fieldName, control, defaultValue, isFetching, isErrorContract }: any) => {

    const { t } = useTranslation()

    const {
        field: { value, onChange },
    } = useController({
        rules: {
            required: t('support.required_input')
        },
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    const getContractName = (id: number, data: ContractsArrType[]) => {
        const selectedContract = data.find((i: ContractsArrType) => i.id === id) || null
        return (
            selectedContract ? <>{selectedContract.or_number ? `#${selectedContract.or_number} - ` : ''}  {selectedContract.name ? selectedContract.name : ''}</> : ''
        )
    }


    return (
        <>
            <Menu placement="bottom-start" >
                <MenuHandler>
                    <Button
                        color='white'
                        className='px-[16px] rounded flex items-center justify-between whitespace-nowrap overflow-hidden'
                        onClick={(event: any) => {
                            event.stopPropagation()
                        }}
                        disabled={isFetching}
                    >
                        <span className='text-[12px] tab:text-[14px]'>
                            {
                                currentData && value ?
                                    getContractName(value, currentData)
                                    :
                                    t('support.select_contract_placeholder')
                            }
                        </span>
                        {isFetching ?
                            <Spinner />
                            :
                            <SelectIcon />}
                    </Button>
                </MenuHandler>
                <MenuList className='z-[10001] w-auto'>
                    {
                        isIterableArray(currentData) && currentData?.map((item: ContractsArrType, key: number) => (
                            <MenuItem
                                key={key}
                                onClick={() => {
                                    onChange(item.id);
                                }}
                                className={`flex items-center gap-[6px] py-[10px] px-[16px] text-gray-700 ${value === item.id ? 'bg-gray-100 font-medium' : ''}`}
                            >

                                {
                                    value === item.id ?
                                        <CheckIcon className='!stroke-gray-700' />
                                        :
                                        ''
                                }
                                {item.or_number ? `#${item.or_number} - ` : ''}  {item.name ? item.name : ''}
                            </MenuItem>
                        ))
                    }s
                </MenuList>
            </Menu>
            <Typography variant='lead' className={`leading-1 text-red-500 text-[12px] des:text-[14px] transition-all ${isErrorContract ? 'opacity-1' : 'opacity-0'}`} >
                {t('support.required_input')}
            </Typography>
        </>
    )
}

export default SelectContract