import { Button, Typography } from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { NO_OVERLAY_CLASS } from '../../../layouts/pagesLayouts/OverlayUnTouchable'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import { isIterableArray } from '../../../utils/utils'
import FilterDateItem from '../../tableHelpers/filters/FilterDateItem'
import FilterItem from '../../tableHelpers/filters/FilterItem'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import MobileFilters from './MobileFilters'

// export const defaulPeriodFrom = '05-2023'
// export const defaulPeriodTo = '07-2023'
type ConsFormInput = {
    periodFrom: string,
    periodTo: string,
    tko: number[]
}

const Filters = (props: {
    value: ConsFormInput,
    isLoadingFetch: boolean,
    fetchPage: any,
    listTko: any,
    // defaulPeriodFrom: string,
    // defaulPeriodTo: string,
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        listTko,
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const { pathname, search } = useLocation()
    const { param_contract_id } = useParams()
    const service_id = useSelector((state: RootState) => state.contract.service_id)

    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    // const defaultState: ConsFormInput = {
    //     periodFrom: defaulPeriodFrom,
    //     periodTo: defaulPeriodTo,
    //     tko: [],
    // }
    const {
        control,
        getValues,
        setValue,
    } = useForm<ConsFormInput>({
        defaultValues: {},
    })

    useEffect(() => {
        // setValue({
        //     periodFrom: value.periodFrom,
        //     periodTo: value.periodTo,
        //     tko: value.tko,
        // })
        setValue('periodFrom', value.periodFrom)
        setValue('periodTo', value.periodTo)
        setValue('tko', value.tko)

    }, [value]);


    const [filtersState, setFiltersState] = useState<ConsFormInput>(value);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }


    const [computedLink, setComputedLink] = useState<string | null>(null);


    useEffect(() => {
        const getHref = (): string | null => {
            const tkos = isIterableArray(filtersState.tko) ? filtersState.tko.join(',')
                :
                isIterableArray(listTko) ? listTko.map((i: any) => i.value).join(',')
                    :
                    false
            if (tkos) {
                const href = `/consumptions/${param_contract_id}/create-declared/` + '?&from=' + filtersState.periodFrom + '&to=' + filtersState.periodTo + '&tkos=' + tkos
                return href
            }
            return null
        }
        const href = getHref()
        setComputedLink(href)
    }, [filtersState, listTko, param_contract_id]);

    return (
        <div className='flex justify-between items-center mb-[32px] mt-[12px] gap-[8px]'>

            <MobileFilters
                handleCloseFilters={handleCloseFilter}
                control={control}
                datesData={data}
                filtersState={filtersState}
                listTko={listTko}
            />

            <div className='hidden tab:flex flex-wrap justify-between grow items-center gap-[8px]'>
                <div className='flex items-center gap-[8px]'>
                    <Typography className='text-gray-700'>
                        {t('filters.period')}
                    </Typography>
                    <FilterDateItem
                        isLoadingFetch={false}
                        placement={'bottom-start'}
                        control={control}
                        fieldName={'periodFrom'}
                        label={t("filters.period_from")}
                        datesData={data}
                        handleCloseFilter={handleCloseFilter}
                        isDisabled={(date: string) => {
                            return moment(value.periodTo, 'MM-YYYY').isBefore(moment(date, 'MM-YYYY'))
                        }}
                    />
                    <span className='rotate-90'>
                        <ArrowAccordionIcon />
                    </span>
                    <FilterDateItem
                        isLoadingFetch={false}
                        placement={'bottom-end'}
                        control={control}
                        fieldName={'periodTo'}
                        label={t("filters.period_to")}
                        datesData={data}
                        handleCloseFilter={handleCloseFilter}
                        isDisabled={(date: string) => {
                            return moment(value.periodFrom, 'MM-YYYY').isAfter(moment(date, 'MM-YYYY'))
                        }}
                    />
                </div>
                <FilterItem
                    isLoadingFetch={isLoadingFetch}
                    control={control}
                    label={t('filters.all_tkos')}
                    fieldName={'tko'}
                    defaultValue={value.tko}
                    filtersData={listTko}
                    handleCloseFilter={handleCloseFilter}
                />
            </div>
            {
                service_id === 1 &&
                <NavLink state={{
                    pathname, search
                }}
                    to={computedLink ? computedLink : '#'}>
                    <Button
                        disabled={!computedLink}
                        color='black'
                        className={`${NO_OVERLAY_CLASS}`}
                    // onClick={() => {
                    //     if (computedLink) navigate(computedLink)
                    // }}
                    >
                        {t('consumption.create_consumption')}
                    </Button>
                </NavLink>
            }
        </div>
    )
}

export default Filters