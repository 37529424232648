import { useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, ListItem, Typography } from '@material-tailwind/react'

import { useController } from 'react-hook-form'
import moment from 'moment'
import MonthYearSelect from './MonthYearSelect'
import { DateSelectType } from '../../contract/ContractPage.types'
import ArrowAccordionIcon from '../../../images/icons/ArrowAccordionIcon'
import { useTranslation } from 'react-i18next'

type propTypes = {
    control: any,
    fieldName: string,
    label: string,
    datesData: DateSelectType,
    isDisabled: (date: string) => boolean,
    isOpenDefault?: boolean,
    handleChange?: any
    // disabledDate?: string
}
const FilterDateItemMobile = (props: propTypes) => {

    const { t } = useTranslation()

    const { control, fieldName, label, datesData, isDisabled, isOpenDefault = true, handleChange } = props

    const [open, setOpen] = useState<boolean>(isOpenDefault);
    const toggleAccordion = () => setOpen(prev => !prev);




    // const defaultValue = defaultMonth + '-' + defaultYear
    const defaultValue = ''
    const {
        field: { value, onChange }
    } = useController({
        name: fieldName,
        control,
        defaultValue: defaultValue
    });

    // const [activeYear, setActiveYear] = useState<number>(Number(moment(value, 'MM-YYYY').format('YYYY') || 0));
    // const [activeMonth, setActiveMonth] = useState<string>(moment(value, 'MM-YYYY').format('MM') || '');

    const handleSelectDate = (date: string) => {
        onChange(date)
        if (handleChange) handleChange()
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }
    return (
        <div className=''>
            <Accordion
                open={open}
                icon={<div className={`${open ? '' : 'rotate-180'}`}><ArrowAccordionIcon /></div>}
                className='tab:mb-[24px]'
            >
                <ListItem className='p-0'>
                    <AccordionHeader
                        className='bg-white border-2 border-gray-100 rounded p-[16px]'
                        onClick={toggleAccordion}
                    >
                        <div className="flex items-start justify-between">
                            <div className='flex flex-col gap-[0px]'>
                                <Typography variant="lead" className='text-[14px] text-gray-800 font-bold flex items-center gap-[10px]' >
                                    <span>{label}</span>
                                    {
                                        value ? <>
                                            <span>{dateParser(value)}</span>
                                        </> : ''
                                    }
                                </Typography>
                            </div>
                        </div>
                    </AccordionHeader>
                </ListItem>
                <AccordionBody className='mt-0 tab:mt-[16px]'>
                    <MonthYearSelect
                        handleSelectDate={handleSelectDate}
                        isDisabled={isDisabled}
                        datesData={datesData}
                        value={value}
                    />
                </AccordionBody>
            </Accordion>
        </div >
    )
}

export default FilterDateItemMobile
