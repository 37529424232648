import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Typography,
} from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { toStringPrice } from "../../../utils/utils";

const AmountDeltaPopover = ({ amount, amount_delta }: any) => {

    const { t } = useTranslation()

    const service_id = useSelector((state: RootState) => state.contract.service_id)


    const amountDeltaService = service_id === 1 ? 'text-badge-orange border-badge-orange ' : service_id === 2 ? 'text-badge-blue border-badge-blue ' : ''

    const [openPopover, setOpenPopover] = useState(false);

    const triggers = {
        onclick: () => setOpenPopover(prev => !prev),
        onMouseEnter: () => setOpenPopover(true),
        onMouseLeave: () => setOpenPopover(false),
    };

    return (
        <Popover
            open={openPopover} handler={setOpenPopover}
            placement='top'
        >
            <PopoverHandler {...triggers}>
                <button className={`${amountDeltaService} border-2 p-[4px] w-[20px] h-[20px] rounded-full flex items-center justify-center text-[12px] cursor-help`}>!</button>
            </PopoverHandler>
            <PopoverContent {...triggers} className='p-[24px]'>
                <div className="flex flex-col gap-[12px]">
                    <Typography variant="lead">
                        {t('consumption.popover_declared_amount')}{': '}<span className="text-gray-800 font-bold">{toStringPrice(amount)}</span>
                    </Typography>
                    <Typography variant="lead">
                        {t('consumption.popover_declared_amount_delta')}{': '}<span className="text-gray-800 font-bold">{toStringPrice(amount_delta)}</span>
                    </Typography>
                </div>
            </PopoverContent>

        </Popover>
    )
}

export default AmountDeltaPopover
