import { Typography } from '@material-tailwind/react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageLayout from '../../../layouts/pagesLayouts/PageLayout'
import { useViewDetailingInfoQuery } from '../../../store/auth/authService'
import { setContractId, setServiceId } from '../../../store/service/contractSlice'
import { AppDispatch } from '../../../store/store'
import { isIterableArray } from '../../../utils/utils'
import TkoInfoCard from '../TkoInfoCard'
import MatrixDays from '../matrix/MatrixDays'
import { SubmitHandler, useForm } from 'react-hook-form'
import MatrixMonth from '../matrix/MatrixMonth'
import axios from 'axios'
import { baseApi } from '../../../ConfigApi'
import { errorToast, successToast } from '../../../components/toasts/toasts'
import { Helmet } from 'react-helmet'

const EditDetailing = () => {

  const { t } = useTranslation()
  const { param_contract_id, param_cons_id, param_tko_id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch<AppDispatch>()

  const infoTko = useViewDetailingInfoQuery({
    contract_id: Number(param_contract_id),
    id: Number(param_cons_id)
  })


  // console.log(matrixData)

  useEffect(() => {
    dispatch(setContractId(Number(param_contract_id)))
  }, [param_contract_id]);

  useEffect(() => {
    if (infoTko?.data?.service_id) dispatch(setServiceId(Number(infoTko.data.service_id)))
  }, [infoTko?.data]);



  // for days
  const {
    handleSubmit,
    register,
    control,
    setValue,
  } = useForm<any>({
    defaultValues: [],
  })
  useEffect(() => {
    if (infoTko?.data?.matrix_detailing === 1) setValue('data', infoTko?.data.matrix)
  }, [infoTko?.data])


  const [isLoadingSave, setIsLoadingSave] = useState(false);


  const saveMatrixDays = async () => {
    handleSubmit(onSubmit)()
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const matrix = isIterableArray(data.data) ? data.data.map((i: any) => Number(i)) : []
    saveDetailing({
      matrix: matrix,
      type_detailing: 1
    })
  }

  const saveMatrixMonth = (data: any) => {
    saveDetailing({
      matrix: data,
      type_detailing: 2
    })
  }


  const saveDetailing = (val: any) => {
    setIsLoadingSave(true)
    axios.post(`${baseApi}/api/ca/consumption-detailing/create`, {
      type_detailing: val.type_detailing,
      detailing: val.matrix,
      tko_id: Number(param_tko_id),
      month: infoTko?.data?.tko?.month || '',
      year: infoTko?.data?.tko?.year || '',
      contract_id: Number(param_contract_id)
    })
      .then((response: any) => {
        if (response.data.res === 'error') {
          errorToast({
            title: t('detailing.edit_detailing_title'),
            text: t('detailing.error_on_save')
          })
        }
        else {
          successToast({
            title: t('detailing.success_on_save')
          })
          handleNavigateBack()
        }
      })
      .catch(error => {
        console.error(error);
        errorToast({
          title: t('detailing.edit_detailing_title'),
          text: t('detailing.error_on_save')
        })
      })
      .finally(() => {
        setIsLoadingSave(false)
      })
  }


  const handleNavigateBack = () => {
    console.log(location)
    if (location.state?.pathname) navigate(location.state.pathname + (location.state?.search ? location.state.search : ''))
    else navigate(`/consumptions/${param_contract_id}`)
  }

  return (
    <PageLayout title={
      <Typography variant='h3' className='text-gray-800 text-center mb-[32px]'>
        {t('detailing.edit_detailing_title')}
      </Typography>
    }>
      <Helmet>
        <title>
          {t('detailing.edit_detailing_title')}
        </title>
      </Helmet>
      {infoTko?.data?.tko && <TkoInfoCard tko={infoTko.data.tko} />}

      {/* <div className='my-[32px] flex items-center justify-between'>

        <div className="flex flex-col gap-[32px]">

          <AmountTitle
            text={t('detailing.amount_total_month')}
            amount={infoTko?.data?.amount}
            amountTitle={t('kvHour')}
            isFetching={false}
          />
        </div>
        <div className="flex items-center gap-[8px]">
          <Button
            // disabled={!computedLink}
            color='white'
            className={`relative`}
            onClick={() => {
              navigate(`/consumptions/${param_contract_id}`)
            }}
          >
            {t('to_back')}
          </Button>
          <Button color='black' onClick={handleSave}>
            {t('consumption.save')}
          </Button>
        </div>
      </div> */}
      {
        infoTko?.data?.matrix_detailing === 1 &&
        <MatrixDays
          handleNavigateBack={handleNavigateBack}
          control={control}
          data={infoTko.data.matrix}
          register={register}
          setValue={setValue}
          isEditAccess={true}
          saveMatrixDays={saveMatrixDays}
          amount={infoTko?.data?.amount}
          isLoadingSave={isLoadingSave}
        />
      }
      {
        infoTko?.data?.matrix_detailing === 2 &&
        isIterableArray(infoTko.data.matrix) &&
        <MatrixMonth
          handleNavigateBack={handleNavigateBack}
          saveMatrixMonth={saveMatrixMonth}
          amount={infoTko?.data?.amount}
          daysLength={infoTko.data.matrix[0].length}
          isEditAccess={true}
          detailing={infoTko.data.matrix}
          isLoadingSave={isLoadingSave}
        />
      }
    </PageLayout>
  )
}

export default EditDetailing
