import { Button, Typography } from '@material-tailwind/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EyeIcon from '../../images/icons/EyeIcon'
import EyeCloseIcon from '../../images/icons/EyeCloseIcon'
import axios from 'axios'
import { baseApi } from '../../ConfigApi'
import { errorToast, successToast } from '../../components/toasts/toasts'

const minimum8Chars = /^.{8,}$/
const withoutSpaces = /^\S*$/
const containsLetters = /^.*[a-zA-Z]+.*$/
const containsNumbers = /^.*[0-9]+.*$/


const ChangePassword = () => {

    const { t } = useTranslation()

    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

    const [passwordInput, setPasswordInput] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordCheck, setNewPasswordCheck] = useState('');

    const [errorText, setErrorText] = useState('');


    const handleCheckNewPassword = () => {
        if (newPassword === passwordInput) {
            setErrorText(t('profile.equal_with_current_password'))
            return
        }
        if (newPassword !== newPasswordCheck) {
            setErrorText(t('profile.not_equal_passwords'))
            return
        }
        if (!containsNumbers.test(newPassword)) {
            setErrorText(t('profile.passwords_error_no_numbers'))
            return
        }
        if (!containsLetters.test(newPassword)) {
            setErrorText(t('profile.passwords_error_no_letters'))
            return
        }
        if (!minimum8Chars.test(newPassword)) {
            setErrorText(t('profile.passwords_error_length'))
            return
        }
        if (!withoutSpaces.test(newPassword)) {
            setErrorText(t('profile.passwords_error_whitespaces'))
            return
        }

        handleChangePassword()
        setErrorText('')

        // else { 
        //     if (
        //         containsNumbers.test(newPassword) &&
        //         minimum8Chars.test(newPassword) &&
        //         withoutSpaces.test(newPassword) &&
        //         containsLetters.test(newPassword)
        //     ) {
        //         console.log('ok', newPassword);
        //     }
        //     else {
        //         setErrorText(t('profile.passwords_error'))
        //     }
        // }
    }

    const handleChangePassword = () => {

        setIsLoadingSave(true)
        axios.post(`${baseApi}/api/cabinet/profile-updatepassword`, {
            password: passwordInput,
            newpassword: newPassword
        })
            .then((response: any) => {
                if (response.data.res === 'Error') {
                    errorToast({
                        title: t('profile.change_password'),
                        text: t('profile.error_on_change_password')
                    })
                }
                else {
                    successToast({
                        title: t('profile.change_password'),
                        text: t('profile.success_change_password')
                    })
                    // handleConfirm()
                }
            })
            .catch(error => {
                console.error(error);
                errorToast({
                    title: t('profile.change_password'),
                    text: t('profile.error_on_change_password')
                })
            })
            .finally(() => {
                setIsLoadingSave(false)
            })
    }

    return (
        <>
            <div className='flex flex-col gap-[24px]'>
                <div className='flex flex-col gap-[8px]'>
                    <label className='text-[16px] text-gray-700'>
                        {t('profile.current_password')}
                    </label>
                    <div className='relative'>
                        <input
                            value={passwordInput}
                            autoComplete="new-password"
                            type={isShowPassword ? 'text' : 'password'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setPasswordInput(e.target.value)
                                setErrorText('')
                            }}
                            className={`bg-gray-50 border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                                hover:bg-gray-50 hover:border-gray-300 
                                                focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                    `}
                        />
                        <div
                            onClick={() => {
                                setIsShowPassword(prev => !prev)
                            }}
                            className='cursor-pointer absolute flex items-center justify-center w-[32px] h-[32px] top-[50%] translate-y-[-50%] right-[16px]'
                        >
                            {isShowPassword ? <EyeCloseIcon /> : <EyeIcon />}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-[8px]'>
                    <label htmlFor='new_password' className='text-[16px] text-gray-700'>
                        {t('profile.new_password')}
                    </label>
                    <div className='relative'>
                        <input
                            id='new_password'
                            value={newPassword}
                            autoComplete="new-password1"
                            type={isShowNewPassword ? 'text' : 'password'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setNewPassword(e.target.value)
                                setErrorText('')
                            }}
                            className={`bg-gray-50 border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                                hover:bg-gray-50 hover:border-gray-300 
                                                focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                    `}
                        />
                        <div
                            onClick={() => {
                                setIsShowNewPassword(prev => !prev)
                            }}
                            className='cursor-pointer absolute flex items-center justify-center w-[32px] h-[32px] top-[50%] translate-y-[-50%] right-[16px]'
                        >
                            {isShowNewPassword ? <EyeCloseIcon /> : <EyeIcon />}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-[8px]'>
                    <label htmlFor='check_password' className='text-[16px] text-gray-700'>
                        {t('profile.check_password')}
                    </label>

                    <div className='relative'>
                        <input
                            id='check_password'
                            value={newPasswordCheck}
                            autoComplete="new-password2"
                            type={isShowConfirmPassword ? 'text' : 'password'}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setNewPasswordCheck(e.target.value)
                                setErrorText('')
                            }}
                            className={`bg-gray-50 border-2 border-gray-200 rounded w-full h-[48px] px-[16px] text-gray-800 text-[18px]
                                                hover:bg-gray-50 hover:border-gray-300 
                                                focus:border-gray-200 focus-visible:!border-gray-200 transition-all
                                    `}
                        />
                        <div
                            onClick={() => {
                                setIsShowConfirmPassword(prev => !prev)
                            }}
                            className='cursor-pointer absolute flex items-center justify-center w-[32px] h-[32px] top-[50%] translate-y-[-50%] right-[16px]'
                        >
                            {isShowConfirmPassword ? <EyeCloseIcon /> : <EyeIcon />}
                        </div>
                    </div>
                </div>
                <Button
                    color='black'
                    className='justify-center'
                    onClick={handleCheckNewPassword}
                    disabled={!newPassword || !newPasswordCheck || isLoadingSave}
                    loading={isLoadingSave}
                >
                    {t('profile.save_password')}
                </Button>
            </div>
            <Typography variant='lead' className='flex items-center justify-center font-medium text-[14px] text-center text-red-500 min-h-[32px]'>
                {errorText && errorText}
            </Typography>
        </>
    )
}

export default ChangePassword