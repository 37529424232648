import { Fragment, useState } from 'react'
import { isIterableArray, toFormatPrice, toStringPrice } from '../../../utils/utils'
import { Button, IconButton, Spinner, Typography } from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import DrawerCard from '../../../components/mobile/DrawerCard'
import DownloadBtn from '../../tableHelpers/buttons/DownloadBtn'
import DownloadIcon from '../../../images/icons/DownloadIcon'
import CloseDrawerIcon from '../../../images/icons/CloseDrawerIcon'
import { ActRowType } from '../ActsPage.types'
import FileIcon from '../../../images/icons/FileIcon'
import ExcelIcon from '../../../images/icons/ExcelIcon'

type propType = {
    visibleRows: any[],
    // visibleRows: InvoiceRowType[], 
    isLoadingTable: boolean,
    handleDownloadInvoice: any
    handleDownloadAct: any
    handleDownloadDetail: any
}
const ActsCards = ({
    visibleRows,
    isLoadingTable,
    handleDownloadInvoice,
    handleDownloadAct,
    handleDownloadDetail,
}: propType) => {

    const { t } = useTranslation()

    const [isOpenCard, setIsOpenCard] = useState<boolean>(false);
    const [modalValue, setModalValue] = useState<ActRowType | null>(null);

    const closeDrawer = () => {
        setIsOpenCard(false)
        setModalValue(null)
    }
    const openDrawer = (row: ActRowType) => {
        setIsOpenCard(true)
        setModalValue(row)
    }

    const dateParser = (date: string) => {
        const month = moment(date, 'MM-YYYY').format('MM')
        const year = moment(date, 'MM-YYYY').format('YYYY')
        return t(`month_${month}`) + ' ' + year
    }

    return (
        <div className='tab:hidden lap:pb-[100px]'>
            <DrawerCard
                isOpen={isOpenCard}
                closeDrawer={closeDrawer}
            >
                {
                    modalValue &&
                    <Fragment>
                        <div className="mb-6 flex items-center justify-between">
                            {
                                modalValue &&
                                <Typography variant='lead' className='text-[16px] font-bold text-gray-800'>
                                    {t('acts_page.act_title')}: {modalValue.number}
                                </Typography>
                            }
                            <IconButton variant="text" color="blue-gray" className='hover:bg-gray-100 active:bg-gray-100 rounded-full' onClick={closeDrawer}>
                                <CloseDrawerIcon />
                            </IconButton>
                        </div>
                        <div className='flex flex-col gap-[10px] mb-6'>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.act_type`)}</span>
                                <span className='text-[14px] font-medium'> {t(`acts_page.act_type_${modalValue.act_type}`)}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.balance_id`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.balance_id_table}</span>
                            </div>
                            <div className="grid grid-cols-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.period`)}</span>
                                <span className='text-[14px] font-medium'> {modalValue.period}</span>
                            </div>

                            <hr className='border-gray-100 my-3' />

                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.count`)}</span>
                                <span className='text-[16px] font-medium'> {toStringPrice(Number(modalValue.count))}</span>
                            </div>
                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.price`)}</span>
                                <span className='text-[16px] font-bold'> {toFormatPrice(Number(modalValue.price))}</span>
                            </div>
                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.amount_with_nds`)}</span>
                                <span className='text-[16px] font-bold'> {toFormatPrice(Number(modalValue.amount_with_nds))}</span>
                            </div>
                            <div className="grid grid-cols-2 mb-2">
                                <span className='text-[14px] font-medium'> {t(`acts_page.amount_for_payment`)}</span>
                                <span className='text-[16px] font-bold'> {toFormatPrice(Number(modalValue.amount_for_payment))}</span>
                            </div>

                        </div>
                        <div className='flex flex-col gap-[10px] my-6'>
                            <DownloadBtn
                                btnClass={'w-full !rounded-full min-h-[50px]'}
                                icon={<FileIcon />}
                                tooltipText={t('acts_page.download_invoice_act')}
                                record={modalValue}
                                handleDownloadFile={handleDownloadInvoice}
                            />

                            <DownloadBtn
                                btnClass={'w-full !rounded-full min-h-[50px]'}
                                icon={<DownloadIcon />}
                                tooltipText={t('acts_page.download_act')}
                                record={modalValue}
                                handleDownloadFile={handleDownloadAct}
                            />

                            <DownloadBtn
                                disabled={modalValue.act_type === 3}
                                icon={<ExcelIcon className='w-[20px]' />}
                                btnClass={'w-full !rounded-full min-h-[50px]'}
                                tooltipText={t('acts_page.download_detail')}
                                record={modalValue}
                                handleDownloadFile={handleDownloadDetail}
                            />

                            <Button onClick={closeDrawer} size='sm' color='white' className='w-full !rounded-full min-h-[50px] flex gap-[12px] items-center justify-center'>
                                {t('close')}
                            </Button>
                        </div>
                    </Fragment>
                }
            </DrawerCard>
            {
                isIterableArray(visibleRows) ?
                    visibleRows.map((item: ActRowType, key: number) => (
                        <Fragment key={key}>

                            {
                                item.period !== visibleRows?.[key - 1]?.period &&
                                <Typography variant='lead' className='text-[14px] font-bold text-gray-800 mb-[6px] mt-[10px]'>
                                    {dateParser(item.period)}
                                </Typography>
                            }
                            <div onClick={() => openDrawer(item)} className="bg-white active:bg-gray-50 transition-all rounded border-2 border-gray-100 p-[12px] mb-[6px] animate-appear">
                                <div className='w-full flex flex-wrap items-center justify-between gap-[4px] mb-[8px]'>
                                    <Typography variant='lead' className='text-[14px] text-gray-800'>
                                        {item.number}
                                    </Typography>
                                    <span className='text-[12px] p-[4px_8px] rounded-full bg-gray-50  font-bold'>{t(`balance_${item.balance_id}`)}</span>
                                </div>
                                <div className='flex flex-wrap items-center justify-between gap-[4px]'>
                                    <div className="grid grid-cols-1">
                                        {/* <span className='text-[14px] font-medium'> {t(`acts_page.amount_with_nds`)}</span> */}
                                        <span className='text-[16px] font-medium'> {toFormatPrice(Number(item.amount_with_nds))}</span>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ))
                    :
                    isLoadingTable ?
                        <Spinner className='my-6 mx-auto' />
                        :
                        <Typography variant='lead' className='text-[20px] font-medium text-center p-6 '>
                            {t('tables.nothing_to_show')}
                        </Typography>
            }
        </div>
    )
}

export default ActsCards