import React, { useEffect, useState } from 'react'
import { useGetContractPeriodQuery } from '../../../store/auth/authService'
import moment from 'moment'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SaldoFormType } from './ViewSaldo'
import { useForm } from 'react-hook-form'
import FilterBalance from './FilterBalance'
import MobileFilters from './MobileFilters'
import FilterDatePeriodYear from '../../tableHelpers/filters/FilterDatePeriodYear'
import { ImageDownloadButton } from '../ImageDownloadButton'

const FiltersSaldo = (props: {
    value: SaldoFormType,
    isLoadingFetch: boolean,
    fetchPage: any,
    savePicture: any,
    isAllocation: boolean
    // defaulPeriodFrom: string,
    // defaulPeriodTo: string,
}) => {
    const {
        value,
        isLoadingFetch,
        fetchPage,
        savePicture,
        isAllocation
        // defaulPeriodFrom,
        // defaulPeriodTo
    } = props

    const { t } = useTranslation()
    const { param_contract_id } = useParams()
    const { data } = useGetContractPeriodQuery({
        contract_id: param_contract_id,
        endOffset: 3
    })
    const {
        control,
        getValues,
        setValue,
    } = useForm<SaldoFormType>({
        defaultValues: {},
    })

    useEffect(() => {
        // setValue({
        //     periodFrom: value.periodFrom,
        //     periodTo: value.periodTo,
        //     tko: value.tko,
        // })
        setValue('periodFrom', value.periodFrom)
        setValue('periodTo', value.periodTo)
        setValue('balance_id', value.balance_id)

    }, [value]);


    const [filtersState, setFiltersState] = useState<SaldoFormType>(value);

    const handleCloseFilter = () => {
        const values = getValues()
        setFiltersState(values)
        if (JSON.stringify(values) !== JSON.stringify(filtersState)) {
            // fetch page with new filters 
            fetchPage(values)
        }
    }

    return (

        <div className='flex flex-wrap tab:flex-row tab:flex-nowrap  justify-between items-center mb-[24px] tab:mb-[32px] mt-[12px] gap-[8px]'>

            <MobileFilters
                defaultState={{
                    periodFrom: value.periodFrom,
                    periodTo: value.periodTo,
                    balance_id: value.balance_id,
                }}
                handleCloseFilters={handleCloseFilter}
                control={control}
                datesData={data}
                filtersState={filtersState}
            // handleCloseFilters={handleCloseFilter}
            // control={control}
            // datesData={data}
            // filtersState={filtersState}
            />
            <div className='tab:hidden grow'>
                <FilterBalance
                    defaultValue={value.balance_id}
                    control={control}
                    label={t('filters.type_balance')}
                    fieldName={'balance_id'}
                    filtersData={[
                        {
                            value: 1,
                            label: 'balance_1',
                            disabled: false
                        },
                        {
                            value: 2,
                            label: 'balance_2',
                            disabled: !isAllocation
                        },
                    ]}
                    handleCloseFilter={handleCloseFilter}
                />
            </div>

            <div className='hidden w-full tab:flex gap-[8px] justify-between'>
                <FilterDatePeriodYear
                    isLoadingFetch={isLoadingFetch || !value.periodFrom}
                    placement={'bottom-start'}
                    control={control}
                    fieldName1={'periodFrom'}
                    fieldName2={'periodTo'}
                    label1={t("filters.period_from")}
                    label2={t("filters.period_to")}
                    datesData={data}
                    handleCloseFilter={handleCloseFilter}
                    isDisabled={(date: string) => {
                        return false
                    }}
                    dateFormatter1={(date: string) => moment(date, 'MM-YYYY').add(6, 'month').format('MM-YYYY')}
                    dateFormatter2={(date: string) => moment(date, 'MM-YYYY').add(-6, 'month').format('MM-YYYY')}
                />
                <FilterBalance
                    defaultValue={value.balance_id}
                    control={control}
                    label={t('filters.type_balance')}
                    fieldName={'balance_id'}
                    filtersData={[
                        {
                            value: 1,
                            label: 'balance_1',
                            disabled: false
                        },
                        {
                            value: 2,
                            label: 'balance_2',
                            disabled: !isAllocation
                        },
                    ]}
                    handleCloseFilter={handleCloseFilter}
                />
            </div>

            <ImageDownloadButton text={t('download')} onClick={savePicture} disabled={isLoadingFetch} />
        </div>
    )
}

export default FiltersSaldo