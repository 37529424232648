
import { baseApi } from '../../ConfigApi'
import { ConsumptionFormType, TypeConsumptionPage } from '../../pages/consumptions/_types'
import { ContractPagePeriodType, ContractPageType } from '../../pages/contract/ContractPage.types'
import { MainPageType } from '../../pages/main/MainPage.types'
import { isIterableArray } from '../../utils/utils'
import { AppealType, ContractsArrType } from '../../pages/support/types'
import { UserProfileType } from '../../pages/userProfile/types'
import { SaldoFormType } from '../../pages/analytics/viewSaldo/ViewSaldo'
import moment from 'moment'
import 'moment/locale/uk'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ComparisonFormType } from '../../pages/analytics/viewComparison/ViewComparison'

const isDataCheckComparisonBar = (arr: any) => {
    for (let i = 0; i < arr.length; i++) {
        if (Object.keys(arr[i]).length > 1) {
            return true;
        }
    }
    return false;
}

const CACHE_DEFAULT_TIME = 100
export const authApi = createApi({
    reducerPath: 'authApi',
    keepUnusedDataFor: 0,
    // keepUnusedDataFor: 120,
    baseQuery: fetchBaseQuery({
        baseUrl: baseApi,
        prepareHeaders: (headers, { getState }: any) => {
            const token = getState().auth.token
            if (token) {
                // include token in req header
                headers.set('authorization', `${token}`)
                return headers
            }
            else {
                window.location.href = '/auth'
            }
        },
    }),
    endpoints: (builder) => ({
        viewComparisonByYear: builder.query<any, {
            contract_id?: number,
            periodFrom: string,
            periodTo: string
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id?: number,
                periodFrom: string,
                periodTo: string
            }) => ({
                url: '/api/analitic/consumption-comparison/view-by-year',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const labels: string[] = []
                const labels_string: string[] = []

                const rdn: number[] = []
                const transfer: number[] = []
                const class1: number[] = []
                const class2: number[] = []

                const isData = isIterableArray(response) ? isDataCheckComparisonBar(response) : false
                if (isIterableArray(response)) response.forEach((element: any) => {
                    labels.push(element.period)
                    labels_string.push(moment(element.period, 'YYYY-MM').format('MMMM YYYY'))
                    rdn.push(element?.rdn?.total_for_act || 0)
                    transfer.push(element?.transfer?.total_for_act || 0)
                    class1.push(element?.class1?.total_for_act || 0)
                    class2.push(element?.class2?.total_for_act || 0)

                })
                return {
                    month: response.month,
                    isForSave: isData,
                    formatted: {
                        labels,
                        labels_string,
                        rdn,
                        transfer,
                        class1,
                        class2,
                    }
                }
            }
        }),
        viewComparisonByYearPrice: builder.query<any, {
            contract_id?: number,
            periodFrom: string,
            periodTo: string
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id?: number,
                periodFrom: string,
                periodTo: string
            }) => ({
                url: '/api/analitic/consumption-comparison/view-by-year',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const labels: string[] = []
                const labels_string: string[] = []

                const rdn: number[] = []
                const transfer: number[] = []
                const class1: number[] = []
                const class2: number[] = []

                if (isIterableArray(response)) response.forEach((element: any) => {
                    labels.push(element.period)
                    labels_string.push(moment(element.period, 'YYYY-MM').format('MMMM YYYY'))
                    rdn.push(element?.rdn?.price || 0)
                    transfer.push(element?.transfer?.price || 0)
                    class1.push(element?.class1?.price || 0)
                    class2.push(element?.class2?.price || 0)

                })

                const isData = isIterableArray(response) ? isDataCheckComparisonBar(response) : false
                return {
                    isForSave: isData,
                    formatted: {
                        labels,
                        labels_string,
                        rdn,
                        transfer,
                        class1,
                        class2,
                    }
                }
            }
        }),
        viewComparison: builder.query<any, ComparisonFormType>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: ComparisonFormType) => ({
                url: '/api/analitic/consumption-comparison/view',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {

                const KEYVAL1 = 'price'
                const KEYVAL2 = 'total_for_act'

                // распарсенные данные 1 периода
                // values1 - значения price всех элементов
                // values2 - значения total_for_act всех элементов
                const formatted1 = isIterableArray(Object.keys(response.period1)) ?
                    Object.keys(response.period1).reduce((acc: any, key: any) => {


                        const item = response.period1[key]
                        if (item?.[KEYVAL1]) {
                            acc.values1.push(item?.[KEYVAL1])
                            acc.labels1.push(key)
                        }
                        if (item?.[KEYVAL2]) {
                            acc.values2.push(item?.[KEYVAL2])
                            acc.labels2.push(key)
                        }
                        return acc
                    }, {
                        values1: [],
                        labels1: [],
                        values2: [],
                        labels2: [],
                    })
                    :
                    false

                const formatted2 = isIterableArray(Object.keys(response.period2)) ?
                    Object.keys(response.period2).reduce((acc: any, key: any) => {


                        const item = response.period2[key]
                        if (item?.[KEYVAL1]) {
                            acc.values1.push(item?.[KEYVAL1])
                            acc.labels1.push(key)
                        }
                        if (item?.[KEYVAL2]) {
                            acc.values2.push(item?.[KEYVAL2])
                            acc.labels2.push(key)
                        }
                        return acc
                    }, {
                        values1: [],
                        labels1: [],
                        values2: [],
                        labels2: [],
                    })
                    :
                    false
                return {
                    formatted: {
                        period1: {
                            consumption: {
                                declared: response.period1.declared,
                                fact: response.period1.fact,
                                delta_cons: Math.abs(response.period1.delta_cons),
                            },
                            dataPrice: { // tarif
                                values: formatted1 ? formatted1.values1 : null,
                                labels: formatted1 ? formatted1.labels1 : null
                            },
                            dataTotal: {
                                values: formatted1 ? formatted1.values2 : null,
                                labels: formatted1 ? formatted1.labels2 : null
                            },
                        },
                        period2: {
                            consumption: {
                                declared: response.period2.declared,
                                fact: response.period2.fact,
                                delta_cons: Math.abs(response.period2.delta_cons),
                            },
                            dataPrice: { // tarif
                                values: formatted2 ? formatted2.values1 : null,
                                labels: formatted2 ? formatted2.labels1 : null
                            },
                            dataTotal: {
                                values: formatted2 ? formatted2.values2 : null,
                                labels: formatted2 ? formatted2.labels2 : null
                            },
                        },
                    }
                }
            }
        }),
        viewSaldo: builder.query<any, SaldoFormType>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: SaldoFormType) => ({
                url: '/api/ca/view-saldo',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const labels: string[] = []
                const balance: number[] = []
                const sum_invoice: number[] = []
                const sum_payment: number[] = []

                response.forEach((element: any) => {
                    labels.push(element.date)
                    balance.push(element.balance)
                    sum_invoice.push(element.sum_invoice)
                    sum_payment.push(element.sum_payment)
                })
                return {
                    response: response,
                    formatted: {
                        labels,
                        balance,
                        sum_invoice,
                        sum_payment,
                    }
                }
            }
        }),
        viewConsumption: builder.query<any, {
            contract_id?: number,
            periodFrom: string,
            periodTo: string
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                periodFrom: string,
                periodTo: string
            }) => ({
                url: '/api/ca/view-consumption',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const labels: string[] = []
                const labels_string: string[] = []
                const declared: number[] = []
                const fact: number[] = []
                const declared_delta_less_6: number[] = []
                const declared_delta_more_6: number[] = []
                const declared_delta_more_10: number[] = []
                const fact_delta_less_6: number[] = []
                const fact_delta_more_6: number[] = []
                const fact_delta_more_10: number[] = []

                if (isIterableArray(response.month)) response.month.forEach((element: any) => {
                    const lab = moment(`${element.month}-${element.year}`, 'MM-YYYY').locale("uk").format('MMMM YY')
                    const label = lab[0].toUpperCase() + lab.slice(1)
                    labels_string.push(label)
                    labels.push(`${element.month}-${element.year}`)
                    declared.push(element.declared)
                    fact.push(element.fact)
                    if (element.declared > element.fact) {
                        const delta_procent = (element.fact * 100) / element.declared

                        if (delta_procent > 10) {
                            fact_delta_less_6.push(element.fact > 0 ? Math.abs(element.delta) : 0)
                            fact_delta_more_6.push(0)
                            fact_delta_more_10.push(0)

                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)
                        } else if (delta_procent >= 6) {
                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(element.fact > 0 ? Math.abs(element.delta) : 0)
                            fact_delta_more_10.push(0)

                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)
                        } else if (delta_procent < 6) {
                            fact_delta_more_10.push(element.fact > 0 ? Math.abs(element.delta) : 0)
                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)

                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)
                        }
                        else {
                            fact_delta_more_10.push(0)
                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)

                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)
                        }
                    }
                    else if (element.declared < element.fact) {
                        const delta_procent = (element.declared * 100) / element.fact
                        if (delta_procent > 10) {
                            declared_delta_less_6.push(element.declared > 0 ? Math.abs(element.delta) : 0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)

                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)
                            fact_delta_more_10.push(0)
                        } else if (delta_procent >= 6) {
                            declared_delta_more_6.push(element.declared > 0 ? Math.abs(element.delta) : 0)
                            declared_delta_less_6.push(0)
                            declared_delta_more_10.push(0)

                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)
                            fact_delta_more_10.push(0)
                        } else if (delta_procent < 6) {
                            declared_delta_more_10.push(element.declared > 0 ? Math.abs(element.delta) : 0)
                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)

                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)
                            fact_delta_more_10.push(0)
                        }
                        else {
                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)

                            fact_delta_more_10.push(0)
                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)
                        }
                    }
                    else {
                        fact_delta_more_10.push(0)
                        fact_delta_less_6.push(0)
                        fact_delta_more_6.push(0)

                        declared_delta_less_6.push(0)
                        declared_delta_more_6.push(0)
                        declared_delta_more_10.push(0)
                    }
                })
                const isDataCheck = (arrMonth: any) => {
                    for (let i = 0; i < arrMonth.length; i++) {
                        if (arrMonth?.[i]?.declared > 0 || arrMonth?.[i]?.delta > 0 || arrMonth?.[i]?.fact > 0) {
                            return true;
                        }
                    }
                    return false;
                }
                const isData = isIterableArray(response.month) ? isDataCheck(response.month) : false
                return {
                    month: response.month,
                    isForSave: isData,
                    formatted: {
                        labels,
                        labels_string,
                        declared,
                        fact,
                        declared_delta_less_6,
                        declared_delta_more_6,
                        declared_delta_more_10,
                        fact_delta_less_6,
                        fact_delta_more_6,
                        fact_delta_more_10
                    }
                }
            }
        }),
        viewConsumptionMonth: builder.query<any, {
            contract_id: number,
            period: string
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                period: string,
            }) => ({
                url: '/api/analitic/consumption-month/view',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const labels: string[] = []

                const total_declared: number[] = []
                const total_fact: number[] = []

                const declared_delta_less_6: number[] = []
                const declared_delta_more_6: number[] = []
                const declared_delta_more_10: number[] = []
                const fact_delta_less_6: number[] = []
                const fact_delta_more_6: number[] = []
                const fact_delta_more_10: number[] = []


                if (isIterableArray(response.array_cons)) {
                    response.array_cons.forEach((element: any) => {
                        labels.push(element.day)
                        total_declared.push(element.total_declared)
                        total_fact.push(element.total_fact)
                        if (element.total_declared > element.total_fact) {
                            const delta_procent = (element.total_fact * 100) / element.total_declared

                            if (delta_procent > 10) {
                                fact_delta_less_6.push(element.total_fact > 0 ? Math.abs(element.delta) : 0)
                                fact_delta_more_6.push(0)
                                fact_delta_more_10.push(0)

                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)
                            } else if (delta_procent >= 6) {
                                fact_delta_more_6.push(element.total_fact > 0 ? Math.abs(element.delta) : 0)
                                fact_delta_less_6.push(0)
                                fact_delta_more_10.push(0)

                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)
                            } else if (delta_procent < 6) {
                                fact_delta_more_10.push(element.total_fact > 0 ? Math.abs(element.delta) : 0)
                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)

                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)
                            }
                            else {
                                fact_delta_more_10.push(0)
                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)

                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)
                            }
                        }
                        else if (element.total_declared < element.total_fact) {
                            const delta_procent = (element.total_declared * 100) / element.total_fact
                            if (delta_procent > 10) {
                                declared_delta_less_6.push(element.total_declared > 0 ? Math.abs(element.delta) : 0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)

                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)
                                fact_delta_more_10.push(0)
                            } else if (delta_procent > 6) {
                                declared_delta_more_6.push(element.total_declared > 0 ? Math.abs(element.delta) : 0)
                                declared_delta_less_6.push(0)
                                declared_delta_more_10.push(0)

                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)
                                fact_delta_more_10.push(0)
                            } else if (delta_procent < 6) {
                                declared_delta_more_10.push(element.total_declared > 0 ? Math.abs(element.delta) : 0)
                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)

                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)
                                fact_delta_more_10.push(0)
                            }
                            else {
                                declared_delta_less_6.push(0)
                                declared_delta_more_6.push(0)
                                declared_delta_more_10.push(0)

                                fact_delta_less_6.push(0)
                                fact_delta_more_6.push(0)
                                fact_delta_more_10.push(0)
                            }
                        }
                        else {
                            declared_delta_less_6.push(0)
                            declared_delta_more_6.push(0)
                            declared_delta_more_10.push(0)

                            fact_delta_less_6.push(0)
                            fact_delta_more_6.push(0)
                            fact_delta_more_10.push(0)
                        }
                    })
                    return {
                        month: response.month,
                        formatted: {
                            labels,
                            declared: total_declared,
                            fact: total_fact,
                            declared_delta_less_6,
                            declared_delta_more_6,
                            declared_delta_more_10,
                            fact_delta_less_6,
                            fact_delta_more_6,
                            fact_delta_more_10
                        }
                    }
                }
                else return {
                    month: response.month,
                    formatted: false
                }
            }
        }),
        viewConsumptionDay: builder.query<any, {
            contract_id: number,
            period: string,
            day: number
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                period: string,
                day: number
            }) => ({
                url: '/api/analitic/consumption-day/view',
                method: 'POST',
                body
            })
        }),
        viewConsumptionDetailMonth: builder.query<any, {
            contract_id: number,
            period: string
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                period: string,
            }) => ({
                url: '/api/analitic/consumption-detail/view',
                method: 'POST',
                body
            }),
            // transformResponse: (response: any) => {
            //     const KEYVAL1 = 'price'
            //     const KEYVAL2 = 'total_for_act'

            //     const formatted = isIterableArray(Object.keys(response)) ? Object.keys(response).reduce((acc: any, key: any) => {
            //         const item = response[key]
            //         console.log(key, item)
            //         if (item?.[KEYVAL1]) {
            //             acc.values1.push(item?.[KEYVAL1])
            //             acc.labels1.push(key)
            //         }
            //         if (item?.[KEYVAL2]) {
            //             acc.values2.push(item?.[KEYVAL2])
            //             acc.labels2.push(key)
            //         }
            //         return acc
            //     }, {
            //         // price: {
            //         //     values: [],
            //         //     labels: [],
            //         // },
            //         // total_for_act: {
            //         //     values: [],
            //         //     labels: [],
            //         // },
            //         values1: [],
            //         labels1: [],
            //         values2: [],
            //         labels2: [],
            //     }) : false

            //     console.log(formatted)

            //     return {
            //         formatted: formatted,
            //         response: response
            //     }
            // }
        }),
        contractBalancesView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
            }) => ({
                url: '/api/ca/view-balance',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                return { ...response, ...{ legal_address: response.address } }
            }
        }),
        invoiceHistoryView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                invoiceId: number,
            }) => ({
                url: '/api/invoice/view-saldo',
                method: 'POST',
                body
            })
        }),
        invoicesView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                periodFrom: string,
                periodTo: string,
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/invoice/view-filter',
                method: 'POST',
                body
            })
        }),
        actsView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                periodFrom: string,
                periodTo: string,
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/acts/view-filter',
                method: 'POST',
                body
            })
        }),
        paymentsView: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: {
                contract_id: number,
                type: number[],
                type_invoice: number[],
                balance: number[],
                date_payment: string[]
            }) => ({
                url: '/api/payments/view-filter',
                method: 'POST',
                body
            })
        }),
        viewDeclaredGas: builder.query<any, {
            contract_id: number,
            id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-gas',
                method: 'POST',
                body
            })
        }),
        viewDeclaredGasAll: builder.query<any, {
            contract_id: number,
            arr_gas: number[]
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-gas-all',
                method: 'POST',
                body
            })
        }),
        viewDeclaredPower: builder.query<any, {
            contract_id: number,
            id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-power',
                method: 'POST',
                body
            })
        }),
        viewDeclaredPowerAll: builder.query<any, {
            contract_id: number,
            arr_power: number[]
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-power-all',
                method: 'POST',
                body
            })
        }),
        viewFactualGas: builder.query<any, {
            id: number,
            contract_id: number,
            arr_gas: number[],
            arr_power: number[],
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-fact',
                method: 'POST',
                body
            }),
        }),
        viewFactualGasAll: builder.query<any, {
            id: number,
            contract_id: number,
            arr_fact: number[],
            arr_gas: number[],
            arr_power: number[],
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-fact-all',
                method: 'POST',
                body
            }),
        }),
        viewFactInfoTkoGas: builder.query<any, {
            id?: number,
            contract_id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/info-tko',
                method: 'POST',
                body
            }),
        }),
        gasConsumptionView: builder.query<TypeConsumptionPage, ConsumptionFormType>({
            query: (body) => ({
                url: '/api/ca/consumption-gas/view-all',
                method: 'POST',
                body
            })
        }),
        consumptionViewAllTko: builder.query<any, {
            contract_id: number,
        }>({
            query: (body: {
                contract_id: number,
                service_id: number
            }) => ({
                url: `/api/ca/consumption/all-tko`,
                method: 'POST',
                body: {
                    contract_id: body.contract_id,
                }
            }),
            transformResponse: (response: any) => {
                const replaced = isIterableArray(response) ? response.map((i: any) => ({
                    value: i.id,
                    label: i.name
                })) : []
                return replaced
            }
        }),
        consumptionView: builder.query<TypeConsumptionPage, ConsumptionFormType>({
            query: (body) => ({
                url: `/api/ca/consumption/view-all`,
                method: 'POST',
                body
            })
        }),
        // consumptionViewAllTko: builder.query<any, {}>({
        //     query: (body: {
        //         contract_id: number,
        //     }) => ({
        //         url: '/api/ca/consumption/all-tko',
        //         method: 'POST',
        //         body
        //     }),
        //     transformResponse: (response: any) => {
        //         const replaced = isIterableArray(response) ? response.map((i: any) => ({
        //             value: i.id,
        //             label: i.name
        //         })) : []
        //         return replaced
        //     }
        // }),
        viewDeclared: builder.query<any, {}>({
            query: (body: {
                contract_id: number
            }) => ({
                url: '/api/ca/consumption/view-declared',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const formData = isIterableArray(response?.data) ? response.data.reduce((acc: any, item: any) => {
                    if (isIterableArray(item.data)) {
                        item.data.map((tko: any) => {
                            acc = {
                                ...acc,
                                ... { [`${item.tko.id}-${tko.month}-${tko.year}`]: typeof tko.declared?.amount === 'number' ? tko.declared?.amount : '' }
                                // CHECK THIS!!!
                            }
                        })
                    }
                    return acc
                }, {})
                    :
                    []
                return { ...response, ...{ formData: formData } }
            }
        }),
        viewDetailingInfo: builder.query<any, {
            id: number,
            contract_id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view',
                method: 'POST',
                body
            }),
        }),
        viewDetailingMatrix: builder.query<any, {
            tko_id: number,
            month: string,
            year: number,
            contract_id: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view-matrix',
                method: 'POST',
                body
            }),
        }),
        viewDetailingAll: builder.query<any, {
            arr_id: number[],
            contract_id: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-detailing/view-matrix-all',
                method: 'POST',
                body
            }),
        }),
        viewFactAll: builder.query<any, {
            fact_id: number[],
            contract_id: number,
            type: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/view-matrix-all',
                method: 'POST',
                body
            }),
        }),
        viewFactual: builder.query<any, {
            // fact_id: number[],
            id: number,
            contract_id: number,
            type: number,
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/view-matrix',
                method: 'POST',
                body
            }),
        }),
        viewFactInfoTko: builder.query<any, {
            id?: number,
            contract_id: number
        }>({
            query: (body) => ({
                url: '/api/ca/consumption-fact/info_tko',
                method: 'POST',
                body
            }),
        }),
        getContractPeriod: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number, endOffset?: number }) => ({
                url: '/api/ca/get-date-select',
                method: 'POST',
                body
            }),
        }),
        caView: builder.query<ContractPageType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number }) => ({
                url: '/api/ca/view',
                method: 'POST',
                body
            }),
        }),
        caViewPeriod: builder.query<ContractPagePeriodType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number, month: string, year: string }) => ({
                url: '/api/ca/view-period',
                method: 'POST',
                body
            })
        }),
        requestGetContractsView: builder.query<ContractsArrType[], {
            service_id: number,
        }>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { service_id: number }) => ({
                url: '/api/cabinet/profile-request-getcontracts',
                method: 'POST',
                body
            })
        }),
        profileRequestsView: builder.query<AppealType[], {
            periodFrom: string,
            periodTo: string,
        }>({
            query: (body) => ({
                url: '/api/cabinet/profile-requests',
                method: 'POST',
                body
            })
        }),
        profileInfoView: builder.query<UserProfileType, any>({
            query: () => ({
                url: '/api/cabinet/profile-getinfo',
                method: 'GET'
            })
        }),
        getInfoManagerView: builder.query<{
            name: string,
            phone: string,
            email: string,
            avatar: string,
        }, {
            contract_id: number
        }>({
            keepUnusedDataFor: 120,
            query: (body) => ({
                url: `/api/cabinet/profile-getinfomanager/${body.contract_id}`,
                method: 'GET'
            })
        }),
        indexContracts: builder.query({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { service_id: number }) => ({
                url: '/api/cabinet/index-contracts',
                method: 'POST',
                body
            }),
            transformResponse: (response: any) => {
                const legalFilters = isIterableArray(response.contracts) ? response.contracts.reduce((acc: any, item: any) => {
                    acc.push({
                        id: Number(item.id),
                        name: item.name
                    })
                    return acc
                }, []) : []
                return { ...response, ...{ legalFilters: legalFilters } }
            }

        }),
        // main
        indexAll: builder.query<MainPageType, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (props = '') => `/api/cabinet/index-all`,
            transformResponse: (response: any) => {
                const count = response?.svet?.active_total + response?.gaz?.active_total
                if (count < 2) {
                    return {
                        navigate: true,
                        ...response
                    }
                }
                return response
            }
        }),
        viewCaAllocation: builder.query<any, {}>({
            keepUnusedDataFor: CACHE_DEFAULT_TIME,
            query: (body: { contract_id: number }) => ({
                url: `/api/ca/view-allocation`,
                method: 'POST',
                body
            }),
        }),
    }),
})

export const {
    useIndexAllQuery,
    useIndexContractsQuery,
    useCaViewQuery,
    useCaViewPeriodQuery,
    useInvoicesViewQuery,
    useInvoiceHistoryViewQuery,
    useGetContractPeriodQuery,
    useActsViewQuery,
    usePaymentsViewQuery,
    useConsumptionViewQuery,
    useConsumptionViewAllTkoQuery,
    useViewDeclaredQuery,
    useViewDetailingInfoQuery,
    useViewDetailingMatrixQuery,
    useViewFactAllQuery,
    useViewFactInfoTkoQuery,
    useViewFactualQuery,
    useViewDetailingAllQuery,
    useContractBalancesViewQuery,
    useProfileInfoViewQuery,
    useRequestGetContractsViewQuery,
    useProfileRequestsViewQuery,
    useGetInfoManagerViewQuery,
    useViewDeclaredGasQuery,
    useViewDeclaredPowerQuery,
    useViewDeclaredGasAllQuery,
    useViewDeclaredPowerAllQuery,
    useViewFactInfoTkoGasQuery,
    useViewFactualGasQuery,
    useViewFactualGasAllQuery,
    useViewSaldoQuery,
    useViewConsumptionQuery,
    useViewConsumptionMonthQuery,
    useViewConsumptionDayQuery,
    useViewConsumptionDetailMonthQuery,
    useViewComparisonQuery,
    useViewComparisonByYearQuery,
    useViewComparisonByYearPriceQuery,
    useViewCaAllocationQuery
} = authApi
