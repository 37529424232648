import { Typography } from '@material-tailwind/react'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { isIterableArray } from '../../../utils/utils'
import { Controller } from 'react-hook-form'
import { TypeTkoPeriodItem } from '../_types'


const PeriodsTkosInput = ({ data, tko_id, control, tko_detailing }: {
    tko_detailing: 1 | 2 | 3,
    control: any,
    tko_id: any,
    data: TypeTkoPeriodItem[],
    handleEditDeclared: ({ item, edit }: { item: TypeTkoPeriodItem, edit: boolean }) => void
}) => {
    // console.log(data)

    const { t } = useTranslation()

    const { pathname, search } = useLocation()
    const { param_contract_id } = useParams<{
        param_contract_id: string;
    }>()

    return (
        <>
            {
                isIterableArray(data) &&
                <div className='grid grid-cols-2 mob:grid-cols-3 tab:grid-cols-6 lap:grid-cols-5 des:grid-cols-6 text-[14px] tab:text-[18px] gap-[24px_16px] lap:gap-[32px_24px] lap:pl-[96px]'>
                    {
                        data.map((item: any, key: number) => (
                            <div key={key} className={`flex flex-col items-center ${data?.length > 6 ? 'gap-[8px] tab:gap-[18px]' : 'gap-[16px] tab:gap-[32px]'}`}>
                                <div>{item.month}.{item.year}</div>

                                <div className='flex flex-col gap-[8px] max-w-[100%] '>
                                    <Controller
                                        render={({ field, fieldState }) =>
                                            <input
                                                {...field}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const n = parseFloat(e.target.value)
                                                    field.onChange(typeof n === 'number' && n >= 0 ? n : '')
                                                }}
                                                disabled={!item.show || item.isClose}
                                                className={`border-2 border-gray-200 rounded max-w-[100%] h-[56px] px-[4px] text-center text-gray-700 text-[14px] tab:text-[18px] font-bold          
                                                    disabled:bg-gray-50 disabled:border-gray-200 disabled:text-gray-500
                                                    hover:bg-gray-50 hover:border-gray-300 outline-gray-400
                                                    focus:bg-white focus:border-orange-500 focus-visible:!border-orange-400 transition-all

                                                    ${fieldState.isDirty && '!border-orange-200 text-orange-400 border-[3px] hover:bg-orange-50'}
                                                `}
                                            />
                                        }
                                        name={`data.${tko_id}-${item.month}-${item.year}`}
                                        control={control}
                                        defaultValue=""
                                    />

                                    {
                                        (tko_detailing === 1 || tko_detailing === 2) && item.show && !item.isClose &&
                                        <div className="lap:my-[12px] mx-auto w-[100%]">
                                            <NavLink state={{
                                                pathname, search
                                            }}
                                                to={`/consumptions/${param_contract_id}/detailing/create/${tko_id}/${item.year}/${item.month}`}
                                                className={`overflow-hidden block w-[100%] whitespace-nowrap p-[6px] text-center text-[14px] text-normal text-gray-700 bg-gray-50 border-2 border-gray-200 rounded-full  hover:bg-gray-100 transition-all`}
                                            >
                                                {t(`declared.by_detailing_${tko_detailing}`)}
                                            </NavLink>
                                        </div>
                                    }
                                </div>

                                <Typography variant="lead" className="font-normal text-[14px] text-gray-600">
                                    {t('kvHour')}
                                </Typography>
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )
}

export default PeriodsTkosInput