import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import {
    Routes,
    Route,
    useNavigate,
} from "react-router-dom";
import AuthPage from './components/auth/AuthPage';
import LoginForm from './components/auth/LoginForm';
import LayoutPrivate from './layouts/LayoutPrivate';
import { useDispatch, useSelector } from 'react-redux';
import { checkToken } from './store/auth/authActions';
import { AppDispatch, RootState } from './store/store';
import { routes } from './menu/routes';
import { createPortal } from 'react-dom';
import PageNewConnection from './components/newConnection/page/PageNewConnection';
import axios from 'axios';
import { TOAST_POSITION } from './components/toasts/toasts';


const AppRouter = () => {

    const navigate = useNavigate()
    // check token and navigate('auth') here

    const dispatch = useDispatch<AppDispatch>()

    // const token = useSelector((state: RootState) => state.auth.token)
    const service_id = useSelector((state: RootState) => state.contract.service_id)
    const { token } = useSelector((state: RootState) => state.auth)

    const [isTokenAlive, setIsTokenAlive] = useState<boolean>(false);

    useEffect(() => {
        const output = document.querySelector("html");
        if (!output) return;
        output.className = `service_id_${service_id}`
    }, [service_id]);


    useEffect(() => {
        handleCheckToken(token)
    }, [token]);

    const handleCheckToken = async (prop: any) => {
        const token = prop ? prop : localStorage.getItem('userToken') ? localStorage.getItem('userToken') : false
        if (token) {
            const res = await dispatch(checkToken(token))
            // console.log(res)
            if (res.payload.res === 'Success') {
                setIsTokenAlive(true)
                axios.defaults.headers.common['Authorization'] = token
            }
            else {
                navigate('/auth')
            }
        }
        else {
            navigate('/auth')
            // console.log('navigate!', localStorage.getItem('userToken'))
        }
    }

    return (
        <div>
            <React.Fragment>
                <div>
                    {
                        createPortal(
                            <Toaster
                                gutter={16}
                                containerClassName='!z-[10001]'
                                toastOptions={{
                                    position: TOAST_POSITION,
                                }}
                                containerStyle={{
                                    margin: 16
                                }}
                            />,
                            document.body
                        )
                    }
                </div>

                <Routes>
                    <Route
                        path={'/auth'}
                        element={
                            <AuthPage>
                                <LoginForm />
                            </AuthPage>
                        }
                    />
                    <Route
                        path={'/new-connection'}
                        element={
                            <PageNewConnection />
                        }
                    />
                    {/* private routes */}
                    {
                        isTokenAlive &&
                        <>
                            {
                                routes.map((route, key) => (
                                    <Route
                                        path={route.path}
                                        key={key}
                                        element={
                                            <LayoutPrivate>
                                                {React.createElement(route.component)}
                                            </LayoutPrivate>}
                                    />
                                ))
                            }
                        </>
                    }
                </Routes>
            </React.Fragment >
        </div>
    )
}

export default AppRouter
