import React from "react";
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarController,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from "react-i18next";
import { isIterableArray, toFormatPrice } from "../../../utils/utils";
import moment from "moment";
import { SALDO_GRAPH_ID } from "./ViewSaldo";


Chart.register(
    ChartDataLabels,
    CategoryScale,
    BarController,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const pluginBg = {
    id: 'background',
    beforeDraw: (chart,) => {
        const {
            ctx,
            chartArea
        } = chart;
        ctx.save()
        const { top, left, height, width, bottom } = chartArea
        // ctx.fillStyle = 'red';
        ctx.fillStyle = '#fdfdfd';
        ctx.fillRect(left - 30, top - 30, width + 60, bottom * 3)
    }
}

export const externalTooltip = function (context, preffix = '') {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    const isTooltip = tooltipModel.dataPoints.reduce((acc, item) => {
        if (item.dataset.data[item.dataIndex]) acc++
        return acc
    }, 0)
    if (!isTooltip) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    // Set class for custom style !!!
    tooltipEl.classList.add('tooltip-custom');

    if (tooltipModel.body) {

        let innerHtml = '<thead>';

        const title = tooltipModel.title[0] ? moment(tooltipModel.title[0], 'DD-MM-YYYY').locale('uk').format('DD MMMM YYYY') : '';
        // const title = tooltipModel.title[0];
        innerHtml += '<tr><th><span class="title">' + title + '</span></th></tr>';
        innerHtml += '</thead><tbody>';


        const bodyLines = tooltipModel.dataPoints.reduce((acc, bodyItem) => {
            if (bodyItem.dataset.data[bodyItem.dataIndex] !== 0) {
                acc.push({
                    backgroundColor: typeof bodyItem.dataset.backgroundColor === 'string' ? bodyItem.dataset.backgroundColor : `linear-gradient(90deg, rgba(252,165,165,1) 0%, rgba(252,165,165,1) 52%, rgba(146,221,164,1) 52%, rgba(146,221,164,1) 100%)`,
                    label: bodyItem.dataset.label,
                    value: bodyItem.dataset.data[bodyItem.dataIndex]
                })
            }
            return acc
        }, []);

        bodyLines.forEach(function (body, i) {
            const spanTitle = '<span class="spanTitle">' + body.label + '</span>';

            const spanValue = '<span class="spanValue">' + '<i class="spanColor" style="background:' + body.backgroundColor + '"></i>' + toFormatPrice(body.value) + ' ' + preffix + '</span>';
            innerHtml += '<tr><td>' + spanTitle + '</td></tr>';
            innerHtml += '<tr><td>' + spanValue + '</td></tr>';
        });
        innerHtml += '</tbody>';

        let tableRoot = tooltipEl.querySelector('table');
        tableRoot.innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.font = "Inter";
}

const GraphSaldo = ({ data }) => {

    const { t } = useTranslation()
    let maxLeft = 0;
    let maxWidth = 0;
    function generateLabelsNew(chart) {
        const datasets = chart.data.datasets;
        const { labels: { usePointStyle, pointStyle, textAlign, color } } = chart.legend.options;

        return chart._getSortedDatasetMetas().map((meta) => {
            const style = meta.controller.getStyle(usePointStyle ? 0 : undefined);
            const borderWidth = style.borderWidth;

            const box = meta?.controller?.chart?.legend?.legendHitBoxes[meta.index];

            if (box?.left > maxLeft) {
                maxLeft = box.left;
            }
            if (box?.width > maxWidth) {
                maxWidth = box.width;
            }
            const gradient = chart.ctx.createLinearGradient(maxLeft, 0, maxLeft + maxWidth, 0);
            // const gradient = chart.ctx.createLinearGradient(maxLeft, 0, maxLeft + maxWidth, 0);
            gradient.addColorStop(0, '#FCA5A599');
            gradient.addColorStop(.07, '#FCA5A599');
            gradient.addColorStop(.07, '#92DDA499');
            gradient.addColorStop(1, '#92DDA499');
            return {
                text: datasets[meta.index].label,
                // fillStyle: style.backgroundColor,
                // fillStyle: gradient,//style.backgroundColor,
                fillStyle: datasets[meta.index].backgroundColor ? style.backgroundColor : gradient,
                fontColor: color,
                hidden: !meta.visible,
                lineCap: style.borderCapStyle,
                lineDash: style.borderDash,
                lineDashOffset: style.borderDashOffset,
                lineJoin: style.borderJoinStyle,
                lineWidth: (borderWidth.width + borderWidth.height) / 4,
                strokeStyle: style.borderColor,
                pointStyle: pointStyle || style.pointStyle,
                rotation: style.rotation,
                textAlign: textAlign || style.textAlign,
                borderRadius: 6,
                datasetIndex: meta.index
            };
        }, this);
    }
    const options = {
        maintainAspectRatio: false,
        // animation: false,
        // transitions: {
        //     show: false,
        //     hide: false
        // },
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                labels: {
                    generateLabels: generateLabelsNew,
                    boxWidth: 12,
                    boxHeight: 12,
                    pointStyle: 'circle',
                    useBorderRadius: true,
                    borderRadius: 6,
                }
            },
            interaction: {
                intersect: false,
                mode: 'index',
            },
            tooltip: {
                // enabled: false, 
                // external: externalTooltip, 
                // position: 'nearest',

                position: 'nearest',
                mode: 'index',

                intersect: false,
                mode: 'index',

                backgroundColor: '#fff',
                bodyColor: '#444248',
                titleColor: '#444248',
                titleSpacing: 8,
                caretSize: 0,
                cornerRadius: 12,
                borderColor: '#EDEDED',
                borderWidth: 2,
                // displayColors: false,
                padding: 16,
                textDirection: 'top',
                titleFont: {
                    size: 18
                },
                bodyFont: {
                    size: 18
                },
                callbacks: {
                    title: function (tooltipItems, context) {
                        const label = tooltipItems[0].label
                        const isDataInCol = isIterableArray(tooltipItems) ? tooltipItems.reduce((acc, item) => {
                            acc += item.parsed.y
                            return acc
                        }, 0) : false
                        if (!isDataInCol) return t('analytics.no_data_for') + label
                        return label
                    },
                    label: function (context) {
                        let label = context.parsed.y !== 0 ? context.dataset.label : '';
                        if (context.parsed.y !== 0) {
                            if (label) {
                                label += ': ';
                            }
                            label += toFormatPrice(context.parsed.y, 2);
                        }
                        return label;
                    },
                    labelColor: function (context) {
                        return {
                            backgroundColor: context.dataset.backgroundColor,
                            border: 0,
                            boxWidth: 10,
                            boxHeight: 10,
                            pointStyle: 'circle',
                            useBorderRadius: true,
                            borderRadius: 8,
                        }
                    }
                }
            },
            datalabels: {
                display: false,
            }
        },
        responsive: true,
        scales: {
            x: {
                // offset: true,
                // position: 'top',
                border: {
                    display: false,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
                stacked: true,
                ticks: {
                    maxRotation: 0,
                }
            },
            y: {
                display: false,
                offset: true,
                stacked: true,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                // ticks: {
                //     mirror: true
                // }
            },
        },
    };

    // const getStops = (gradient, color) => {
    //     const nums = Array.from(Array(100).keys()).filter(i => i > 0)
    //     // console.log(res)
    //     nums.forEach(num => {
    //         gradient.addColorStop(num / 100, color);
    //         gradient.addColorStop(num / 100, 'white');
    //     })
    //     return gradient

    // }
    // console.log(getStops())
    // function getGradient(ctx, chartArea, color) {
    //     let width, height, gradient;
    //     const chartWidth = chartArea.right - chartArea.left;
    //     const chartHeight = chartArea.bottom - chartArea.top;
    //     const stripsLength = 64
    //     const nums = Array.from(Array(stripsLength).keys()).filter(i => i > 0)

    //     if (!gradient || width !== chartWidth || height !== chartHeight) {
    //         // Create the gradient because this is either the first render
    //         // or the size of the chart has changed
    //         width = chartWidth;
    //         height = chartHeight;
    //         const angle = 45 * Math.PI / 180
    //         gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top * Math.cos(angle));
    //         if (color === 'green') {
    //             gradient.addColorStop(0, '#92DDA499');
    //             nums.forEach(num => {
    //                 if (num % 2 === 0) {
    //                     gradient.addColorStop(num / stripsLength, 'white');
    //                     gradient.addColorStop(num / stripsLength, '#92DDA499');
    //                 }
    //                 else {
    //                     gradient.addColorStop(num / stripsLength, '#92DDA499');
    //                     gradient.addColorStop(num / stripsLength, 'white');
    //                 }
    //             })
    //             gradient.addColorStop(1, 'white');
    //         }
    //         if (color === 'red') {
    //             gradient.addColorStop(0, '#FCA5A599');
    //             nums.forEach(num => {
    //                 if (num % 2 === 0) {
    //                     gradient.addColorStop(num / stripsLength, 'white');
    //                     gradient.addColorStop(num / stripsLength, '#FCA5A599');
    //                 }
    //                 else {
    //                     gradient.addColorStop(num / stripsLength, '#FCA5A599');
    //                     gradient.addColorStop(num / stripsLength, 'white');
    //                 }
    //             })
    //             gradient.addColorStop(1, 'white');

    //             // gradient.addColorStop(0, '#FCA5A599');
    //             // gradient.addColorStop(1, 'white');
    //         }
    //     }
    //     console.log(gradient)

    //     return gradient
    // }

    const datachar = {
        labels: data?.labels,
        datasets: [
            {
                animation: false,
                transitions: false,
                label: t('analytics.sum_invoice'),
                data: data.sum_invoice,
                backgroundColor: '#FCA5A599',
                borderRadius: 4,
                spanGaps: false,
                datalabels: {
                    // display: true,
                    align: 'start',
                    // rotation: '-90',
                    anchor: 'start',
                    formatter: function (value, context) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        // const isAbs = context?.dataset?.data?.[context?.dataIndex] > 0
                        return '#EF4444';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }

            },
            {
                animation: false,
                transitions: false,
                label: t('analytics.sum_payment'),
                data: data.sum_payment,
                backgroundColor: '#92DDA499',
                borderRadius: 4,
                spanGaps: true,
                datalabels: {
                    // display: true,
                    align: 'end',
                    // rotation: '-90',
                    anchor: 'end',
                    formatter: function (value, context) {
                        return `${toFormatPrice(value)}`;
                    },
                    color: function (context) {
                        if (!context?.dataset?.data?.[context?.dataIndex]) return '#00000000'
                        // const isAbs = context?.dataset?.data?.[context?.dataIndex] > 0
                        // return isAbs ? '#139A32' : '#EF4444';
                        return '#139A32';
                    },
                    font: {
                        weight: 500,
                        size: 10,
                        family: 'Inter'
                    }
                }
            },
            {
                animation: false,
                transitions: false,
                label: t('analytics.balance'),
                type: "line",
                data: data.balance,
                pointStyle: false,
                // backgroundColor: 'rgba(231, 231, 231, 0.7)',
                // backgroundColor: (e, e2, e3) => {
                //     console.log(e, e2, e3,)
                //     return 'rgba(231, 231, 231, 0.7)';
                // },
                spanGaps: true,
                // fill: {
                //     above: (context) => {
                //         const chart = context.chart;
                //         const { ctx, chartArea } = chart;
                //         if (!chartArea) {
                //             return 'green';
                //         }
                //         return getGradient(ctx, chartArea, 'green');
                //     },
                //     below: (context) => {
                //         const chart = context.chart;
                //         const { ctx, chartArea } = chart;

                //         if (!chartArea) {
                //             return;
                //         }
                //         return getGradient(ctx, chartArea, 'red');
                //     },
                //     target: { value: 0 }
                // },
                fill: { above: '#92dda442', below: '#fca5a55c', target: { value: 0 } },
                borderColor: "rgba(255, 254, 254, 0)",

                // datalabels: {
                //     display: true,
                //     align: 'top',
                //     anchor: 'end',
                //     formatter: function (value, context) {
                //         return `${value}`;
                //     },
                //     color: function (context) {
                //         const isAbs = context?.dataset?.data?.[context?.dataIndex] > 0
                //         return isAbs ? '#139A32' : '#EF4444';
                //     },
                //     font: {
                //         weight: 'bold',
                //         size: 12,
                //         family: 'Inter'
                //     }
                // }
            },
        ],
    }
    return (
        <Bar
            id={SALDO_GRAPH_ID}
            options={options}
            data={datachar}
            plugins={[pluginBg]}
        />
    )
}

export default GraphSaldo